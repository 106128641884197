import { createSlice } from "@reduxjs/toolkit";

const usecaseSlice = createSlice({
	name: "usecase",
	initialState: [],
	reducers: {
		setUsecase: (state, action) => action.payload,
		addUsecase: (state, action) => {
			state.push(action.payload);
		},
		updateUsecase: (state, action) => {
			const index = state.findIndex(
				(usecase) => usecase.id === action.payload.id
			);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteUsecase: (state, action) => {
			return state.filter((usecase) => usecase.id !== action.payload.id);
		},
	},
});

export const { setUsecase, addUsecase, updateUsecase, deleteUsecase } =
	usecaseSlice.actions;

export default usecaseSlice.reducer;
