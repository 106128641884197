import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import http from '../../../resources/http';
import { baseURL } from '../../../resources/apiClient';
import swal from 'sweetalert';
import { formatText } from '../../../utils/helpers';
import { threatModelingMatrixOptions } from '../../../data/data';
import { setBackgroundForLevels } from '../../../utils/backgroundColorForLevels';

const ThreatModelingMatrix = () => {
  const [matrix, setMatrix] = useState([]);
  const [id, setId] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const handleModalShowHideEdit = () => setShowEdit(!showEdit);
  const [selectedMatrixResult, setSelectedMatrixResult] = useState();
  const [selectedValue, setSelectedValue] = useState('');

  const fetchData = () => {
    http
      .get(`${baseURL}/business_process/threat-modeling-matrix-config`)
      .then((response) => {
        setMatrix(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getCellValue = (applicationRiskProfile, threatRiskProfile) => {
    const result = matrix?.find(
      (item) =>
        item?.application_risk_profile === applicationRiskProfile &&
        item?.threat_risk_profile === threatRiskProfile
    );
    return result ? result.result : '';
  };

  const updateMatrixResult = (e) => {
    http
      .patch(
        `${baseURL}/business_process/threat-modeling-matrix-config/${id}`,
        {
          result: selectedMatrixResult,
        }
      )
      .then(
        (response) => {
          swal('Success', 'It has been updated successfully.', 'success');
          fetchData();
          handleModalShowHideEdit();
        },
        (err) => {
          swal('Error', 'Something went wrong, please try again.', 'error');
        }
      );
  };

  const DisplayData = matrix?.map((matrix) => {
    return (
      <>
        <tr key={matrix.id}>
          <td>{matrix?.id}</td>
          <td>{formatText(matrix?.application_risk_profile)}</td>
          <td>{formatText(matrix?.threat_risk_profile)}</td>
          <td>{formatText(matrix?.result)}</td>
          <td className="flex text-center" data-label="Manage">
            <IconButton
              onClick={() => {
                setId(matrix.id);
                handleModalShowHideEdit();
              }}
            >
              <EditIcon />
            </IconButton>
          </td>
        </tr>
      </>
    );
  });
  const matrixHeaders = ['very_low', 'low', 'moderate', 'high', 'critical'];
  const displayHeaders = ['Very Low', 'Low', 'Moderate', 'High', 'Critical'];
  const [isShowing, setIsShowing] = useState(false);

  const toggleShow = () => {
    setIsShowing(!isShowing);
  };
  return (
    <div className="flex justify-around items-center">
      <div className="p-16  flex-1">
        <div className="rounded-md overflow-hidden  shadow-sm ">
          <div className="table-title ">
            <div className="flex justify-center items-center">
              <p className="font-semibold">Application Risk Profile</p>
            </div>
          </div>
          <div className="rounded-md overflow-auto ">
            <table className="tables">
              <thead>
                <tr>
                  <th>
                    <p className="font-semibold">Threat Risk Profile</p>
                  </th>
                  {matrixHeaders.map((header) => (
                    <th key={header}>{formatText(header)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {matrixHeaders.map((header1) => (
                  <tr key={header1}>
                    <th>{formatText(header1)}</th>
                    {matrixHeaders.map((header2) => {
                      const cell = matrix?.find(
                        (item) =>
                          item.application_risk_profile === header2 &&
                          item.threat_risk_profile === header1
                      );
                      return (
                        <td
                          onClick={() => {
                            setId(cell.id);
                            setSelectedValue(cell.result);
                            setSelectedMatrixResult(cell.result);
                          }}
                          key={header2}
                          className="cursor-pointer"
                        >
                          <p>
                            {cell ? formatText(cell.result.toUpperCase()) : ''}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <br />
      </div>
      {selectedValue.length > 1 ? (
        <div
          className={`rounded h-full w-56 mr-10 bg-gray-400 transition-all duration-500 ${
            selectedValue.length > 1
              ? 'opacity-100 max-h-screen'
              : 'opacity-0 max-h-0'
          } overflow-hidden`}
        >
          <div
            onClick={() => setSelectedValue('')}
            className="p-1 flex justify-end items-end mr-1 cursor-pointer"
          >
            <p className="bg px-1 text-white rounded">X</p>
          </div>
          <div className="flex mb-4 text-center h-full flex-col justify-center items-center ">
            {threatModelingMatrixOptions.map((option) => (
              <div key={option.id} className="flex flex-col space-y-10">
                <p
                  onClick={() => {
                    setSelectedValue(option.value);
                    setSelectedMatrixResult(option.value);
                    handleModalShowHideEdit();
                  }}
                  className={`p-1.5 mt-8 text-center ${
                    option.value === selectedValue
                      ? 'font-extrabold shadow-2xl border border-white'
                      : ''
                  } w-36 border-b flex items-center rounded-md px-4 duration-300 cursor-pointer`}
                  style={{
                    backgroundColor: setBackgroundForLevels(option.value),
                  }}
                >
                  {option.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <Modal show={showEdit} className="">
        <Modal.Header onClick={() => handleModalShowHideEdit()}>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="select ">Are You Sure?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => {
              handleModalShowHideEdit();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-add-new"
            onClick={(e) => updateMatrixResult(e)}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ThreatModelingMatrix;
