import React, { useState } from 'react';
import { baseURL } from '../../../resources/apiClient';
import useFileUpload from '../../../hooks/useFileUpload';
import FileUploader from '../../../shared/FileUploader/FileUploader';
import useFileUploadToServer from '../../../hooks/useFileUploadToServer';
const UploadConsensus = ({ toggleFileUploader, fetchData }) => {
  const [showHide, setShowHide] = useState(false);
  const [file, handleFile] = useFileUpload(toggleFileUploader);

  const handleModalShowHide = () => {
    setShowHide(!showHide);
  };
  const { loading, handleUploadFile, handleUploadFileErase } =
    useFileUploadToServer(
      file,
      `${baseURL}/excel-upload/upload-consensus-assessment-questionnaire/`,
      toggleFileUploader,
      fetchData
    );

  return (
    <>
      <div className=" flex flex-col space-y-6 justify-center mt-10 mb-10 items-center">
        <div className="flex flex-col space-y-4 justify-center items-center">
          <FileUploader
            loading={loading}
            file={file}
            handleFile={handleFile}
            handleUploadFile={handleUploadFile}
            handleUploadFileErase={handleUploadFileErase}
            toggleFileUploader={toggleFileUploader}
          />
        </div>
      </div>
    </>
  );
};

export default UploadConsensus;
