import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const IncidentList = ({ path }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const pageCount = Math.ceil(path?.incidents_count / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleCheckBox = (event) => {
    console.log(event.target.checked);

    this.setState({
      isChecked: event.target.checked,
    });
  };
  return (
    <div>
      {path?.paths?.length ? (
        <div>
          <div className="flex flex-col mt-6 justify-center items-center">
            <p className="text-6xl ">Incidents</p>
            <table class="tables p-10 border-collapse   border border-slate-400">
              <thead>
                <tr>
                  <th>Entry</th>
                  <th>Middle</th>
                </tr>
              </thead>
              <tbody>
                {path?.paths
                  ?.filter((types) => types?.type === "incident")
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  .map((items) => {
                    const { entry, middle, enabled, id } = items;
                    return (
                      <tr key={id}>
                        <td>{items.entry ? items.entry : "null"}</td>
                        <td>{items.middle ? items.middle : "null"}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="mt-10">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              activeClassName={"paginationActive"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
            />
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default IncidentList;
