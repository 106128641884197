import React, { useState, useRef } from 'react';
//  // Import css
import { Button, Form, Modal } from 'react-bootstrap';
import FileUploadLoading from '../../../utils/FileUploadLoading';
import swal from 'sweetalert';
import http from '../../../resources/http';
import { RISK_AT_FILE_UPLOAD } from '../../../api/excelUpload';
import useFileUploadToServer from '../../../hooks/useFileUploadToServer';
import FileUploader from '../../../shared/FileUploader/FileUploader';
import useFileUpload from '../../../hooks/useFileUpload';

const UploadATFile = ({ toggleFileUploader, fetchData }) => {
  const [file, handleFile] = useFileUpload(toggleFileUploader);

  const { loading, handleUploadFile, handleUploadFileErase } =
    useFileUploadToServer(
      file,
      RISK_AT_FILE_UPLOAD,
      toggleFileUploader,
      fetchData
    );

  return (
    <FileUploader
      loading={loading}
      file={file}
      handleFile={handleFile}
      handleUploadFile={handleUploadFile}
      handleUploadFileErase={handleUploadFileErase}
      toggleFileUploader={toggleFileUploader}
    />
  );
};

export default UploadATFile;
