import React, { useState, useEffect } from 'react';
import http from '../../../resources/http';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import { COST_CATEGORY } from '../../../api/excelUpload';
import { THREAT_VECTORS_LIST } from '../../../api/threatCatalog';
import { INVESTMENT_REQUIREMENT } from '../../../api/businessProcess';
import { CONTROL_DOMAIN, CONTROL_CATEGORY } from '../../../api/configurations';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import swal from 'sweetalert';
//THREAT_VECTORS_LIST

const ModalHandler = ({
  isOpen,
  mode,
  onClose,
  header,
  selectedItem,
  selectedItemId,
  size,
  url_create,
  url_by_pk,
  fetchData,
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [costCategoryDropdownOptions, setCostCategoryDropdownOptions] =
    useState([]);
  const [threatVectorDropdownOptions, setThreatVectorDropdownOptions] =
    useState([]);

  async function fetchFormOptionData() {
    try {
      const listOfCostCategory = await http.get(COST_CATEGORY);
      const listOfThreatVector = await http.get(THREAT_VECTORS_LIST);

      const costCategoryOptionsData = listOfCostCategory.data?.map(
        (item) => item.category
      );
      setCostCategoryDropdownOptions(costCategoryOptionsData);
      const threatVectorOptions = listOfThreatVector.data?.map(
        (item) => item.name
      );
      setThreatVectorDropdownOptions(threatVectorOptions);
    } catch (error) {
      console.log('error occurred while fetching form dropdown options.');
    }
  }
  useEffect(() => {
    if (isOpen) {
      fetchFormOptionData();
    }
  }, [isOpen]);

  const initialState = {
    cost_category: '',
    threat_vector: '',
    cost_of_breach: '',
    percent_of_total_breach: '',
    threat_event_frequency: '',
    cost_per_incident: '',
    lost_business_cost: '',
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ initialState });
  const [errors, setErrors] = useState({});

  const validations = {
    cost_category: (value) => (value ? '' : 'This field is required.'),
    threat_vector: (value) => (value ? '' : 'This field is required.'),
    cost_of_breach: (value) => (value ? '' : 'This field is required.'),
    percent_of_total_breach: (value) =>
      value ? '' : 'This field is required.',
    threat_event_frequency: (value) => (value ? '' : 'This field is required.'),
    investment_requirement: (value) => (value ? '' : 'This field is required.'),
    cost_per_incident: (value) => (value ? '' : 'This field is required.'),
    lost_business_cost: (value) => (value ? '' : 'This field is required.'),
  };

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }
  };

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };

  useEffect(() => {
    if (mode === 'edit' && selectedItem) {
      console.log(selectedItem, mode, 'selectedItem');
      setLoading(true);
      setFormData(selectedItem);
      setLoading(false);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const formFieldsConfig = {
    cost_category: {
      label: 'Cost Category',
      type: 'select',
      placeholder: 'Select...',
      options: costCategoryDropdownOptions,
      value: formData.cost_category || '',
      onChange: (e) => handleChange('cost_category', e.target.value),
      error: errors.cost_category,
    },
    threat_vector: {
      label: 'Threat Vector',
      type: 'select',
      placeholder: 'Select...',
      options: threatVectorDropdownOptions,
      value: formData.threat_vector || '',
      onChange: (e) => handleChange('threat_vector', e.target.value),
      error: errors.threat_vector,
    },
    cost_of_breach: {
      label: 'Cost of Breach',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.cost_of_breach,
      onChange: (e) => handleChange('cost_of_breach', e.target.value),
      error: errors.cost_of_breach,
    },
    percent_of_total_breach: {
      label: 'Percent of Total Breach',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.percent_of_total_breach,
      onChange: (e) => handleChange('percent_of_total_breach', e.target.value),
      error: errors.percent_of_total_breach,
    },
    threat_event_frequency: {
      label: 'Threat Event Frequency',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.threat_event_frequency,
      onChange: (e) => handleChange('threat_event_frequency', e.target.value),
      error: errors.threat_event_frequency,
    },
    cost_per_incident: {
      label: 'Cost Per Incident',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.cost_per_incident,
      onChange: (e) => handleChange('cost_per_incident', e.target.value),
      error: errors.cost_per_incident,
    },
    lost_business_cost: {
      label: 'Cost Per Incident',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.lost_business_cost,
      onChange: (e) => handleChange('lost_business_cost', e.target.value),
      error: errors.lost_business_cost,
    },
  };

  const handleSubmit = () => {
    let newErrors = {};
    Object.entries(formFieldsConfig).forEach(([key, config]) => {
      if (validations[key]) newErrors[key] = validations[key](formData[key]);
    });
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      // console.log("Submitting form data:", formData);
      if (mode === 'edit') {
        http
          .patch(`${url_by_pk}${selectedItemId}`, formData)
          .then((res) => {
            fetchData();
            onClose();
            swal('Success!', 'It has been updated successfully', 'success');
            resetForm();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
                setErrors(newErrors);
              }
            } else {
              swal('Error', 'Something went wrong, please try again', 'error');
            }
          });
      } else {
        http
          .post(`${url_create}`, formData)
          .then((res) => {
            fetchData();
            onClose();
            swal('Success!', 'It has been updated successfully', 'success');
            resetForm();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
                setErrors(newErrors);
              }
            } else {
              swal('Error', 'Something went wrong, please try again', 'error');
            }
          });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === 'edit' ? `Update  ${header}` : `Add New  ${header}`}
        </Modal.Title>
      </Modal.Header>
      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body>
          <div className="modal-two-columns">
            <Form>
              {Object.entries(formFieldsConfig || {}).map(
                ([key, fieldConfig]) => (
                  <Form.Group key={key}>
                    <Form.Label>{fieldConfig.label}</Form.Label>
                    <Form.Control {...fieldConfig} />
                    {errors[key] && (
                      <Form.Control.Feedback type="invalid">
                        {errors[key]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
              )}
            </Form>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
