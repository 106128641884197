import { createSlice } from "@reduxjs/toolkit";

const domainsSlice = createSlice({
	name: "domains",
	initialState: [],
	reducers: {
		setDomains: (state, action) => action.payload,
		addDomain: (state, action) => {
			state.push(action.payload);
		},
		updateDomain: (state, action) => {
			const index = state.findIndex(
				(domain) => domain.id === action.payload.id
			);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteDomain: (state, action) => {
			return state.filter((domain) => domain.id !== action.payload.id);
		},
	},
});

export const { setDomains, addDomain, updateDomain, deleteDomain } =
	domainsSlice.actions;

export default domainsSlice.reducer;
