import { useState, useEffect } from 'react';

export const usePropertyTransformation = (initialData) => {
  const [transformedData, setTransformedData] = useState([]);

  useEffect(() => {
    const transformedArray = initialData?.map((item) => {
      const {
        client_control_catalog,
        threat_risk_profile,
        threat_vector,
        ...rest
      } = item;
      return {
        ...rest,
        relevance: client_control_catalog?.relevance,
        severity_value: threat_risk_profile?.value,
        reduction_percentage:
          threat_vector?.threat_severity?.reduction_percentage,
        client_control_catalog,
        threat_risk_profile,
      };
    });

    // Sort the array in descending order based on the relevance property
    // transformedArray?.sort((a, b) => b.relevance - a.relevance);

    setTransformedData(transformedArray);
  }, [initialData]);

  return transformedData;
};
