import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Button, Form, Modal } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import swal from 'sweetalert';
import * as XLSX from 'xlsx';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import http from '../resources/http';
import { baseURL } from '../resources/apiClient';
import { removeDuplicates, resolveResponse } from '../utils/helpers';
import MultipleSelectCheckmarks from '../shared/MultiSelect/MultiSelect';
import LoadingComponent from '../shared/LoadingDropDownSelection/LoadingComponent';

const ControlCatalogAddRecord = ({
  fetchCatalogs,
  handleModalShowHideAdd,
  url,
  controls,
}) => {
  // fetched data var
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [threatVectors, setThreatVectors] = useState([]);
  const [title, setTitle] = useState('');
  const [showHideMe, setSetShowHideMe] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  console.log(controls, 'controls');

  // for page pagination
  const uniqueThreats = removeDuplicates(threatVectors);
  console.log(uniqueThreats, 'threatVectors');

  const [industryUseCaseConfigData, setIndustryUseCaseConfigData] = useState(
    []
  );
  const [industryStandardConfigData, setIndustryStandardConfigData] = useState(
    []
  );

  const [preSelectedSecondaryList, setPreSelectedSecondaryList] = useState([]);
  const secondaryThreatVectorsOptions = threatVectors?.map(({ name }) => name);
  const [secondaryList, setSecondaryList] = useState([]);
  const handleSecondaryThreatVectorsOptionsChange = (selected) => {
    setSecondaryList(selected);
  };

  const [preSelectedUseCaseList, setPreSelectedUseCaseList] = useState([]);
  console.log(threatVectors, 'threatVectors');
  const [useCaseList, setUseCaseList] = useState([]);
  const IndustryUseCasesOptions = industryUseCaseConfigData?.map(
    ({ title }) => title
  );
  const handleIndustryUseCasesOptionsChange = (selected) => {
    setUseCaseList(selected);
  };
  const [preSelectedStandardList, setPreSelectedStandardList] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const IndustryStandardsOptions = industryStandardConfigData?.map(
    ({ title }) => title
  );
  const handleIndustryStandardsOptionsChange = (selected) => {
    setStandardList(selected);
  };

  //form data and error handler
  const [form, setForm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  //character limiter
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const {
      control_domain = '',
      control_category = '',
      control_name = '',
      control_type = '',
      control_impact = '',
      control_scope = '',
      control_applicability = '',
      primary_threat = '',
      secondary_threat = [],
      industry_usecase = '',
      industry_standard = '',
      industry_identifier = '',
      control_efficacy = '',
      rationalized_threats = '',
      control_description = '',
      cmsName = '',
      stride = '',
      mitre = '',
      owasp = '',
      example_threat = '',
      iso_nist = '',
    } = form; //declare the variables and assign the values from the form object
    const newErrors = {};
    if (
      !control_domain ||
      control_domain === '' ||
      control_domain === 'Select...'
    )
      newErrors.control_domain = 'Control domain is required.';
    if (
      !control_category ||
      control_category === '' ||
      control_category === 'Select...'
    )
      newErrors.control_category = 'Control category is required.';
    if (
      !control_category ||
      control_category === '' ||
      control_category === 'Select...'
    )
      newErrors.control_category = 'Control category is required.';
    if (!control_name || control_name === '')
      newErrors.control_name = 'Control name is required.';
    if (
      !control_impact ||
      control_impact === '' ||
      control_impact === 'Select...'
    )
      newErrors.control_impact = 'Control impact is required.';

    if (!control_type || control_type === '' || control_type === 'Select...')
      newErrors.control_type = 'Control type is required.';
    if (!control_scope || control_scope === '' || control_scope === 'Select...')
      newErrors.control_scope = 'Control scope is required.';
    if (
      !control_applicability ||
      control_applicability === '' ||
      control_applicability === 'Select...'
    )
      newErrors.control_applicability = 'Control applicability is required.';
    if (
      !primary_threat ||
      primary_threat === '' ||
      primary_threat === 'Select...'
    )
      newErrors.primary_threat = 'Primary threat is required.';
    if (!industry_usecase || industry_usecase === '')
      newErrors.industry_usecase = 'Industry Use case is required.';
    return newErrors;
  };

  const clearAll = () => {
    form.control_domain = '';
    form.control_category = '';
    form.control_name = '';
    form.control_type = '';
    form.control_impact = '';
    form.control_scope = '';
    form.control_applicability = '';
    form.primary_threat = '';
    form.secondary_threats = '';
    form.industry_usecase = '';
    form.control_description = '';
  };
  const [showHide, setSetShowHide] = useState(false);
  const handleModalShowHides = () => {
    setSetShowHide(!showHide);
  };
  const [descriptionText, setDescriptionText] = useState('');
  //configurable datas
  const [controlDomainConfigData, setControlDomainConfigData] = useState([]);
  const [controlCategoryConfigData, setControlCategoryConfigData] = useState(
    []
  );
  const [fetchLoading, setFetchLoading] = useState(false);
  const [controlTypeConfigData, setControlTypeConfigData] = useState([]);
  const [controlImpactConfigData, setControlImpactConfigData] = useState([]);
  const [controlScopeConfigData, setControlScopeConfigData] = useState([]);
  const [controlApplicabilityConfigData, setControlApplicabilityConfigData] =
    useState([]);

  //update fields start
  const [controlId, setControlId] = useState('');
  const [showFileUploader, setShowFileUploader] = useState(false);
  const toggleFileUploader = () => setShowFileUploader(!showFileUploader);
  const [selectErrors, setSelectErrors] = useState('Please select');
  const [isLoading, setIsLoading] = useState(false);
  const submit = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 1) {
      setErrors(formErrors);
    } else if (
      secondaryList.length === 0 &&
      useCaseList.length === 0 &&
      standardList.length === 0
    ) {
      setSelectErrors('Please select');
    } else {
      setIsLoading(true);
      http
        .post(`${baseURL}${url}`, {
          control_domain: form.control_domain,
          control_category: form.control_category,
          control_name: form.control_name,
          control_type: form.control_type,
          control_impact: form.control_impact,
          control_scope: form.control_scope,
          control_applicability: form.control_applicability,
          primary_threat: form.primary_threat,
          secondary_threat: secondaryList,
          industry_usecase: useCaseList,
          industry_standard_text: standardList,
          industry_identifier: form.industry_identifier,
          control_efficacy: form.control_efficacy,
          rationalized_threats: form.rationalized_threats,
          control_description: form.control_description,
          cmc_name: form.cmsName,
          stride: form.stride,
          owasp: form.owasp,
          mitre: form.mitre,
          iso_nist: form.iso_nist,
          example_threat: form.example_threat,
          is_applicable: true,
          is_implemented: true,
        })
        .then(
          (response) => {
            setIsLoading(false);
            fetchCatalogs();
            handleModalShowHideAdd();
            swal('Success', 'It has been add successfully.', 'success');
          },
          (err) => {
            setIsLoading(false);

            swal('Error', 'Something went wrong, please try again.', 'error');
          }
        );
    }
    setSubmitted(true);
  };
  const initializeFormData = () => {
    setControlId(controls.id);
    form.cmsName = controls?.cmc_name;
    form.control_description = controls?.control_description;
    form.control_domain = controls?.control_domain;
    form.control_category = controls?.control_category;
    form.control_name = controls?.control_name;
    form.control_type = controls?.control_type;
    form.control_impact = controls?.control_impact;
    form.control_scope = controls?.control_scope;
    form.control_applicability = controls?.control_applicability;
    form.primary_threat = controls?.primary_threat;
    setPreSelectedStandardList(controls?.industry_standard_text);
    setPreSelectedUseCaseList(controls?.industry_usecase);
    setPreSelectedSecondaryList(controls?.secondary_threat);
    form.control_efficacy = controls?.control_efficacy;
    form.rationalized_threats = controls?.rationalized_threats;
    form.industry_identifier = controls?.industry_identifier;
    form.stride = controls?.stride;
    form.owasp = controls?.owasp;
    form.mitre = controls?.mitre;
    form.iso_nist = controls?.iso_nist;
    form.example_threat = controls?.example_threat;
  };

  const updateControlsCatalog = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 1) {
      setErrors(formErrors);
    } else if (
      secondaryList.length === 0 &&
      useCaseList.length === 0 &&
      standardList.length === 0
    ) {
      setSelectErrors('Please select');
    } else {
      setIsLoading(true);
      http
        .patch(`${baseURL}/excel-upload/controls-catalog/${controlId}/`, {
          control_domain: form.control_domain,
          control_category: form.control_category,
          control_name: form.control_name,
          control_type: form.control_type,
          control_impact: form.control_impact,
          control_scope: form.control_scope,
          control_applicability: form.control_applicability,
          primary_threat: form.primary_threat,
          secondary_threat: secondaryList,
          industry_usecase: useCaseList,
          industry_standard_text: standardList,
          industry_identifier: form.industry_identifier,
          control_efficacy: form.control_efficacy,
          rationalized_threats: form.rationalized_threats,
          control_description: form.control_description,
          cmc_name: form.cmsName,
          stride: form.stride,
          owasp: form.owasp,
          mitre: form.mitre,
          iso_nist: form.iso_nist,
          example_threat: form.example_threat,
        })
        .then((response) => {
          setIsLoading(false);
          handleModalShowHideAdd();
          fetchCatalogs();
          swal('Success', 'It has been updated successfully.', 'success');
        })
        .catch((err) => {
          handleModalShowHideAdd();
          setIsLoading(false);
          swal('Error', 'Something went wrong, please try again.', 'error');
        });
    }
    setSubmitted(true);
  };

  const fetchData = () => {
    http
      .get(`${baseURL}/configurations/control-domain`)
      .then((response) => {
        setControlDomainConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/configurations/control-category`)
      .then((response) => {
        setControlCategoryConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/excel-upload/control-type`)
      .then((response) => {
        setControlTypeConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/excel-upload/control-impact`)
      .then((response) => {
        setControlImpactConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/excel-upload/control-scope`)
      .then((response) => {
        setControlScopeConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/excel-upload/control-applicability`)
      .then((response) => {
        setControlApplicabilityConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/threat-catalog/threat-vectors`)
      .then((response) => {
        setThreatVectors(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/configurations/industry-usecase`)
      .then((response) => {
        setIndustryUseCaseConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/configurations/industry-standard`)
      .then((response) => {
        setIndustryStandardConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    initializeFormData();
    fetchData();
    fetchCatalogs();
  }, [title]);

  useEffect(() => {
    http
      .get(`${baseURL}/excel-upload/control-catalog-domains`)
      .then((response) => {
        setDatas(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          swal(
            'There is no data to display at the moment, please try again later.'
          );
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);

  return (
    <>
      {/* {isLoading ? <LoadingComponent /> : null} */}
      <div className="grid grid-cols-3 gap-2">
        <Form.Group>
          <Form.Label for="text">Control Domain</Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_domain}
            onChange={(e) => setField('control_domain', e.target.value)}
            isInvalid={!!errors.control_domain}
            required
            name="text"
          >
            <option>Select...</option>
            {controlDomainConfigData?.map((x, y) => (
              <option value={x.name}>{x.name}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_domain}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">Primary Threat (Mitigated)</Form.Label>
          <Form.Control
            as="select"
            id="primary_threat_add"
            value={form.primary_threat}
            onChange={(e) => setField('primary_threat', e.target.value)}
            isInvalid={!!errors.primary_threat}
            required
            name="text"
            placeholder="Enter Primary Threat (Mitigated)"
          >
            <option>Select...</option>
            {uniqueThreats?.map((x, y) => (
              <option value={x.name}>{x.name}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.primary_threat}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Secondary Threats
          </Form.Label>
          <MultipleSelectCheckmarks
            data={secondaryThreatVectorsOptions}
            onChange={handleSecondaryThreatVectorsOptionsChange}
            tag="Select Secondary Threats"
            initialSelectedValues={preSelectedSecondaryList}
          />
          {console.log(preSelectedSecondaryList, 'preSelectedSecondaryList')}

          {submitted && secondaryList.length === 0 && (
            <p className="text-red-400">{selectErrors} Secondary Threats</p>
          )}
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Industry Use Case
          </Form.Label>
          <MultipleSelectCheckmarks
            data={IndustryUseCasesOptions}
            onChange={handleIndustryUseCasesOptionsChange}
            tag="Select Industry Use Case"
            initialSelectedValues={preSelectedUseCaseList}
          />
          {submitted && useCaseList.length === 0 && (
            <p className="text-red-400">{selectErrors} Industry Usecase</p>
          )}
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Industry Standards
          </Form.Label>
          <MultipleSelectCheckmarks
            data={IndustryStandardsOptions}
            onChange={handleIndustryStandardsOptionsChange}
            tag="Select Industry Standards"
            initialSelectedValues={preSelectedStandardList}
          />
          {submitted && standardList.length === 0 && (
            <p className="text-red-400">{selectErrors} Industry Standards</p>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">Control Category</Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_category}
            onChange={(e) => setField('control_category', e.target.value)}
            isInvalid={!!errors.control_category}
            required
            name="text"
          >
            <option>Select...</option>
            {controlCategoryConfigData
              ?.filter((list) => list.title.length > 1)
              ?.map((x, y) => (
                <option value={x.title}>{x.title}</option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_category}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">NIST/CSA Id: Control Name</Form.Label>
          <Form.Control
            type="text"
            id="text"
            value={form.control_name}
            onChange={(e) => setField('control_name', e.target.value)}
            isInvalid={!!errors.control_name}
            placeholder="Text..."
            required
            name="text"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">Control Type</Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_type}
            onChange={(e) => setField('control_type', e.target.value)}
            isInvalid={!!errors.control_type}
            required
            name="text"
          >
            <option>Select...</option>
            {controlTypeConfigData?.map((x, y) => (
              <option value={x.control_type}>{x.control_type}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_type}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">Control Impact</Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_impact}
            onChange={(e) => setField('control_impact', e.target.value)}
            isInvalid={!!errors.control_impact}
            required
            name="text"
          >
            <option>Select...</option>
            {controlImpactConfigData?.map((x, y) => (
              <option value={x.control_impact}>{x.control_impact}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_impact}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">Control Scope</Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_scope}
            onChange={(e) => setField('control_scope', e.target.value)}
            isInvalid={!!errors.control_scope}
            required
            name="text"
          >
            <option>Select...</option>
            {controlScopeConfigData?.map((x, y) => (
              <option value={x.control_scope}>{x.control_scope}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_scope}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">Control Applicability</Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_applicability}
            onChange={(e) => setField('control_applicability', e.target.value)}
            isInvalid={!!errors.control_applicability}
            required
            name="text"
          >
            <option>Select...</option>
            {controlApplicabilityConfigData?.map((x, y) => (
              <option value={x.control_applicability}>
                {x.control_applicability}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_applicability}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label for="text">Control Description</Form.Label>

          <Form.Control
            as="textarea"
            type="text"
            id="control_description"
            value={form.control_description}
            onChange={(e) => setField('control_description', e.target.value)}
            isInvalid={!!errors.control_description}
            required
            name="text"
            placeholder="Text..."
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_description}
          </Form.Control.Feedback>
        </Form.Group>{' '}
      </div>
      {controls?.length < 1 ? (
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => {
              handleModalShowHideAdd();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-add-new"
            onClick={(e) => {
              submit(e);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => {
              handleModalShowHideAdd();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-add-new"
            onClick={(e) => {
              updateControlsCatalog(e);
            }}
          >
            Save Changes{' '}
          </Button>
        </Modal.Footer>
      )}
    </>
  );
};

export default ControlCatalogAddRecord;
