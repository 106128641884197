import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { ApolloError } from '@apollo/client';
import toast from 'react-hot-toast';
import Modal from '../../../shared/Modal';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import Form from '../../../shared/Form';

const ModalHandler = ({
  isOpen,
  mode,
  onClose,
  size,
  selectedItemId,
  selectedItem,
  header,
  url,
  updateMutation,
  createMutation,
  fetchData,
}) => {
  const initialState = {
    name: '',
    description: '',
  };
  const [fetchLoading, setFetchLoading] = useState(false);
  const [formData, setFormData] = useState({ initialState });
  const [errors, setErrors] = useState({});

  const validations = {
    control_type: (value) => (value ? '' : 'This field is required.'),
  };

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));

    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }
  };

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };

  const formFieldsConfig = {
    name: {
      label: 'Industry Name',
      type: 'text',
      placeholder: 'Text...',
      value: formData?.name,
      onChange: (e) => handleChange('name', e.target.value),
      error: errors.label,
    },
    description: {
      label: 'Description',
      type: 'textarea',
      maxLength: 400,
      placeholder: 'Text...',
      value: formData?.description,
      onChange: (e) => handleChange('description', e.target.value),
      error: errors.description,
    },
  };

  useEffect(() => {
    if (mode === 'edit' && selectedItem) {
      setFormData(selectedItem);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const handleSubmit = async () => {
    let newErrors = {};
    Object.entries(formFieldsConfig).forEach(([key, config]) => {
      if (validations[key]) newErrors[key] = validations[key](formData[key]);
    });
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      setFetchLoading(true);
      try {
        let response;

        if (mode === 'edit') {
          response = await updateMutation({
            variables: {
              id: parseInt(selectedItemId),
              input: {
                name: formData.name,
                description: formData.description,
              },
            },
          });
        } else {
          response = await createMutation({
            variables: {
              inputs: {
                created_at: new Date().toISOString().split('T')[0],
                name: formData.name,
                description: formData.description,
              },
            },
          });
        }

        const dynamicKey = Object.keys(response.data)[0]; // Get the dynamic key

        if (response.data[dynamicKey].affected_rows > 0) {
          toast.success(
            mode === 'edit'
              ? 'It has been updated successfully'
              : 'It has been added successfully'
          );
          resetForm();
          fetchData();
          onClose();
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          const errorMessage = error.message.includes('duplicate key value')
            ? 'Control  label already exists. Please choose a different one.'
            : 'An error occurred while processing your request.';
          toast.error(errorMessage);
        } else {
          console.error('Unexpected error:', error); // Log unexpected errors
          toast.error('An unexpected error occurred.');
        }
      } finally {
        setFetchLoading(false);
      }
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === 'edit' ? `Update  ${header}` : `Add New ${header}`}
        </Modal.Title>
      </Modal.Header>
      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body>
          <Form>
            {Object.entries(formFieldsConfig || {}).map(
              ([key, fieldConfig]) => (
                <Form.Group key={key}>
                  <Form.Label>{fieldConfig.control_type}</Form.Label>
                  <Form.Control {...fieldConfig} />
                  {errors[key] && (
                    <Form.Control.Feedback type="invalid">
                      {errors[key]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )
            )}
          </Form>
        </Modal.Body>
      )}
      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
