import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";

const FilePreviewer = ({ file }) => {
	const [excelData, setExcelData] = useState(null);

	useEffect(() => {
		// Logic to parse Excel file and extract data
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
			setExcelData(excelData);
		};

		reader.readAsArrayBuffer(file);
	}, [file]);

	return (
		<div className="file-previewer">
			{/* Display the parsed Excel data */}
			<table className="tables">
				<thead className="sticky-header">
					<tr>
						{excelData &&
							Object.keys(excelData[0]).map((key) => <th key={key}>{key}</th>)}
					</tr>
				</thead>
				<tbody>
					{excelData &&
						excelData.map((row, index) => (
							<tr key={index}>
								{Object.values(row).map((value, colIndex) => (
									<td key={colIndex}>{value}</td>
								))}
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};

export default FilePreviewer;
