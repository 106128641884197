import axios from 'axios';
import { decryptData, encryptData } from './encryptionPayLoad';

const baseURL = process.env.REACT_APP_BASE_URL;
// const baseURL = 'https://api-dev.cronusgrx.io/api';
const http = axios.create({
  baseURL: `${baseURL}/`,
});

// Function to refresh the access token using the refresh token
const refreshAccessToken = async () => {
  try {
    const data = JSON.parse(localStorage.getItem('cyber-minds'));
    if (data && data.user_status.refresh_token) {
      const response = await axios.post(`${baseURL}/accounts/refresh-token`, {
        refresh_token: data.user_status.refresh_token,
      });
      const newAccessToken = response.data.access_token;
      data.user_status.access_token = newAccessToken;
      localStorage.setItem('cyber-minds', JSON.stringify(data));
      // Update axios default header
      http.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${newAccessToken}`;

      return newAccessToken;
    }
  } catch (error) {
    console.error('Error refreshing access token:', error);
    window.location.href = '/signin';
  }
};

// request interceptors
http.interceptors.request.use(
  async (config) => {
    try {
      let data = JSON.parse(localStorage.getItem('cyber-minds'));
      //   if (
      //     config.data &&
      //     !config.headers['Content-Type']?.includes('multipart/form-data')
      //   ) {
      //     const encryptedData = encryptData(JSON.stringify(config.data));
      //     config.data = { data: encryptedData };
      //   }
      if (data && data.user_status.access_token) {
        config.headers[
          'Authorization'
        ] = `Bearer ${data.user_status.access_token}`;
      }
      return config;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

//  response interceptors
http.interceptors.response.use(
  async (response) => {
    if (response.data) {
      try {
        response.data = JSON.parse(decryptData(response.data));
      } catch (error) {
        console.error('Error decrypting response data:', error);
        throw new Error('DecryptionFailed');
      }
    }
    console.log(response.data, 'decoded response');
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        await refreshAccessToken();
        return http.request(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    } else if (error.response) {
      // for non 401 error
      error.response.data = JSON.parse(decryptData(error.response.data));
    }
    return Promise.reject(error);
  }
);
export default http;
