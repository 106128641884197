// CardComponent.js
import React from "react";

const ScenarioCardComponent = ({
	item,
	isChecked,
	onCheckboxChange,
	onCompare,
}) => {
	return (
		<div className="rounded-xl py-1.5 px-2">
			<label className="items-center text-xs">
				{" "}
				<button className="text-xs" onClick={() => onCompare(item.id)}>
					{isChecked ? (
						<span className=" btn-add-new">Compare</span>
					) : (
						"Add to Compare"
					)}
				</button>
				<input
					type="checkbox"
					className="p-2 w-4 h-4 ml-2 border hover:bg-gray-400 rounded-lg items-center"
					checked={isChecked}
					onChange={(e) => onCheckboxChange(item.id, e.target.checked)}
				/>
			</label>
		</div>
	);
};

export default ScenarioCardComponent;
