const localData = JSON.parse(localStorage.getItem('cyber-minds'));
const darkMode = localData?.theme?.darkMode;

export const ticketStatus = [
  { id: 1, label: 'Set Ticket as resolved', name: 'closed' },
  { id: 2, label: 'Set Ticket as pending', name: 'pending' },
  { id: 2, label: 'Set Ticket as open', name: 'open' },
];
export const userTicketStatus = [
  { id: 1, label: 'Set Ticket as canceled', name: 'canceled' },
];
export const ticketUrgecyStatus = [
  { id: 1, label: 'Low - Medium response', name: 'low' },
  { id: 2, label: 'High - Urgent response', name: 'high' },
];
export const sensetivities = [
  { id: 1, name: 'low' },
  { id: 2, name: 'medium' },
  { id: 3, name: 'high' },
];

export const testData = [
  { id: 1, name: 'Test', description: 'Test Description', level: 3 },
  { id: 2, name: 'Test', description: 'Test Description', level: 5 },
  { id: 3, name: 'Test', description: 'Test Description', level: 3 },
];

export const paths = [
  { id: 1, name: 'start' },
  { id: 2, name: 'middle' },
  { id: 3, name: 'end' },
];
export const accountsSideNavData = [
  {
    id: 1,
    name: 'Clients Accounts',
    to: '/manage_clients',
  },
  {
    id: 2,
    name: 'Users Accounts',
    to: '/manage_users',
  },
];

export const controlsSidebarData = [
  {
    id: 9,
    name: 'Control Type test',
    to: '/manage_control_type_test',
  },
  {
    id: 1,
    name: 'Control Domain',
    to: '/manage_control_domain',
  },
  {
    id: 2,
    name: 'Control Category',
    to: '/manage_control_category',
  },
  {
    id: 3,
    name: 'Control Type',
    to: '/manage_control_type',
  },
  {
    id: 4,
    name: 'Control Impact',
    to: '/manage_control_impact',
  },
  { id: 5, name: 'Control Scope', to: '/manage_control_scope' },
  {
    id: 6,
    name: 'Control Applicability',
    to: '/manage_control_availability',
  },
  {
    id: 7,
    name: 'Industry Use Cases',
    to: '/industry_use_cases',
  },
  {
    id: 8,
    name: 'Industry Standard',
    to: '/industry_standards',
  },
];
export const threatRepositorySidebarData = [
  {
    id: 1,
    name: 'Availability',
    to: '/manage_availability',
  },
  {
    id: 2,
    name: 'Confidentiality',
    to: '/manage_confidentiality',
  },
  { id: 3, name: 'Discoverability', to: '/manage_discoverability' },
  {
    id: 4,
    name: 'Exploitability',
    to: '/manage_exploitability',
  },
  {
    id: 5,
    name: 'Integrity',
    to: '/manage_integrity',
  },
  {
    id: 6,
    name: 'Reproducibility',
    to: '/manage_reproducibility',
  },
  {
    id: 7,
    name: 'Threat Category',
    to: '/manage_threatcategory',
  },
];

export const businessProcessSidebarData = [
  {
    id: 1,
    name: 'Business Criticality (RPO)',
    to: '/configurations/business_process/criticality',
  },
  {
    id: 2,
    name: 'Classification Level of data',
    to: '/configurations/business_process/classification_level_of_data',
  },
  {
    id: 3,
    name: 'Broadest population of User',
    to: '/configurations/business_process/broadcast_population_of_user',
  },
  {
    id: 4,
    name: 'User Population',
    to: '/configurations/business_process/user_population',
  },
  {
    id: 5,
    name: 'Application Accessibility',
    to: '/configurations/business_process/application_accessibility',
  },
  {
    id: 6,
    name: 'Hosting Model',
    to: '/configurations/business_process/hosting_model',
  },
  {
    id: 7,
    name: 'User Interface Accessibility',
    to: '/configurations/business_process/user_interface_accessibility',
  },
  {
    id: 8,
    name: 'Source/Destination of data',
    to: '/configurations/business_process/origin_of_data',
  },
  {
    id: 9,
    name: 'Business Impact',
    to: '/configurations/business_process/business_impacts',
  },
];

export const userStatus = [
  {
    id: 1,
    name: 'Activate',
    value: 'active',
  },
  {
    id: 2,
    name: 'Deactivate',
    value: 'inactive',
  },
];
export const roles = ['ClientUser', 'ClientAdmin'];
export const rolesStaff = ['ClientUser', 'ClientAdmin', 'CybermindAdmin'];
export const options = [
  {
    label: 'Client User',
    value: 'ClientUser',
  },
  {
    label: 'Client Admin',
    value: 'ClientAdmin',
  },
  {
    label: 'CMC Support',
    value: 'CMCSupport',
  },
];

export const threatModelingMatrixOptions = [
  {
    id: 1,
    value: 'very_low',
    text: 'Very Low',
  },
  {
    id: 2,
    value: 'low',
    text: 'Low',
  },
  {
    id: 3,
    value: 'high',
    text: 'High',
  },
  {
    id: 4,
    value: 'moderate',
    text: 'Moderate',
  },
  {
    id: 5,
    value: 'critical',
    text: 'Critical',
  },
];

export const complianceRequirementsOptions = [
  'PCI/DSS',
  'GDPR',
  'CCPA',
  'HIPAA',
  'SOX',
];

export const pathOptions = {
  sankey: {
    node: {
      interactivity: true, // Enable interactivity for nodes
      colors: ['#101f3a', '#FFC93C', '#144272'],
      width: 15,
      nodePadding: 25,
      nodeShape: 'circle',

      label: {
        fontName: 'Roboto',
        fontSize: 22,
        textStyle: {
          wrapText: true, // Enable text wrapping
        },
        color: darkMode ? 'whitesmoke' : 'black',
        fontWeight: 'bolder',
      },
    },
    link: {
      colorMode: 'gradient',
      opacity: 0.3,
      width: 0.5, // Adjust path line thickness here
      colors: ['#101f3a', '#FFC93C', '#144272'],
      hoveredLinkColor: '#000', // Make hover color bolder
      css: `
        .google-visualization-sankey-link a:hover {
          stroke: black;
          stroke-opacity: 1;
        }
      `,
    },
    tooltip: {
      trigger: 'selection',
    },
  },
};
export const columns = [
  { type: 'string', label: 'From' },
  { type: 'string', label: 'To' },
  { type: 'number', label: 'Repetitions: ' },
];

export const pathOptionsDropDown = [
  { id: 1, label: 'Entry', value: 'Entry' },
  { id: 2, label: 'Middle', value: 'Middle' },
  { id: 3, label: 'End', value: 'End' },
];

export const superAdminHeaders = [
  { id: 1, name: 'Threat Repository Report', to: '/super_threat_vectors' },
];

export const superAdminClientUsers = [
  { id: 2, name: 'Clients', to: '/manage_clients' },
  { id: 3, name: 'Accounts', to: '/manage_users' },
];
export const superAdminHeadersManageFiles = [
  { id: 4, name: 'Assets Threat Mapping', to: '/manage_at_mapping' },
  { id: 29, name: 'Assets Threat', to: '/AT_mapping' },

  { id: 5, name: 'Business Impact Analysis', to: '/business_impact' },
  { id: 6, name: 'Control Catalog', to: '/manage_control_catalog' },
  {
    id: 7,
    name: 'Control Effectiveness Matrix',
    to: '/manage_control_matrix',
  },
  { id: 8, name: 'Costs', to: '/upload_costs' },
  { id: 9, name: 'CSA', to: '/manage_consensus' },
  { id: 10, name: 'Industry Data', to: '/upload_industry_data' },
  { id: 11, name: 'NIST', to: '/manage_NIST' },
  { id: 12, name: 'Threat Risk Mapping', to: '/risk_tc_mapping' },
  {
    id: 13,
    name: 'Response Distribution Data',
    to: '/response_distribution_data',
  },
];
export const superAdminHeadersConfigurations = [
  { id: 14, name: 'Asset', to: '/manage_asset' },
  { id: 15, name: 'Business Process', to: '/super_admin_business_process' },
  { id: 16, name: 'Controls', to: '/manage_control_domain' },
  {
    id: 17,
    name: 'Currency',
    to: '/manage_currency',
  },
  {
    id: 18,
    name: 'Cost Category',
    to: '/cost_category',
  },
  {
    id: 19,
    name: 'Exploitable Paths',
    to: '/super-admin-manage-exploitable-paths',
  },
  { id: 20, name: 'Industry', to: '/manage_sector' },
  {
    id: 21,
    name: 'Investment Requirements',
    to: '/manage_investment_requirements',
  },
  { id: 22, name: 'Risk Probability', to: '/manage_risk_probability' },
  {
    id: 23,
    name: 'Risk Sensitivity',
    to: '/risk_sensitivity',
  },
  {
    id: 25,
    name: 'Threat Modeling Matrix',
    to: '/threat_modeling_matrix_configuration',
  },
  {
    id: 24,
    name: 'Threat Repository',
    to: '/manage_availability',
  },
];

export const superAdminHeadersManageAccounts = [
  {
    id: 28,
    name: 'Accounts',
    to: '/manage_clients',
  },
];
