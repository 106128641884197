export const setBackgroundForLevels = (value) => {
  switch (value) {
    case "very_low":
      return "lightgreen";
    case "low":
      return "green";
    case "moderate":
      return "yellow";
    case "high":
      return "orange";
    case "critical":
      return "maroon";
    default:
      return "#273c63";
  }
};
