import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { businessProcessData } from '../authentication/store/actions';
import { newsData } from '../data/newsData';
import AssetDetails from '../layout/components/Threat Scope/MoreDetails/AssetDetails';
import VendorDetailsBrief from '../layout/components/Threat Scope/MoreDetails/VendorDetailsBrief';
import { baseURL } from '../resources/apiClient';
import http from '../resources/http';
import { formatNumber } from '../utils/formatNumbers';
import LoadingSpinner from '../utils/LoadingSpinner';
import NoDataToShow from '../utils/NoDataToShow';
import { sessionChecker } from '../utils/sessionChecker';
import Modal from './../shared/Modal';
import { GET_BUSINESS_IMPACT_ANALYSIS } from '../graphql/client/query';
import { useQuery } from '@apollo/client';
const BusinessImpactAnalysis = (props) => {
  // const processId = useSelector((state) => state.pid);
  const processName = useSelector((state) => state.name);
  const usersCurrency = useSelector((state) => state.user)?.client_obj
    ?.currency;
  const processId = useSelector((state) => state.pid);
  const [datass, setData] = useState({});
  const [path, setPath] = useState([]);
  console.log(usersCurrency, 'usersCurrency');
  const [datas, setDatas] = useState([]);

  const [modalAsset, setModalAsset] = useState(false);
  const showModalAsset = () => setModalAsset(!modalAsset);
  const [foo, setFoo] = useState(false);
  const showFoo = () => setFoo(!foo);
  const [fooo, setFooo] = useState(false);
  const showFooo = () => setFooo(!fooo);
  const dispatch = useDispatch();
  const [dat, setDat] = useState([]);
  const [addCompany, setAddCompany] = useState(false);
  const toggleAddCompany = () => setAddCompany(!addCompany);
  const [showAsset, setShowAsset] = useState(false);
  const toggleAsset = () => setShowAsset(!showAsset);
  const [riskDistribution, setRiskDistribution] = useState(false);
  const toggleRiskDistribution = () => setRiskDistribution(!riskDistribution);
  const [businessRisks, setBusinessRisks] = useState(false);
  const toggleBusinessRisks = () => setBusinessRisks(!businessRisks);
  const [loadings, setLoading] = useState(true);

  console.log(processId, 'process id');

  const { data, loading, error, refetch } = useQuery(GET_BUSINESS_IMPACT_ANALYSIS, {
    variables: { business_process_id: parseInt(processId)??0},
  });

  const processData = useSelector((state) => state.businessProcessData);
  // const [showAll, setShowAll] = useState(false);
  // const [itemsToShow, setItemsToShow] = useState(3);

  // const toggleShowAll = () => {
  //   if (showAll) {
  //     setItemsToShow(3); // Show only three items when "See Less" is clicked
  //   } else {
  //     const criticalityArray = data?.regulations || []; // Ensure it's an array, default to empty array
  //     setItemsToShow(criticalityArray.length > 3 ? criticalityArray.length : 3); // Show all items when "See More" is clicked, with a default of 3
  //   }
  //   setShowAll(!showAll);
  // };

  // useEffect(() => {
  //   sessionChecker();
  //   http
  //     .get(`${baseURL}/business_process/business-impact/${processId}`)
  //     .then((response) => {
  //       setData(response.data);
  //       //set the incoming response to the redux variable(array/object)
  //       dispatch(businessProcessData(response.data));
  //       console.log(response.data, 'bia');
  //       setLoading(false);
  //     })
  //     .then(
  //       (response) => {},
  //       (err) => {
  //         setLoading(false);
  //       }
  //     )
  //     .catch((err) => {
  //       return false;
  //     });
  // }, [processId]);

  useEffect(() => {
    // http
    // 	.get(`${baseURL}/business_process/exploitable-paths/`)
    // 	.then((response) => {
    // 		setPath(response.data);
    // 	})
    // 	.then(
    // 		(response) => {},
    // 		(err) => {
    // 			console.log(err);
    // 		}
    // 	)
    // 	.catch((err) => {
    // 		return false;
    // 	});
    // http
    //   .get(`${baseURL}/business_process/get-business-impact/${processId}`)
    //   .then((response) => {
    //     setDatas(response.data);
    //   })
    //   .catch(function (error) {
    //     // if (error?.response?.status === 403) {
    //     //   props.history.push('/signin');
    //     // }
    //   });
  }, [processName]);

  try {
    var asset = data.assets;
    var low = data?.asset_risks?.find((obj) => obj.total_Low);
    var medium = data?.asset_risks?.find(
      (obj) => obj.total_Medium
    ).total_Medium;
    var high = data?.asset_risks?.find((obj) => obj.total_High).total_High;
  } catch (err) {
    console.log('No data to show');
  }
  console.log(data, 'process Data');
  return (
    <div className="relative">
      {true?(
        <div>
          <div className="p-10 grid place-items-center  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3  gap-4">
            <div
              style={{ borderBottom: '4px solid #273c63' }}
              className="rounded-lg flex flex-col  w-full justify-evenly shadow"
            >
              <div className=" h-fit   border-t  text-xl rounded-t-lg font-bold p-2 flex  items-center">
                <div>
                  <div className="flex items-center space-x-2">
                    <p>Revenue Contribution (in {usersCurrency})</p>
                    <p className="text-xs ">
                      <Tooltip
                        className="text-3xl cursor-pointer"
                        title={
                          <Typography fontSize={15}>
                            Number assets and vendors
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpOutline />
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className="  h-full text-3xl  p-4">
                {formatNumber(data?.business_impact_analysis?.revenue_contribution)}
              </div>
            </div>
            <div
              style={{ borderBottom: '4px solid #273c63' }}
              className="rounded-lg flex flex-col  w-full justify-evenly shadow"
            >
              <div className=" h-fit   border-t  text-xl rounded-t-lg font-bold p-2 flex  items-center">
                <div>
                  <div className="flex items-center space-x-2">
                    <p>Key Business Impact</p>
                    <p className="text-xs ">
                      <Tooltip
                        className="text-3xl cursor-pointer"
                        title={
                          <Typography fontSize={15}>
                            Number assets and vendors
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpOutline />
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-full text-3xl p-4">
                {data?.business_impact_analysis?.key_business_impact}
              </div>
            </div>
            <div
              style={{ borderBottom: '4px solid #273c63' }}
              className="rounded-lg flex flex-col w-full justify-evenly shadow"
            >
              <div className=" h-fit   border-t  text-xl rounded-t-lg font-bold p-2 flex  items-center">
                <div>
                  <div className="flex items-center space-x-2">
                    <p>Estimated Business Losses</p>
                    <p className="text-xs ">
                      <Tooltip
                        className="text-3xl cursor-pointer"
                        title={
                          <Typography fontSize={15}>
                            Number assets and vendors
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpOutline />
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className="  h-full text-3xl  p-4">
                {data?.business_impact_analysis?.estimated_business_losses}
              </div>
            </div>
            <div
              style={{ borderBottom: '4px solid #ce9f2c' }}
              className="rounded-lg flex flex-col  w-full justify-evenly shadow"
            >
              <div className=" h-fit   border-t   text-xl rounded-t-lg font-bold p-2 flex  items-center">
                <div>
                  <div className="flex items-center space-x-2">
                    <p>Criticality</p>
                    <p className="text-xs ">
                      <Tooltip
                        className="text-3xl cursor-pointer"
                        title={
                          <Typography fontSize={15}>
                            Number assets and vendors
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpOutline />
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className=" h-full text-3xl  p-4">
                {data?.business_impact_analysis?.criticality}
              </div>
            </div>
            <div
              style={{ borderBottom: '4px solid #ce9f2c' }}
              className="rounded-lg h-full flex flex-col  w-full justify-evenly shadow"
            >
              <div className=" h-fit   border-t  text-xl rounded-t-lg font-bold p-2 flex  items-center">
                <div>
                  <div className="flex items-center space-x-2">
                    <p>Regulatory Requirements</p>
                    <p className="text-xs ">
                      <Tooltip
                        className="text-3xl cursor-pointer"
                        title={
                          <Typography fontSize={15}>
                            Number assets and vendors
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpOutline />
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className=" flex justify-start space-x-1 items-center h-full text-3xl  p-4">
                <p>
                  {data?.business_impact_analysis?.regulatory_requirements
                    ? data?.business_impact_analysis?.regulatory_requirements?.join(', ')
                    : 'There is no data at the moment'}
                </p>
                {/* {data?.regulations.length > 3 ? (
                  <button onClick={toggleShowAll} className="see_more mt-2">
                    {showAll ? '...See Less' : '...See More'}
                  </button>
                ) : null} */}
              </div>
            </div>

            <div
              style={{ borderBottom: '4px solid #ce9f2c' }}
              className="rounded-lg flex flex-col  w-full justify-evenly shadow"
            >
              <div className=" h-fit   border-t   text-xl rounded-t-lg font-bold p-2 flex  items-center">
                <div>
                  <div className="flex items-center space-x-2 ">
                    <p>Overall Risk Rating</p>
                    <p className="text-xs ">
                      <Tooltip
                        className="text-3xl cursor-pointer"
                        title={
                          <Typography fontSize={15}>
                            Number assets and vendors
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpOutline />
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className=" h-full text-3xl  p-4">
                {data?.business_impact_analysis?.overall_risk_rating}
              </div>
            </div>
            <div className="flex flex-col border rounded-lg border-gray-600 w-full h-full shadow-2xl">
              <div className="pl-4 py-2 flex space-x-1 ">
                <p className="font-bold ">Business Assets</p>
                <p className="text-xs">
                  <Tooltip
                    className="text-3xl cursor-pointer"
                    title={
                      <Typography fontSize={15}>
                        Number assets and vendors
                      </Typography>
                    }
                    placement="top"
                  >
                    <HelpOutline />
                  </Tooltip>
                </p>
              </div>
              <div className="border-b flex justify-around flex-1 ">
                <div className="assets w-full flex flex-col justify-center items-center">
                  <p>Assets</p>
                  <p className="text-6xl ">{12}</p>
                </div>
                <div className="users  w-full flex flex-col justify-center items-center">
                  <p>Users</p>
                  <p className="text-6xl ">{30}</p>
                </div>
                <div className="vendor w-full flex flex-col justify-center items-center">
                  <p>Vendors</p>
                  <p className="text-6xl ">{43}</p>
                </div>
              </div>
              <div className="detail text-xs flex items-center justify-around mb-3 mt-1">
                <p className="cursor-pointer" 
                // onClick={() => toggleAsset()}
                >
                  More Details
                </p>
                <p
                  className="cursor-pointer"
                  //  onClick={() => toggleAsset()}
                >
                  More Details
                </p>
                <p
                  className="cursor-pointer"
                  // onClick={() => toggleAddCompany()}
                >
                  More Details
                </p>
              </div>{' '}
            </div>

            <div className="flex flex-col border rounded-lg  border-gray-600 w-full h-full shadow-2xl">
              <div className="pl-4 py-2 flex space-x-1 ">
                <p className=" font-bold">Asset Distribution by Risk</p>
                <p className="text-xs">
                  <Tooltip
                    className="text-3xl cursor-pointer"
                    title={
                      <Typography fontSize={15}>
                        Number assets and vendors
                      </Typography>
                    }
                    placement="top"
                  >
                    <HelpOutline />
                  </Tooltip>
                </p>
              </div>
              <div className="border-b flex justify-around  flex-1 ">
                <div className="low w-full flex flex-col justify-center items-center">
                  <p>High</p>
                  <p className="text-6xl">{3}</p>{' '}
                </div>
                <div className="medium w-full flex flex-col justify-center items-center">
                  <p>Medium</p>
                  <p className="text-6xl">{6}</p>{' '}
                </div>
                <div className="highest w-full flex flex-col justify-center items-center">
                  <p>Low</p>
                  <p className="text-6xl">{6}</p>{' '}
                </div>
                {/* <div className="medium w-full flex flex-col justify-center items-center">
                  <p>Medium</p>
                  <p className="text-6xl ">{medium}</p>
                </div>
                <div className="flex flex-col  highest justify-center items-center">
                  <div className=" ">Low</div>
                  <div className="text-6xl ">{low ? low?.total_Low : 0}</div>
                </div> */}
              </div>
              <div className="detail  text-xs flex items-center justify-center mb-3 mt-1">
                <p
                  className="cursor-pointer"
                  onClick={() => toggleRiskDistribution()}
                >
                  More Details
                </p>
              </div>{' '}
            </div>
            <div className="flex flex-col border rounded-lg  border-gray-600 w-full h-full shadow-2xl">
              <div className="pl-4 py-2 flex space-x-1 ">
                <span className="flex flex-col">
                  <span className="font-bold flex">
                    <p>Latest Cyber Security News</p>
                  </span>
                </span>
              </div>
              <div className="p-1 flex flex-col space-y-2">
                {newsData.slice(0, 2).map((article, index) => (
                  <div key={index}>
                    <div className="">
                      <p className="text-2xl">{article.title}</p>
                      <p className="text-sm">
                        {article.description.split(' ').slice(0, 10).join(' ')}
                        ...
                      </p>{' '}
                    </div>
                  </div>
                ))}
              </div>
              <div className="detail border-t text-xs flex items-center justify-center mb-3 mt-1">
                <Link
                  className="cursor-pointer"
                  to="/latest-cyber-security-news"
                >
                  Read more...
                </Link>
              </div>{' '}
            </div>
          </div>
          {foo ? (
            <div className=" flex justify-center items-center text-red">
              This is Pop up
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          {' '}
          {loading ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : (
            <NoDataToShow />
          )}
        </div>
      )}
      <Modal onClose={() => toggleAsset()} size="sm" isOpen={showAsset}>
        <Modal.Header>
          <Modal.Title>Asset Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssetDetails name={dat.name} showHide={toggleAsset} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-cancel" onClick={() => toggleAsset()}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal onClose={() => toggleAddCompany()} size="sm" isOpen={addCompany}>
        <Modal.Header className="flex space-x-2 items-center">
          <Modal.Title>Vendor Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <VendorDetailsBrief name={dat.name} showHide={toggleAddCompany} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-cancel" onClick={() => toggleAddCompany()}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={riskDistribution} className="mt-1">
        <Modal.Header closeButton onClick={() => toggleRiskDistribution()}>
          <Modal.Title>Asset Distribution by Risk Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>More details here.</p>
        </Modal.Body>
      </Modal>

      <Modal show={businessRisks} className="mt-1">
        <Modal.Header closeButton onClick={() => toggleBusinessRisks()}>
          <Modal.Title>Business Risks Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>More details here.</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BusinessImpactAnalysis;
