import { createSlice } from "@reduxjs/toolkit";

const multiApplicableControlsSlice = createSlice({
	name: "allowMultiApplicableSelect",
	initialState: false,
	reducers: {
		setAllowMultiApplicableSelect: (state, action) => action.payload,
	},
});

export const { setAllowMultiApplicableSelect } =
	multiApplicableControlsSlice.actions;

export default multiApplicableControlsSlice.reducer;
