export const BUSINESS_IMPACT_BY_ID = "/business_process/business-impact/:id";
export const BUSINESS_ASSETS_DETAIL =
	"/business_process/business-impact/business-assets-detail";
export const BUSINESS_ASSET_RISK_DETAILS =
	"/business_process/business-impact/business_asset_risk_details";
export const VENDOR_PRODUCT_DETAIL =
	"/business_process/business-impact/vendor-product-detail";
export const CEV_SUMMARY = "/business_process/business-impact/cev-summery/:key";
export const RISK_TC_MAPPING_DETAILS =
	"/business_process/risk-tc-mapping-details";
export const BUSINESS_ATC_MAPPING_DETAILS =
	"/business_process/bussiness_atc_mapping_details";
export const DELETE_TC_MAPPING = "/business_process/delete_tc_mapping";
export const GET_BUSINESS_PROCESS_BY_ID =
	"/business_process/get-business-process-by-id/";
export const GET_MY_BUSINESS_PROCESS =
	"/business_process/get-my-business-process";
export const BUSINESS_PROCESS = "/business_process/business-process";
export const BUSINESS_PROCESS_BY_PK = "/business_process/business-process/";
export const GET_BUSINESS_IMPACT_BY_CLIENT =
	// /:clientId/:bProcessId
	"/business_process/get-business-impact-by-client/";
export const GET_BUSINESS_IMPACT_BY_SUPERUSER =
	"/business_process/get-business-impact-by-superuser/";
export const DELETE_BUSINESS_IMPACT_BY_SUPERUSER =
	"/business_process/delete-business-impact-by-superuser/:clientId/:bProcessId";
export const GET_BUSINESS_IMPACT_BY_BP_ID =
	"/business_process/get-business-impact/";
export const GET_VENDOR_PRODUCT = "/business_process/get-vendor-product";
export const DELETE_ATC_MAPPING = "/business_process/delete_atc_mapping";
export const OKTA_SCORE_RECORD = "/business_process/okta-score-record";
export const OKTA_DETAIL = "/business_process/okta-detail";
export const EXPLOITABLE_PATHS = "/business_process/exploitable-paths";
export const FORMATTED_EXPLOITABLE_PATHS =
	"/business_process/exploitable-paths-formatted";
export const EXPLOITABLE_PATH = "/business_process/exploitable-path";
export const CONTROL_ANALYSIS = "/business_process/control-analysis";
export const UPDATE_CONTROL_ANALYSIS =
	"/business_process/update-control-analysis";
export const CONTROL_TYPE = "/business_process/control-type";
export const CONTROL_TYPE_BY_PK = "/business_process/control-type/:pk";
export const INVESTMENT_REQUIREMENT =
	"/business_process/investment-requirement";
export const INVESTMENT_REQUIREMENT_BY_PK =
	"/business_process/investment-requirement/:pk";
export const SECURITY_SCORE_CARD_RESULTS =
	"/business_process/security-score-card-results/:domain";
export const SECURITY_SCORE_CARD_DETAIL_REPORT =
	"/business_process/security-score-card-detail-report/:identifier";
export const SECURITY_SCORE_CARD_PLAN_BY_TARGET =
	"/business_process/security-score-card-plan-by-target/:domain/:score";
export const THREAT_MODELING = "/business_process/threat-modeling";
export const THREAT_MODELING_MATRIX_CONFIG =
	"/business_process/threat-modeling-matrix-config";
export const THREAT_MODELING_MATRIX_CONFIG_BY_PK =
	"/business_process/threat-modeling-matrix-config/:pk";
