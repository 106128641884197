import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Nav } from "react-bootstrap";

const Header = (props) => {
	const auth_links = props.user_status.loggedIn;

	const protected_links = props.user_status.approved ? (
		<Nav className="me-auto">
			<Nav.Link as={Link} to="/client_profile">
				Client profile
			</Nav.Link>
			<Nav.Link as={Link} to="/threat_profile">
				profile Threat
			</Nav.Link>
			<Nav.Link as={Link} to="/admin/threat_profile">
				Admin
			</Nav.Link>
		</Nav>
	) : (
		<Nav className="me-auto"></Nav>
	);

	return (
		<div>
			{protected_links}
			{auth_links}
		</div>
	);
};

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(Header);
