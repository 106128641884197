import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from './../authentication/store/actions/themeActions';

export const useHandleTheme = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.theme).darkMode;

  const handleDarkTheme = async () => {
    dispatch(toggleTheme(true)); // Toggle Dark Theme
  };
  const handleLightTheme = async () => {
    dispatch(toggleTheme(false)); // Toggle Light Theme
  };

  return { handleDarkTheme, handleLightTheme, darkMode };
};
