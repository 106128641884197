import { gql } from '@apollo/client';

export const UPDATE_USERS = gql`
  mutation UPDATE_USER($id: Int!, $input: userInputType!) {
    update_user(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_USER = gql`
  mutation ADD_USER($email: String!, $username: String!, $password: String!) {
    create_user(email: $email, username: $username, password: $password) {
      user {
        id
      }
    }
  }
`;
export const DELETE_USER_ACCOUNT = gql`
  mutation DELETE_USER($id: Int!) {
    delete_user(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const ADD_CLIENT = gql`
  mutation ADD_CLIENT($inputs: [clientInputType]!) {
    create_client(inputs: $inputs) {
      data {
        name
      }
    }
  }
`;
export const DELETE_CLIENT = gql`
  mutation DELETE_CLIENT($id: Int!) {
    delete_client(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_CLIENT = gql`
  mutation UPDATE_CLIENT($id: Int!, $input: clientInputType!) {
    update_client(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
