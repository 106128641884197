import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import http from "../../../resources/http";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import ModalHandler from "./ModalHandler";
import { INDUSTRY_SECTOR } from "../../../routes/accounts";
import swal from "sweetalert";
import { GET_INDUSTRY_SECTOR } from "../../../graphql/superAdmin/query/query";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_INDUSTRY, DELETE_INDUSTRY, UPDATE_INDUSTRY } from "../../../graphql/superAdmin/mutation/mutation";
import toast from "react-hot-toast";

const IndustrySector = () => {
	const url = INDUSTRY_SECTOR;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const domains = useSelector((state) => state.controls.domains);
	const dispatch = useDispatch();

	const [id, setId] = useState();
	const [datas, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["name", "description"];

	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url);
			setData(response.data);
			setFetchLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setFetchLoading(false);
		}
	}
	const [page, setPage] = useState(0);
	const pageSize = 10; // Number of items per page
  
	const { data, loading, error, refetch } = useQuery(GET_INDUSTRY_SECTOR, {
	  variables: { limit: pageSize, offset: page * pageSize },
	});
	const handlePageChange = ({ selected }) => {
	  setPage(selected);
	  refetch({ limit: pageSize, offset: selected * pageSize });
	};
	const totalItems = data?.industry_sector_aggregate?.count || 0;
	// useEffect(() => {
	// 	fetchData();
	// }, []);
	const [update] = useMutation(UPDATE_INDUSTRY);
	const [create] = useMutation(ADD_INDUSTRY);
	const [del] = useMutation(DELETE_INDUSTRY);
	const filteredData = SearchHandler(data?.industry_sector??[], searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
		  const { data } = await del({
			variables: { id: parseInt(id) },
		  });
	
		  if (data?.delete_industry_sector?.affected_rows > 0) {
			setFetchLoading(false);
			refetch();
			toast.success('It has been deleted successfully');
		  } else {
		  }
		} catch (error) {
		  setFetchLoading(false);
		  console.error('Failed to delete item:', error);
		}
	  };

	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span className=" ">Industry</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							className="btn-add-new"
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
						>
							<AddIcon />
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
						page={page}
						pageSize={pageSize}
						totalItems={totalItems}
						onPageChange={handlePageChange}
						passedExcludeColumns={['__typename']}
					/>
				)}
				{/* add and edit modal */}
				<ModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="xl"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="Industry"
					url={url}
					fetchData={refetch}
          			createMutation={create}
          			updateMutation={update}
				/>
			</div>
		</main>
	);
};

export default IndustrySector;
