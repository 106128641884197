import React from "react";

const FileUploadLoading = () => {
  return (
    <div className="flex justify-center items-center">
      <div>
        <div class=" lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p>Uploading....</p>
      </div>
    </div>
  );
};

export default FileUploadLoading;
