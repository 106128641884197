import React from "react";

const SearchInput = ({ searchValue, setSearchValue }) => {
	return (
		<div className="flex justify-center items-center rounded-md">
			<input
				type="text"
				id="searchBox"
				value={searchValue}
				onChange={(e) => setSearchValue(e.target.value)}
				className=" border bg-stone-400 text-gray-900 text-md block w-full p-2.5 
				border-stone-300 placeholder-stone-500 rounded-lg
				 focus:ring-#ce9f2c focus:border-[#ce9f2c]
				 dark:bg-gray-700
				 dark:border-slate-300
				 dark:placeholder-gray-400 
				 dark:text-white 
				 dark:focus:ring-#ce9f2c 
				 dark:focus:border-slate-300"
				placeholder="Search..."
				required
			/>

			{searchValue?.length > 0 && (
				<div
					className="cursor-pointer cmbutton"
					onClick={() => setSearchValue("")}
				>
					<span className=" rounded-lg p-1">Clear</span>
				</div>
			)}
		</div>
	);
};

export default SearchInput;
