import React, { useState } from "react";
import HarveyBallChart from "../Charts/ControlAnalysis/HarveyBall";
import { useHandleTheme } from "./../hooks/useHandleTheme";
import { useApi } from "../hooks/useApis";
import { baseURL } from "../resources/apiClient";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import ReactPaginate from "react-paginate";

const ThreatRisudualRisk = ({ data, filterByThreats }) => {
	const { darkMode } = useHandleTheme();
	const datas = [{id:1, threat:"Test", severity:"low"},{id:2, threat:"Test Two", severity:"high"}]
	// control-analysis-percentage-reduction
	const dataPerPage = 5;
	// page pagination
	const [pageNumber, setPageNumber] = useState(0);
	// const dataPerPage = 7;
	const pagesVisited = pageNumber * dataPerPage;
	const pageCount = Math.ceil(data?.length / dataPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const filteredData = data?.filter((row) => {
		const threatRegex = new RegExp(`\\b${filterByThreats}\\b`);
		return row?.threat?.match(threatRegex);
	});
	const DisplayData = datas
		?.slice(pagesVisited, pagesVisited + dataPerPage)
		?.map((vectors) => {
			return (
				<>
					<tr key={vectors.id}>
						<td className="">{vectors.threat}</td>
						<td className="">
							<p
								className="p-3 text-center rounded-xl"
								style={{
									backgroundColor: getBackgroundColor(vectors.severity),
									color: "black",
								}}
							></p>
						</td>
						<td className="">
							<HarveyBallChart percentage={80} />{" "}
						</td>
						{/* <td className="">
            <HarveyBallChart
              percentage={vectors.deterent_reduction_percentage}
            />{' '}
          </td> */}
						<td className="">
							<HarveyBallChart percentage={60} />{" "}
						</td>
						<td className="">
							<HarveyBallChart percentage={98} />{" "}
						</td>
					</tr>
				</>
			);
		});
	return (
		<div className="h-full w-full pt-4">
			{/* <div
        className={`${
          darkMode ? 'bg-gray-800' : 'bg'
        }  h-auto  text-xl rounded-t-lg font-bold p-2 flex  items-center`}
      >
        <div class="flex flex-col ">
          <div class="flex items-center space-x-2">
            <p>Risk Reduction</p>{' '}
            <p class="text-xs">
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium text-3xl cursor-pointer css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="HelpOutlineIcon"
              >
                <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
              </svg>
            </p>
          </div>
        </div>
      </div> */}

			<table className="tables">
				<thead className="sticky-header">
					<tr>
						<th className="text-center" scope="col">
							Threat
						</th>
						<th className="text-center" scope="col">
							Residual Risk
						</th>
						<th className="text-center" scope="col">
							Preventative
						</th>
						<th className="text-center" scope="col">
							Responsive
						</th>
						<th className="text-center" scope="col">
							Detective
						</th>
						{/* <th className="text-center" scope="col">
              Deterrent
            </th> */}
					</tr>
				</thead>
				<tbody className="">{DisplayData}</tbody>
			</table>
			<div className="paginationBttns">
				{filteredData?.length > 5 ? (
					<ReactPaginate
						previousLabel={"<"}
						nextLabel={">"}
						activeClassName={"paginationActive"}
						pageCount={pageCount}
						onPageChange={changePage}
						containerClassName={"paginationBttns"}
						previousLinkClassName={"previousBttn"}
						nextLinkClassName={"nextBttn"}
						disabledClassName={"paginationDisabled"}
					/>
				) : null}{" "}
			</div>
		</div>
	);
};

export default ThreatRisudualRisk;
