import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import http from "../../resources/http";
import { baseURL } from "../../resources/apiClient";
import { useApi } from "../../hooks/useApis";
import ActionButton from "../../shared/ActionButton";
import { getBackgroundColor } from "../../utils/getBackgroundColor";
import ScoreBadge from "./ScoreBadge";
// import controlsProfileCard from "./controlsProfileCard";
import { setAllowMultiApplicableSelect } from "../../authentication/store/reducers/multiApplicableControlsSlice";

const ControlImplementation = () => {
	const processId = useSelector((state) => state.pid);
	const [primaryThreats, setPrimaryThreats] = useState("");

	const { data, isLoading, fetchData, isError } = useApi(
		`${baseURL}/threat-catalog/control-analysis?businessProcessId=${processId}`
	);
	console.log(data, "this is the data we going to work on");

	// Helper function to display "N/A" if the value is null
	const displayValue = (value) => (value === null ? "N/A" : value.toString());

	// handles multiple selection
	const dispatch = useDispatch();

	const [selectMultiple, setSelectMultiple] = useState(false);
	const [showSelectedLabel, setShowSelectedLabel] = useState(false);
	const allowMultiApplicableControls = useSelector(
		(state) => state.controlsProfile.multiApplicable
	);

	const [isApplicableSelectMultipleOn, setIsApplicableSelectMultipleOn] =
		useState(true);
	const [
		numberOfSelectedApplicableControls,
		setNumberOfSelectedApplicableControls,
	] = useState(0);
	const handleUpdate = (id, newValues) => {};

	const handleUpdateMultiple = (id, newValues) => {};

	const controlsProfileCard = (items, label) =>
		items.map((item) => (
			<div
				key={item.id}
				className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center"
			>
				<div className="flex items-center space-x-3 mb-4">
					<div>
						<p
							className="p-2 text-start rounded-2xl"
							style={{
								backgroundColor: getBackgroundColor(
									item?.threat_risk_profile?.severity
								),
								color: "gray",
							}}
						>
							{/* {item?.threat_risk_profile?.value?.toFixed(2)} */}
							<span className="font-bold text-xl text-blue-400 ui-monospace ">
								Threat:
							</span>
							<span className="font-bold text-xl text-blue-500 underline decoration-dotted ui-monospace">
								{displayValue(
									" " + item.client_control_catalog?.primary_threat
								)}
							</span>
						</p>
						<p className="text-gray-700 text-lg ui-monospace">
							Name:{" "}
							<span className="font-semibold">
								{displayValue(item.client_control_catalog?.control_name)}
							</span>
						</p>
						<p className="text-gray-700 text-lg ui-monospace">
							Relevance:{" "}
							<span className="font-semibold">
								{item.client_control_catalog.relevance?.toFixed(1)}
							</span>
						</p>{" "}
						<p className="text-gray-700 text-lg ui-monospace">
							Effectiveness :
							<span className="font-semibold">
								{item.threat_risk_profile.reduction_percentage} {"%"}
							</span>
						</p>
						{/* <p className="text-gray-700 text-base">
							Threat:
							<span className="font-semibold">
								{displayValue(item.client_control_catalog?.primary_threat)}
							</span>
						</p> */}
					</div>
				</div>
				<div className="grid md:grid-cols-1 gap-1">
					<button
						className="btn-add-new bg-gray-200"
						onClick={() => {
							// isSelectMultipleOn
							// 	? setShowSelectedLabel(true) &&
							// 	  setNumberOfSelectedApplicableControls(3)
							// 	: setShowSelectedLabel(false);
							handleUpdate(item.id);
						}}
					>
						{/* {showSelectedLabel ? "Selected" : label} */}
						{label}
					</button>
				</div>{" "}
			</div>
		));

	// const applicableLabel = selectMultiple ? "Selected" : "Select"

	// Conditional rendering based on loading and error states
	if (isLoading) return <div>Loading...</div>;
	if (isError) return <div>Error loading data!</div>;
	if (!data || !data.control_analysis_data)
		return <div>No data available!</div>;

	// Filtering groups based on 'is_applicable' and 'is_implemented' values
	const ImplementableDataList = data.control_analysis_data.filter(
		(item) => !item.is_applicable && !item.is_implemented
	);
	const applicableDataList = data.control_analysis_data.filter(
		(item) => item.is_applicable && !item.is_implemented
	);
	const implementedDataList = data.control_analysis_data.filter(
		(item) => item.is_applicable && item.is_implemented
	);

	return (
		<div class="container mx-auto px-2 py-8">
			<div className="grid sm:grid-cols-1 md:grid-cols-1  gap-4 ">
				{/* controls sections */}
				<div className="md:col-start-1 col-span-2 space-y-4">
					{/* Implementable Control profiles */}
					<div class="bg-gray-100 p-4 rounded-2xl shadow-inner-xl">
						<div className="grid sm:grid-cols-1 md:grid-cols-2">
							<h3 class="font-bold text-lg mb-4">
								Implementable Control profiles
							</h3>
							<div className="flex justify-end">
								<button
									className="text-white text-end bg-gray-500 hover:bg-blue-900 rounded-xl  py-1.5 px-2 font-semibold text-base"
									onClick={() => {
										allowMultiApplicableControls
											? dispatch(setAllowMultiApplicableSelect(false))
											: dispatch(setAllowMultiApplicableSelect(true));
									}}
								>
									{allowMultiApplicableControls ? "Cancel" : "Select"}
								</button>
							</div>
						</div>

						<div class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 max-h-96 overflow-auto">
							{controlsProfileCard(ImplementableDataList, "Applicable")}
						</div>
						<div className="grid sm:grid-cols-1 md:grid-cols-1">
							{allowMultiApplicableControls ? (
								<div className="grid sm:grid-cols-1 md:grid-cols-1">
									{numberOfSelectedApplicableControls.length > 0 ? (
										<div className="flex items-center justify-around">
											<spam className="text-center text-base font-semibold mx-6">
												{numberOfSelectedApplicableControls} Applicable Controls
												Selected
											</spam>
											<button
												className="text-white text-end bg-gray-500 hover:bg-blue-900 rounded-xl  py-1.5 px-2 font-semibold text-base"
												onClick={() => handleUpdateMultiple}
											>
												Apply
											</button>
										</div>
									) : (
										<spam className="text-center text-base font-semibold">
											Select Applicable Controls
										</spam>
									)}
								</div>
							) : null}
						</div>
					</div>
					<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
						{/* applicable */}
						<div class="bg-gray-100 p-4 rounded-xl shadow md:col-span-1 ">
							<h3 class="font-bold text-lg mb-4">Applicable Controls</h3>
							<div class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 max-h-96 overflow-auto">
								{controlsProfileCard(applicableDataList, "Implement")}
							</div>
						</div>
						{/* implemented */}
						<div class="bg-gray-100 p-4 rounded-xl shadow w-full md:col-span-1  ">
							<h3 class="font-bold text-lg mb-4">Implemented Controls</h3>
							<div class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 max-h-96 overflow-auto">
								{controlsProfileCard(implementedDataList, "Revers")}
							</div>
						</div>
					</div>
				</div>{" "}
			</div>
		</div>
	);
};

export default ControlImplementation;
