import React, { useState, useEffect, useMemo } from "react";
import SvgComponent from "./SvgComponent";

function MultiSelect({ options, onChange, value = "" }) {
	// console.log("selectedValues:", value);
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const toggleDropdown = () => setIsOpen(!isOpen);

	const filteredOptions = useMemo(() => {
		// changing options to a Set to remove duplicates, then back to an array
		const uniqueOptions = Array.from(new Set(options));

		if (!searchTerm) return uniqueOptions;
		return uniqueOptions.filter((option) =>
			option.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [options, searchTerm]);

	const toggleOption = (option) => {
		const newValue = value?.includes(option)
			? value?.filter((v) => v !== option)
			: [...value, option];
		onChange(newValue);
	};

	const areAllSelected = () => options?.length === value?.length;

	const selectAll = () => {
		areAllSelected() ? onChange([]) : onChange(options);
	};

	return (
		<div className="dropdown-container">
			<div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
				<div className="dropdown-header-value">
					<span>
						{value?.length == 0
							? "Select..."
							: areAllSelected()
							? "All options are selected."
							: value && value?.length > 0
							? value.join(", ").slice(0, 70)
							: ""}
					</span>
				</div>
				{value?.length > 0 && (
					<button
						type="button"
						onClick={() => onChange([])}
						className="dropdown-header-selected-clear-btn"
					>
						<SvgComponent
							width="24"
							height="24"
							fillColor="none"
							strokeColor="currentColor"
							strokeWidth="2"
							path="M18 6L6 18M6 6l12 12"
							onClick={toggleDropdown}
						/>
					</button>
				)}
				<button
					type="button"
					onClick={toggleDropdown}
					className="dropdown-header-down-icon"
				>
					<SvgComponent
						width="24"
						height="24"
						fillColor="none"
						strokeColor="currentColor"
						strokeWidth="2"
						path={isOpen ? "M18 15 12 9 6 15" : "M6 9L12 15 18 9"}
						onClick={toggleDropdown}
					/>
				</button>
			</div>
			{isOpen && (
				<div
					className="dropdown-menu"
					onMouseLeave={() => {
						toggleDropdown();
					}}
				>
					<div className="select-content">
						<div className="dropdown-search ">
							<input
								type="text"
								placeholder="Search..."
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
							{searchTerm && (
								<button
									type="button"
									onClick={() => setSearchTerm("")}
									className=" dropdown-search-clear-btn"
								>
									<SvgComponent
										width="24"
										height="24"
										fillColor="none"
										strokeColor="currentColor"
										strokeWidth="2"
										path="M18 6L6 18M6 6l12 12"
										onClick={toggleDropdown}
									/>
								</button>
							)}
						</div>

						<ul className="options">
							<li>
								<label className="multi-select-item" onClick={selectAll}>
									<div className="options-list-item ">
										<input
											type="checkbox"
											checked={areAllSelected()}
											onChange={selectAll}
										/>
										<span>
											{areAllSelected() ? "Unselect All" : "Select All"}
										</span>
									</div>
								</label>
							</li>
							{filteredOptions?.map((option) => (
								<li key={option} value={option}>
									<label htmlFor={option} className="multi-select-item">
										<div className="options-list-item ">
											<input
												type="checkbox"
												checked={value?.includes(option)}
												className="form-checkbox "
												onChange={() => toggleOption(option)}
											/>
											<span key={option}>{option}</span>
										</div>
									</label>
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
}

export default MultiSelect;
