import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../shared/CustomTable';
import SearchHandler from '../../../shared/SearchHandler';
import http from '../../../resources/http';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import ControlsModalHandler from './ControlModalsUntilBEupdate/ControlsModalHandler';
import { CONTROL_DOMAIN } from '../../../api/configurations';
import {
  setDomains,
  addDomain,
  updateDomain,
  deleteDomain,
} from '../../../authentication/store/reducers/domainsSlice';
import swal from 'sweetalert';
import toast from 'react-hot-toast';

import { GET_CONTROL_DOMAIN } from '../../../graphql/superAdmin/query/controlConfiguration/query';
import {
  UPDATE_CONTROL_DOMAIN,
  DELETE_CONTROL_DOMAIN,
  ADD_CONTROL_DOMAIN,
} from '../../../graphql/superAdmin/mutation/controlConfiguration/mutation';
import { useMutation, useQuery } from '@apollo/client';
const ControlDomain = () => {
  const url = CONTROL_DOMAIN;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);

  const domains = useSelector((state) => state.controls.domains);
  const dispatch = useDispatch();

  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = ['name', 'description'];

  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url);
      const domainsList = response.data.map((item) => item.name);
      console.log(domainsList);
      dispatch(setDomains(domainsList));
      setData(response.data);
      setFetchLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchLoading(false);
    }
  }

  // useEffect(() => {
  // 	fetchData();
  // }, []);
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(GET_CONTROL_DOMAIN, {
    variables: { limit: pageSize, offset: page * pageSize },
  });
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.control_domain_aggregate?.count || 0;

  console.log(data);
  const [update] = useMutation(UPDATE_CONTROL_DOMAIN);
  const [create] = useMutation(ADD_CONTROL_DOMAIN);
  const [del] = useMutation(DELETE_CONTROL_DOMAIN);
  const filteredData = SearchHandler(
    data?.control_domain ?? [],
    searchValue,
    fieldsToFilterBy
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });

      if (data?.delete_control_domain?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };

  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span>Control Domain</span>
          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            <button
              className="btn-add-new"
              onClick={() => {
                setSelectedItem(null);
                setMode('add');
                setIsModalOpen(true);
              }}
            >
              <AddIcon />
            </button>
          </div>
        </div>
        {/* table */}
        {loading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && filteredData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <CustomTable
            filteredData={filteredData}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
			page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            passedExcludeColumns={['__typename']}
          />
        )}
        {/* add and edit modal */}
        <ControlsModalHandler
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode={mode}
          size="xl"
          selectedItemId={id}
          selectedItem={selectedItem}
          header="Control Domain"
          url={url}
          fetchData={refetch}
          createMutation={create}
          updateMutation={update}
        />
      </div>
    </main>
  );
};

export default ControlDomain;
