import React from "react";

//what props do we need?
//width, description, setText, modalFunc,

function MoreButton({ description, stringLength, setText, modalFunc }) {
	if (description.length < 10) {
		return <p>{description}</p>;
	}
	return (
		<div className={"grid  gap-1"}>
			<p className="m-0 "> {description.substring(0, stringLength)}</p>

			<p className="m-0 flex items-center">
				<a
					onClick={() => {
						setText(description);
						modalFunc();
					}}
					className="cursor-pointer text-black-500"
				>
					...see more
				</a>
			</p>
		</div>
	);
}

export default MoreButton;
