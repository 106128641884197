import { gql } from '@apollo/client';

export const DELETE_AVAILABILITY = gql`
  mutation DELETE_AVAILABILITY($id: Int!) {
    delete_availability_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_AVAILABILITY = gql`
  mutation UPDATE_AVAILABILITY(
    $id: Int!
    $input: availability_levelInputType!
  ) {
    update_availability_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_AVAILABILITY = gql`
  mutation ADD_AVAILABILITY($inputs: [availability_levelInputType]!) {
    create_availability_level(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONFIDENTIALITY = gql`
  mutation DELETE_CONFIDENTIALITY($id: Int!) {
    delete_confidentiality_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONFIDENTIALITY = gql`
  mutation UPDATE_CONFIDENTIALITY(
    $id: Int!
    $input: confidentiality_levelInputType!
  ) {
    update_confidentiality_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONFIDENTIALITY = gql`
  mutation ADD_CONFIDENTIALITY($inputs: [confidentiality_levelInputType]!) {
    create_confidentiality_level(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_DISCOVERY = gql`
  mutation DELETE_DISCOVERY($id: Int!) {
    delete_discovery_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_DISCOVERY = gql`
  mutation UPDATE_DISCOVERY($id: Int!, $input: discovery_levelInputType!) {
    update_discovery_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_DISCOVERY = gql`
  mutation ADD_DISCOVERY($inputs: [discovery_levelInputType]!) {
    create_discovery_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_EXPLOITABILITY = gql`
  mutation DELETE_EXPLOITABILITY($id: Int!) {
    delete_exploitable_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_EXPLOITABILITY = gql`
  mutation UPDATE_EXPLOITABILITY(
    $id: Int!
    $input: exploitable_levelInputType!
  ) {
    update_exploitable_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_EXPLOITABILITY = gql`
  mutation ADD_EXPLOITABILITY($inputs: [exploitable_levelInputType]!) {
    create_exploitable_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_INTEGRITY = gql`
  mutation DELETE_INTEGRITY($id: Int!) {
    delete_integrity_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_INTEGRITY = gql`
  mutation UPDATE_INTEGRITY($id: Int!, $input: integrity_levelInputType!) {
    update_integrity_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_INTEGRITY = gql`
  mutation ADD_INTEGRITY($inputs: [integrity_levelInputType]!) {
    create_integrity_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_REPRODUCIBILITY = gql`
  mutation DELETE_REPRODICIBILITY($id: Int!) {
    delete_reproducibility_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_REPRODUCIBILITY = gql`
  mutation UPDATE_REPRODICIBILITY($id: Int!, $input: reproducibility_levelInputType!) {
    update_reproducibility_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_REPRODUCIBILITY = gql`
  mutation ADD_REPRODICIBILITY($inputs: [reproducibility_levelInputType]!) {
    create_reproducibility_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
//industry
export const DELETE_INDUSTRY = gql`
  mutation DELETE_INDUSTRY($id: Int!) {
    delete_industry_sector(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_INDUSTRY = gql`
  mutation UPDATE_INDUSTRY($id: Int!, $input: industry_sectorInputType!) {
    update_industry_sector(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_INDUSTRY = gql`
  mutation ADD_INDUSTRY($inputs: [industry_sectorInputType]!) {
    create_industry_sector(inputs: $inputs) {
      affected_rows
    }
  }
`;
//industry
export const DELETE_AT_MAPPING = gql`
  mutation DELETE_AT_MAPPING($id: Int!) {
    delete_asset_threat_mapping(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_AT_MAPPING = gql`
  mutation UPDATE_AT_MAPPING($id: Int!, $input: asset_threat_mappingInputType!) {
    update_asset_threat_mapping(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;

export const ADD_AT_MAPPING = gql`
  mutation ADD_AT_MAPPING($inputs: [asset_threat_mappingInputType]!) {
    create_asset_threat_mapping(inputs: $inputs) {
      affected_rows
    }
  }
`;
