
import React, { useCallback, useEffect, useState, useRef } from "react";

import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import { useApi } from "../../../hooks/useApis";
import useControlFilters from "../../../hooks/useControlsFilter";
import { useHandleTheme } from "../../../hooks/useHandleTheme";
import { baseURL } from "../../../resources/apiClient";
import http from "../../../resources/http";

import { usePropertyTransformation } from "../../../utils/usePropertyTransform";

import { CLIENT_CONTROLS_CATALOG_ENDPOINT } from "./../../../api/threatCataloModels";

import ThreatRisudualRisk from "../../ThreatRisudualRisk";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import DomainThreatChart from "./DomainThreatChart";


const ControlAnalysisSummary = () => {
	const { darkMode } = useHandleTheme();
	const ref = useRef(null);

	const [showFilters, setShowFilters] = useState(false);
	const toggleFilters = () => setShowFilters(!showFilters);

	const [showSorts, setShowSorts] = useState(false);
	// useCallback to memoize the toggleSorts function and prevent re-creation
	const toggleSorts = useCallback(() => {
		setShowSorts((prevShowSorts) => !prevShowSorts);
	}, []);

	const [primaryThreats, setPrimaryThreats] = useState("");
	const [filterValue, setFilterValue] = useState("");
	const [sensFilter, setRadioFilter] = useState("High");
	const [searchValue, setSearchValue] = useState("");
	const [updateLoading, setUpdateLoading] = useState(false);
	const queryClient = useQueryClient();

	const processId = useSelector((state) => state.pid);

	const { data, isLoading, fetchData, isError } = useApi(
		`${baseURL}/threat-catalog/control-analysis?businessProcessId=${processId}`
	);
	const [reports, setReports] = useState([]);

	const fetchDatas = async () => {
		try {
			const allBusinessProcessRes = await http.get(
				`${baseURL}/${CLIENT_CONTROLS_CATALOG_ENDPOINT}`
			);
			setReports(allBusinessProcessRes.data);
		} catch (error) {
			console.log(error);
		}
	};

	// useEffect(() => {
	// 	fetchDatas();
	// }, []);


	const handleOnChange = (value) => {
		setSearchValue(value);
	};
	const handleFilterOnChange = (value) => {
		setFilterValue(value);
	};

	const [pageNumber, setPageNumber] = useState(0);

	let numberFormat = new Intl.NumberFormat("en-US");

	const controlTypes = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.control_type
			)
		),
	];
	const controlDomains = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.control_domain
			)
		),
	];
	const controlCategory = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.control_category
			)
		),
	];
	const primaryThreat = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.primary_threat
			)
		),
	];
	// console.log(data, "data");
	const transformedData = usePropertyTransformation(
		data?.control_analysis_data
	);

	const {
		selectedControlDomain,
		selectedControlCategory,
		selectedControlTypes,
		selectedControlApplicability,
		handleControlDomainChange,
		handleControlCategoryChange,
		handleControlTypeChange,
		handleClearSelections,
		filteredControls,
	} = useControlFilters(
		transformedData,
		controlDomains,
		controlCategory,
		controlTypes
	);







	return (
		<div
			className="
		p-10 md:p-4"
		>
			{true ? (
				<div className="p-4 space-y-4 md:space-y-6">
					{true ? (
						<div className="h-full pt-2 pb-4 flex rounded-xl shadow-lg bg-white dark:bg-gray-900 ">
							<div className="divide-gray-600/25  grid place-items-center sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2  2xl:grid-cols-2  gap-4 rounded-xl shadow-l ">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										maxHeight: "100%",
										width: "100%",
										height: "500px",
										minHeight: "500px",
									}}
								>
									<span className="p-2 text-xl font-semibold border-b-2">
										Possible Threat vs Control Domain{" "}
									</span>
									<DomainThreatChart data={data?.threat_domain_count} />
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										maxHeight: "100%",
										width: "100%",
										height: "500px",
										minHeight: "500px",
									}}
								>
									<span className="p-2 text-xl font-semibold border-b-2">
										Risk Reduction
									</span>

									<ThreatRisudualRisk
										data={data?.control_analysis_percentage}
										filterByThreats={primaryThreats}
									/>
								</div>{" "}
							</div>
						</div>
					) : (
						<div className="alert alert-warning" role="alert">
							There is no control analysis data at the moment, please check
							again later once all the required files add by the admin.
						</div>
					)}
				</div>
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
};

export default ControlAnalysisSummary;
