import React, { useState, useEffect } from "react";
import axios from "axios";
import { NEWS_API_KEY } from "./../../../resources/apiClient";
import { formatDate } from "../../../utils/formatDates";
import { newsData } from "../../../data/newsData";

const NewsComponent = () => {
  return (
    <div className="flex flex-col justify-center">
      {newsData.map((article, index) => (
        <div key={index} className=" shadow-md rounded-lg p-6 m-6">
          <p className="text-3xl font-bold">{article.title}</p>
          <p className="text-xl text-italic mt-2">{article.description}</p>
          <p className="text-basic mt-2">{article.content}</p>
          <div>
            <a
              className="text-md text-blue-500"
              target="_blank"
              href={article.url}
            >
              Read More
            </a>
          </div>

          <p className="text-sm mt-2">
            <strong>Published at:</strong> {formatDate(article.publishedAt)}
          </p>
        </div>
      ))}
    </div>
  );
};

export default NewsComponent;
