import React, { useRef, useEffect } from "react";

const Modal = ({ isOpen, onClose, size = "xl", children }) => {
	const modalRef = useRef(null);

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === "Escape") {
				onClose();
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
	}, [onClose]);

	useEffect(() => {
		if (isOpen) {
			const prevActiveElement = document.activeElement;
			modalRef.current.focus();

			return () => {
				if (prevActiveElement && prevActiveElement.focus) {
					prevActiveElement.focus();
				}
			};
		}
	}, [isOpen]);

	const getSizeClass = (size) => {
		switch (size) {
			case "sm":
				return "modal-sm w-500px";
			case "md":
				return "modal-md w-600px";
			case "lg":
				return "modal-lg w-11/12";
			case "xl":
				return "modal-xl w-10/12";
			default:
				return "modal-md w-10/12";
		}
	};

	if (!isOpen) return null;
	return (
		<>
			<div className={`modal-backdrop ${isOpen ? "show" : "fade"}`}></div>
			<div
				role="dialog"
				tabIndex="-1"
				aria-modal="true"
				aria-labelledby="modalTitle"
				aria-describedby="modalDescription"
				ref={modalRef}
				className={`modal ${isOpen ? "show" : "fade"}`}
				onClick={(e) => {
					if (e.target === modalRef.current) {
						onClose();
					}
				}}
			>
				<div className="modal-dialog">
					<div className={`modal-content  ${getSizeClass(size)}`}>
						{children}
					</div>
				</div>
			</div>
		</>
	);
};

Modal.Header = ({ children }) => <div className="modal-header">{children}</div>;

Modal.Title = ({ children }) => (
	<div
		className="modal-title"
		// id="modalTitle"
	>
		{children}
	</div>
);

Modal.Body = ({ children }) => (
	<div
		className="modal-body"

		// id="modalBody"
	>
		{children}
	</div>
);

Modal.Footer = ({ children }) => (
	<div
		className="modal-footer"
		// id="modalFooter"
	>
		{children}
	</div>
);

export default Modal;
