import React from "react";
import Chart from "react-apexcharts";
const RiskAnalysisThree = ({ myValue }) => {
  const value = 100 - (100 * myValue) / 100;
  const valueRounded = Math.round(value * 100) / 100;
  return (
    <Chart
      type="radialBar"
      width={320}
      height={320}
      series={[valueRounded]}
      options={{
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            track: {
              background: "#c9b174",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 1,
                opacity: 0.5,
              },
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                formatter: function (val) {
                  return val + "%";
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },

        labels: ["%"],
        colors: ["#ce9f2c"],
      }}
    />
  );
};
export default RiskAnalysisThree;
