import React from "react";
import { useSelector } from "react-redux";

const UserNameIcon = () => {
	const username = useSelector((state) => state.user.username);

	const userInitial = username ? username.charAt(0).toUpperCase() : "";

	return <span className="user-name">{userInitial}</span>;
};

export default UserNameIcon;
