import { createSlice } from "@reduxjs/toolkit";

const scopesSlice = createSlice({
	name: "scopes",
	initialState: [],
	reducers: {
		setScopes: (state, action) => action.payload,
		addScope: (state, action) => {
			state.push(action.payload);
		},
		updateScope: (state, action) => {
			const index = state.findIndex((scope) => scope.id === action.payload.id);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteScope: (state, action) => {
			return state.filter((scope) => scope.id !== action.payload.id);
		},
	},
});

export const { setScopes, addScope, updateScope, deleteScope } =
	scopesSlice.actions;

export default scopesSlice.reducer;
