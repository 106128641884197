import { useState, useCallback } from "react";
import http from "../resources/http";
import axios from "axios";
import { toast } from "react-hot-toast";
import apiErrorHandler from "../services/apiErrorHandler";

const APIService = (endPoint) => {
	const [loading, setLoading] = useState(false);
	// const [error, setError] = useState(null);
	const [data, setData] = useState([]);

	const fetchData = useCallback(async () => {
		// Create a cancellation token source
	
		const source = axios.CancelToken.source();
		setLoading(true);
		try {
			const response = await http.get(endPoint);
			setData(response.data);
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				apiErrorHandler(error);
			}
		} finally {
			setLoading(false);
		}
		// Cleanup function to cancel request if the component unmounts 
		return () => {
			source.cancel("Component unmounted: Upload canceled");
		};
	}, [endPoint]);

	return { data, loading, fetchData };
};

export default APIService;
