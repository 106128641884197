import React from 'react';
import Chart from 'react-apexcharts';
const TopCyberThreats = ({ darkMode }) => {
  return (
    <div className="p-0">
      <Chart
        type="pie"
        width={450}
        height={210}
        series={[15, 25, 25]}
        options={{
          labels: ['Ransomware', 'Fraud', 'Misc.'],
          colors: ['#04365d', '#F7DD9E', '#70798A'],
          stroke: {
            width: 0,
          },
          dataLabels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
            },
            enabled: true,
            formatter: function (val, opts) {
              /* Format labels here */
              return opts.w.config.series[opts.seriesIndex];
            },
          },
          //start
          responsive: [
            {
              breakpoint: 535,
              options: {
                chart: {
                  width: 320,
                },
                legend: {
                  offsetY: -13,
                  offsetX: 0,
                },
              },
            },
          ],
          //finish
          legend: {
            fontSize: '14px',
            fontWeight: 'bold',
            itemMargin: 'left',
            labels: {
              colors: darkMode ? '#979ea5' : 'black',
            },
          },
        }}
      />
    </div>
  );
};

export default TopCyberThreats;
