// threatCatalog.js

export const THREAT_CATALOG = "/threat-catalog/threat-catalog/";
export const CATALOG_DETAIL = "/threat-catalog/catalog_detail/";
export const CATALOG_DELETE = "/threat-catalog/catalog_delete/";
export const CATALOG_LIST = "/threat-catalog/catalog-list/";
export const THREAT_CATEGORIES = "/threat-catalog/threat-categories";
export const THREAT_CATEGORY_BY_PK = "/threat-catalog/threat-category/";
export const DISCOVERABILITY_LEVELS = "/threat-catalog/discoverability-levels";
export const DISCOVERABILITY_LEVEL_BY_PK =
	"/threat-catalog/discoverability-level/";
export const EXPLOITABILITY_LEVELS = "/threat-catalog/exploitablity-levels";
export const EXPLOITABILITY_LEVEL_BY_PK =
	"/threat-catalog/exploitability-level/";
export const REPRODUCIBILITIES = "/threat-catalog/reproducibilities";
export const REPRODUCIBILITY_BY_PK = "/threat-catalog/reproducibility/";
export const CONFIDENTIALITY_LEVELS = "/threat-catalog/confidentiality-levels";
export const CONFIDENTIALITY_LEVEL_BY_PK =
	"/threat-catalog/confidentiality-level/";
export const INTEGRITY_LEVELS = "/threat-catalog/integrity-levels";
export const INTEGRITY_LEVEL_BY_PK = "/threat-catalog/integrity-level/";

export const AVAILABILITY_LEVELS = "/threat-catalog/availability-levels";
export const AVAILABILITY_LEVEL_BY_PK = "/threat-catalog/availability-level/";

export const THREAT_VECTORS_LIST = "/threat-catalog/threat-vectors";
export const UPDATE_THREAT_VECTOR = "/threat-catalog/threat-vector/";
export const CONTROL_ANALYSIS = "/threat-catalog/control-analysis/";
export const CONTROL_ANALYSIS_BY_PK = "/threat-catalog/control-analysis/";
export const CLIENT_CONTROLS_CATALOG =
	"/threat-catalog/client-controls-catalog/";
export const CLIENT_CONTROLS_CATALOG_BY_PK =
	"threat-catalog/client-controls-catalog";
