import React, { useEffect, useState } from "react";
import CustomTable from "../../../shared/CustomTable";
import http from "../../../resources/http";
import { NIST_LISTS, NIST_BY_PK, UPLOAD_NIST } from "../../../api/excelUpload";
// import { downloadExcelDataRiskTC } from "../../../utils/ExportExcelUtility";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import SearchHandler from "../../../shared/SearchHandler";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import ModalHandler from "./ModalHandler";
import { downloadExcelData } from "../../../utils/ExportExcelUtility";
import ModalFileUpload from "../../../shared/ModalFileUpload";

const ViewNIST = () => {
	const url_get = NIST_LISTS;
	const url_by_pk = NIST_BY_PK;
	const UploadURL = UPLOAD_NIST;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();
	const [data, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);

	// for file upload
	const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
	// search
	const debounceDelay = 1000;

	const [searchValue, setSearchValue] = useState("");

	const fieldsToFilterBy = [
		"control_identifier",
		"control_name",
		"discussion",
		"related_controls",
	];

	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url_get);
			setData(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setFetchLoading(false);
		}
	}
	useEffect(() => {
		fetchData();
	}, []);

	const filteredData = SearchHandler(
		data,
		searchValue,
		fieldsToFilterBy,
		debounceDelay
	);
	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (itemId) => {
		setFetchLoading(true);
		try {
			await http.delete(`${url_by_pk}${itemId}`);
			fetchData();
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			console.error("Failed to delete item:", error);
		} finally {
			setFetchLoading(false);
		}
	};

	return (
		<div className="main-container">
			<div className="table-title ">
				<span className="font-bold ">NIST Data</span>

				<div className="flex justify-between items-center space-x-4">
					<SearchInput
						searchValue={searchValue}
						setSearchValue={handleInputChange}
					/>
					<button
						onClick={() => {
							setIsModalUploadOpen(true);
						}}
						className="btn_file_download"
					>
						<img
							alt="upload-avatar"
							className="w-7 h-7"
							src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
						/>
					</button>
					<button className="btn_file_download">
						<svg
							onClick={() => downloadExcelData(data, "NIST")}
							xmlns="http://www.w3.org/2000/svg"
							height={28}
							width={28}
							fill-rule="evenodd"
							fill="white"
							clip-rule="evenodd"
							image-rendering="optimizeQuality"
							shape-rendering="geometricPrecision"
							text-rendering="geometricPrecision"
							viewBox="0 0 17639 17639"
						>
							<path
								fill="#1e7145"
								d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
							/>
							<path
								fill="#1e7145"
								d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
							/>
						</svg>
					</button>
					<button
						onClick={() => {
							setSelectedItem(null);
							setMode("add");
							setIsModalOpen(true);
						}}
						className="btn-add-new"
					>
						<AddIcon />
					</button>
				</div>
			</div>
			{fetchLoading ? (
				<LoadingComponent />
			) : searchValue.length > 0 && filteredData.length === 0 ? (
				<div className="alert-no-data">
					No data available for
					<span className="search-value">{searchValue}</span>
					at the moment.
				</div>
			) : (
				<CustomTable
					filteredData={filteredData}
					onEditClick={handleEdit}
					onDeleteClick={handleDelete}
					dataPerPage={6}
				/>
			)}

			{/* add and edit modal */}
			<ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="xl"
				header="Threat Risk Mapping"
				selectedItemId={id}
				selectedItem={selectedItem}
				url_get={url_get}
				url_by_pk={url_by_pk}
				fetchData={fetchData}
			/>
			{/* for uploading new file */}

			<ModalFileUpload
				header="NIST Data"
				UploadURL={UploadURL}
				fetchData={fetchData}
				isOpen={isModalUploadOpen}
				onClose={() => setIsModalUploadOpen(false)}
			/>
		</div>
	);
};

export default ViewNIST;
