import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { makeStyles } from '@material-ui/core/styles';

const marks = [
  {
    value: 8,
    label: '8',
  },
];

function valuetext(value) {
  return value;
}

export default function DiscreteSliderMarksInveStment({ darkMode }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: 150,
      width: 8,
      color: darkMode ? '#979ea5' : '#04365d',
      marginRight: 150,
      marginLeft: -60,
    },
    oprational: {
      color: darkMode ? '#979ea5' : '#04365d',
      fontSize: 30,
      '& .MuiSlider-thumb': {
        color: darkMode ? '#979ea5' : '#04365d',
      },
      '& .MuiSlider-rail': {
        height: 10,
        color: darkMode ? '#979ea5' : '#04365d',
      },
      '& .MuiSlider-track': {
        color: darkMode ? '#979ea5' : '#04365d',
        height: 10,
      },
      '& .MuiSlider-markLabel': {
        color: darkMode ? '#979ea5' : '#04365d',
        fontSize: '32px',
        fontStyle: 'bold',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.oprational} sx={{ width: 200 }}>
      <div className="text-4xl   font-bold">
        <Slider
          aria-label="Custom marks"
          defaultValue={8}
          getAriaValueText={valuetext}
          step={1}
          valueLabelDisplay="auto"
          marks={marks}
          disabled
          color="secondary"
        />
      </div>
    </Box>
  );
}
