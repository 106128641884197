import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const AuthenticatedRoute = ({ roles, ...props }) => {
  const location = useLocation();
  const tokenn = JSON.parse(localStorage.getItem('cyber-minds'));
  // const user_type = tokenn?.user?.user_type; //USER or ADMIN
  const user_type = tokenn?.user?.roles?.[0]?.role_name; //USER or ADMIN
  const loggedIn = tokenn?.user_status?.loggedIn; //true or false
  //set roles in local storage and check if user is allowed to access the page
  const allowed = roles.includes(user_type);
  console.log(user_type, 'usertype');

  return loggedIn ? (
    loggedIn ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={
          user_type === 'ClientUser'
            ? '/'
            : user_type === 'CybermindAdmin'
            ? '/super_threat_vectors'
            : user_type === 'ClientAdmin'
            ? '/threat_vectors'
            : '/signin'
        }
      />
    )
  ) : (
    <Redirect
      to={{
        pathname: '/signin',
      }}
    />
  );
};

export default AuthenticatedRoute;
// import { Route } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
// import { useState, useEffect } from 'react';

// const AuthenticatedRoute = ({ roles, ...props }) => {
//   const location = useLocation();
//   const tokenn = JSON.parse(localStorage.getItem('cyber-minds'));
//   const user_type = tokenn?.user?.user_type; //USER or ADMIN
//   const loggedIn = tokenn?.user_status; //true
//   //set roles in local storage and check if user is allowed to access the page
//   const allowed = roles.includes(user_type);

//   const [previousPath, setPreviousPath] = useState('');
//   useEffect(() => {
//     if (!allowed) {
//       setPreviousPath(location.pathname);
//     }
//   }, [location.pathname, allowed]);
//   const redirectBasedOnUserType = (user_type) => {
//     switch (user_type) {
//       case 'ClientUser':
//         return '/';
//       case 'CybermindAdmin':
//         return '/super_threat_vectors';
//       case 'ClientAdmin':
//         return '/threat_vectors';
//       default:
//         return '/';
//     }
//   };
//   return loggedIn ? (
//     loggedIn ? (
//       <Route {...props} />
//     ) : (
//       <Redirect to={previousPath || redirectBasedOnUserType(user_type)} />
//     )
//   ) : (
//     <Redirect
//       to={{
//         pathname: '/signin',
//       }}
//     />
//   );
// };

// export default AuthenticatedRoute;
