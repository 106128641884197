import { useState, useMemo } from 'react';

const useControlFilters = (
  data,
  controlDomain,
  controlCategory,
  controlTypes,
  controlApplicability
) => {
  const [selectedControlDomain, setSelectedControlDomain] = useState([]);
  const [selectedControlCategory, setSelectedControlCategory] = useState([]);
  const [selectedControlTypes, setSelectedControlTypes] = useState([]);
  const [selectedControlApplicability, setSelectedControlApplicability] =
    useState([]);

  const handleControlDomainChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedControlDomain([...selectedControlDomain, value]);
    } else {
      setSelectedControlDomain(
        selectedControlDomain.filter((domain) => domain !== value)
      );
    }
  };

  const handleControlCategoryChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedControlCategory([...selectedControlCategory, value]);
    } else {
      setSelectedControlCategory(
        selectedControlCategory.filter((category) => category !== value)
      );
    }
  };
  const handleControlTypeChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedControlTypes([...selectedControlTypes, value]);
    } else {
      setSelectedControlTypes(
        selectedControlTypes.filter((type) => type !== value)
      );
    }
  };

  const handleControlApplicabilityChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedControlApplicability([...selectedControlApplicability, value]);
    } else {
      setSelectedControlApplicability(
        selectedControlApplicability.filter(
          (applicability) => applicability !== value
        )
      );
    }
  };

  const handleClearSelections = () => {
    setSelectedControlDomain([]);
    setSelectedControlCategory([]);
    setSelectedControlTypes([]);
    setSelectedControlApplicability([]);
  };

  const filteredControls = useMemo(() => {
    return data?.filter((row) => {
      const isControlDomainSelected =
        selectedControlDomain.length === 0 ||
        selectedControlDomain.includes(
          row.client_control_catalog.control_domain
        );

      const isControlCategorySelected =
        selectedControlCategory.length === 0 ||
        selectedControlCategory.includes(
          row.client_control_catalog.control_category
        );
      const isControlTypeSelected =
        selectedControlTypes.length === 0 ||
        selectedControlTypes.includes(row.client_control_catalog.control_type);

      const isControlApplicabilitySelected =
        selectedControlApplicability.length === 0 ||
        selectedControlApplicability.includes(
          row.client_control_catalog.control_applicability
        );
      return (
        isControlDomainSelected &&
        isControlCategorySelected &&
        isControlTypeSelected &&
        isControlApplicabilitySelected
      );
    });
  }, [
    data,
    selectedControlDomain,
    selectedControlCategory,
    selectedControlTypes,
    selectedControlApplicability,
  ]);

  return {
    selectedControlDomain,
    selectedControlCategory,
    selectedControlTypes,
    selectedControlApplicability,
    handleControlDomainChange,
    handleControlCategoryChange,
    handleControlTypeChange,
    handleControlApplicabilityChange,
    handleClearSelections,
    filteredControls,
  };
};

export default useControlFilters;
