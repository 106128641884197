import { createSlice } from "@reduxjs/toolkit";

const threatVectorsSlice = createSlice({
	name: "threatVectors",
	initialState: [],
	reducers: {
		setThreatVectors: (state, action) => action.payload,
		addThreatVector: (state, action) => {
			state.push(action.payload);
		},
		updateThreatVector: (state, action) => {
			const index = state.findIndex(
				(threatVector) => threatVector.id === action.payload.id
			);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteThreatVector: (state, action) => {
			return state.filter(
				(threatVector) => threatVector.id !== action.payload.id
			);
		},
	},
});

export const {
	setThreatVectors,
	addThreatVector,
	updateThreatVector,
	deleteThreatVector,
} = threatVectorsSlice.actions;

export default threatVectorsSlice.reducer;
