const initialState = null;

const processIDsuper = (state = initialState, action) => {
  switch (action.type) {
    case "PID":
      return action.payload;
    default:
      return state;
  }
};
export { processIDsuper };
