import { createSlice } from "@reduxjs/toolkit";

const applicabilitySlice = createSlice({
	name: "applicability",
	initialState: [],
	reducers: {
		setApplicability: (state, action) => action.payload,
		addApplicability: (state, action) => {
			state.push(action.payload);
		},
		updateApplicability: (state, action) => {
			const index = state.findIndex(
				(applicability) => applicability.id === action.payload.id
			);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteApplicability: (state, action) => {
			return state.filter(
				(applicability) => applicability.id !== action.payload.id
			);
		},
	},
});

export const {
	setApplicability,
	addApplicability,
	updateApplicability,
	deleteApplicability,
} = applicabilitySlice.actions;

export default applicabilitySlice.reducer;
