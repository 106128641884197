export const CLIENTS = "account/get-clients";
export const CLIENT_UPDATE_DELETE = "account/client/:pk";
export const CHANGE_PASSWORD = "account/change-password";
export const PASSWORD_RESET = "account/password-reset";
export const SIGNUP = "account/signup";
export const LOGIN = "account/login";
export const LOGOUT = "account/logout";
export const GET_CLIENT_USERS = "account/get-client-users";
export const UPDATE_USER = "account/update-user/";
export const DELETE_USER = "account/delete-user/";
export const GET_CLIENT_TYPES = "account/get-client-types";
export const GET_CLIENT_STATUS = "account/get-client-status";
export const UPDATE_CLIENT_ACCOUNT = "account/update-client-user/";
export const DELETE_CLIENT_USERS = "account/delete-client-users";
export const CREATE_CLIENT_ACCOUNTS = "account/clients";
export const CURRENCY_LIST = "account/currency-list/";
export const CURRENCY = "account/currency/";
export const VERIFY_OTP = "account/verify-otp";
export const INDUSTRY_SECTOR = "threat-catalog-models/industry-sectors";
