import React, { useEffect, useState } from 'react';
import CustomTable from '../../shared/CustomTable';
import apiErrorHandler from '../../services/apiErrorHandler';
import http from '../../resources/http';
import {
  THREAT_VECTORS_LIST,
  UPDATE_THREAT_VECTOR,
} from '../../api/threatCatalog';
import { UPLOAD_THREAT_VECTOR_EXCEL } from '../../api/excelUpload';
import { downloadThreatRepository } from '../../utils/ExportExcelUtility';
import SearchInput from '../../shared/SearchInput/SearchInput';
import SearchHandler from '../../shared/SearchHandler';
import LoadingComponent from '../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import swal from 'sweetalert';
import ModalHandler from './ModalHandler';
import ModalFileUpload from '../../shared/ModalFileUpload';
import ThreatRepositoryModal from '../../shared/ThreatRepositoryModal/ThreatRepositoryModal';
import axios from 'axios';
import { useMutation, useQuery } from '@apollo/client';
import {
  DELETE_THREAT_VECTOR,
  GET_THREATS,
} from '../../graphql/superAdmin/query/query';
import { EXPORT_THREAT, IMPORT_THREAT } from '../../graphql/superAdmin/mutation/files/mutation';
import useExportAndDownload from '../../hooks/useExportAndDownload';

const ThreatVectorSuper = () => {
  const UploadURL = UPLOAD_THREAT_VECTOR_EXCEL;
  const url_create = THREAT_VECTORS_LIST;
  const url_get = THREAT_VECTORS_LIST;
  const url_by_pk = UPDATE_THREAT_VECTOR;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalShowHide = () => setIsModalOpen(!isModalOpen);

  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [id, setId] = useState();
  const [datas, setData] = useState([]);

  const [selectedSeverityLevel, setSelectedSeverityLevel] = useState('Low');
  const [fetchLoading, setFetchLoading] = useState(false);
  // for file upload
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

  const [exportThreat] = useMutation(EXPORT_THREAT); // Specific mutation
  const { exportAndDownloadFile } = useExportAndDownload();
  const [fileExtension, setFileExtension] = useState("xlsx");

  const handleExport = () => {
    exportAndDownloadFile(exportThreat, fileExtension, "Threat Repository"); // Pass the mutation
  };

  // search
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = [
    'name',
    'availability',
    'category',
    'discoverability',
    'exploitablity',
    'integrity',
    'confidentiality',
    'reproducibility',
    'threat_senario',
    'description',
  ];
  const debounceDelay = 400;
  const { loading, error, data, refetch } = useQuery(GET_THREATS, {
    variables: { limit: 10 },
  });

  const filteredData = SearchHandler(
    data?.threat,
    searchValue,
    fieldsToFilterBy,
    debounceDelay
  );
  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };

  console.log(filteredData, 'data');

  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
    handleModalShowHide();
    console.log(selectedItem, 'selectedItem');
  };
  const [deleteThreat] = useMutation(DELETE_THREAT_VECTOR);

  const handleDelete = async (itemId) => {
    setFetchLoading(true);
    try {
      const { data } = await deleteThreat({
        variables: { id: parseInt(itemId) },
      });
      if (data?.delete_threat?.success) {
        setFetchLoading(false);
        swal('Success', 'It has been deleted successfully', 'success');
        // Optionally refetch or update the data in your component state here
        // fetchData();
      } else {
        throw new Error(data?.delete_threat?.message);
      }
    } catch (error) {
      console.error('Failed to delete item:', error);
      setFetchLoading(false);
      swal('Error', 'Failed to delete item', 'error');
    }
  };
  const [importThreat] = useMutation(IMPORT_THREAT); //100, 100, 1000, 400
  const [fileContent, setFileContent] = useState('');

  // Function to convert file to Base64
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64 = reader.result.split(',')[1]; // Get Base64 string after the comma
      setFileContent(base64); // Set the base64 string in state
    };


    reader.onerror = (error) => {
      console.error('File reading error:', error);
    };

    if (file) {
      reader.readAsDataURL(file); // Converts file to base64 encoded string
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await importThreat({
        variables: {
          file_content: fileContent,
          inputs: { /* Add any additional input fields here */ },
        },
      });
      console.log('Import Response:', response.data.import_threat.data.total);
    } catch (error) {
      console.error('Error importing threat:', error);
    }
  };
  return (
    <div className="main-container">
      <div className="table-title ">
        <span className="">Threat Repository</span>

        <div className="flex justify-between items-center space-x-4">
          {['Low', 'Medium', 'High'].map((level) => (
            <label key={level}>
              <input
                type="radio"
                value={level}
                checked={selectedSeverityLevel === level}
                onChange={(e) => setSelectedSeverityLevel(e.target.value)}
              />
              <span className="p-2">{level}</span>
            </label>
          ))}

          <div>
            <button className="btn-add-new">
              <FilterListIcon />
            </button>
          </div>
          <SearchInput
            searchValue={searchValue}
            setSearchValue={handleInputChange}
          />
          <button
            onClick={() => {
              setIsModalUploadOpen(true);
            }}
            className="btn_file_download"
          >
            <img
              alt="upload-avatar"
              className="w-7 h-7"
              src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
            />
          </button>
          <button className="btn_file_download">
            <svg
              onClick={handleExport}
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fillRule="evenodd"
              fill="white"
              clipRule="evenodd"
              imageRendering="optimizeQuality"
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              setSelectedItem(null);
              setMode('add');
              setIsModalOpen(true);
            }}
            className="btn-add-new"
          >
            <AddIcon />
          </button>
        </div>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : searchValue.length > 0 && filteredData.length === 0 ? (
        <div className="alert-no-data">
          No data available for
          <span className="search-value">{searchValue}</span>
          at the moment.
        </div>
      ) : (
        <>
          <CustomTable
            filteredData={filteredData}
            selectedSeverityLevel={selectedSeverityLevel}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            passedExcludeColumns={[
              'impact',
              'likelihood',
              'category',
              'client',
              'discoverability_level',
              'exploitablity_level',
              'reproducibility_level',
              'confidentiality_level',
              'integrity_level',
              'availability_level',
              'init_id',
              'threat_rating',
              '__typename',
              // 'percent_of_breach',
              // 'lost_business_cost',
            ]}
          />
          <div className="flex  p-4 justify-end items-center">
            <div className="flex space-x-2">
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-200"
                  fill="#90ee90"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                  />
                </svg>
                <span className="">Very Low</span>
              </div>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-500"
                  fill="green"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                  />
                </svg>
                <span className="">Low</span>
              </div>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-yellow-100"
                  fill="yellow"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                  />
                </svg>
                <span className="">Moderate</span>
              </div>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-yellow-500"
                  fill="orange"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                  />
                </svg>
                <span className="">High</span>
              </div>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-800"
                  fill="maroon"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                  />
                </svg>
                <span className="">Critical</span>
              </div>
            </div>
          </div>
        </>
      )}

      {/* add and edit modal */}
      <ModalHandler
        isOpen={false}
        onClose={() => setIsModalOpen(false)}
        mode={mode}
        size="xl"
        header="Threat Vector"
        selectedItemId={id}
        selectedItem={selectedItem}
        url_create={url_create}
        url_get={url_get}
        url_by_pk={url_by_pk}
        // fetchData={fetchData}
      />
      {isModalOpen && (
        <ThreatRepositoryModal
          selectedItemId={id}
          action={mode}
          handleModalShowHide={handleModalShowHide}
          payload={selectedItem}
          url={url_create}
          modifyUrl={UPDATE_THREAT_VECTOR}
          fetchData={refetch}
        />
      )}

      {/* for uploading new file */}
      <ModalFileUpload
        header="Threat Vector"
        UploadURL={UploadURL}
        importMutation={importThreat}
        // fetchData={fetchData}
        isOpen={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
      />
    </div>
  );
};

export default ThreatVectorSuper;
