import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

export const downloadExcelReports = (data, name) => {
  const exportToExcel = data.map(({ id, file, image, user, ...rest }) => ({
    ...rest,
  }));
  var headers = [
    'Created date',
    'Closure date',
    'Title',
    'Description',
    'Status',
    'Response Description',
    'Urgency',
    'Category',
  ];

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, exportToExcel, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { exportToExcel: ws }, SheetNames: ['exportToExcel'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
export const downloadExcelATMapping = (data, name) => {
  const exportToExcel = data.map(
    ({ id, created_at, updated_at, image, user, ...rest }) => ({
      ...rest,
    })
  );
  var headers = ['Asset', 'Threat'];

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, exportToExcel, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { exportToExcel: ws }, SheetNames: ['exportToExcel'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
export const downloadExcelData = (data, name) => {
  const exportToExcel = data.map(
    ({
      id,
      created_at,
      updated_at,
      business_process,
      edited,
      client,
      headers,
      ...rest
    }) => ({
      ...rest,
    })
  );
  var headers = data[0]?.headers;

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, exportToExcel, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { exportToExcel: ws }, SheetNames: ['exportToExcel'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};

export const downloadExcelDataControls = (data, name) => {
  const exportToExcel = data.map(
    ({
      id,
      created_at,
      updated_at,
      edited,
      headers,
      number_of_threats_solved,
      relevance,
      ...rest
    }) => ({
      ...rest,
    })
  );
  console.log(exportToExcel, 'exportToExcel');
  var headers = data[0]?.headers;

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, exportToExcel, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { exportToExcel: ws }, SheetNames: ['exportToExcel'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
export const downloadExcelDataIndustryData = (data, name) => {
  const exportToExcel = data.map(
    ({
      id,
      created_at,
      updated_at,
      edited,
      headers,
      frequency_of_occurrence,
      success_rate,
      ...rest
    }) => ({
      ...rest,
    })
  );
  console.log(exportToExcel, 'exportToExcel');
  var headers = data[0]?.headers;

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, exportToExcel, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { exportToExcel: ws }, SheetNames: ['exportToExcel'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};

export const downloadExcelDataRiskTC = (data, name) => {
  const exportToExcel = data.map(
    ({ id, created_at, updated_at, edited, headers, ...rest }) => ({
      ...rest,
    })
  );

  const modifiedData = exportToExcel?.map((item) => {
    if (Array.isArray(item?.path)) {
      return { ...item, path: item.path.join(', ') };
    } else {
      return item;
    }
  });

  console.log(modifiedData, 'modifiedData');
  var headers = data[0]?.headers;

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, modifiedData, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { modifiedData: ws }, SheetNames: ['modifiedData'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
export const downloadConsensus = (data, name) => {
  const exportToExcel = data.map(
    ({ id, created_at, updated_at, edited, headers, ...rest }) => ({
      ...rest,
    })
  );

  const modifiedData = exportToExcel?.map((item) => {
    if (Array.isArray(item?.path)) {
      return { ...item, path: item.path.join(', ') };
    } else {
      return item;
    }
  });

  console.log(modifiedData, 'modifiedData');
  var headers = [
    'Control Domain',
    'Control Title',
    'Control ID',
    'Control Specification',
    'Question ID',
    'Consensus Assessments Question',
    'Parent',
  ];

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, modifiedData, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { modifiedData: ws }, SheetNames: ['modifiedData'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
export const downloadBusinessProcess = (data, name) => {
  const exportToExcel = data.map(
    ({
      id,
      created_at,
      updated_at,
      application_risk_profile,
      name,
      headers,
      ...rest
    }) => ({
      application_risk_profile,
      name,
    })
  );

  const modifiedData = exportToExcel?.map((item) => {
    if (Array.isArray(item?.path)) {
      return { ...item, path: item.path.join(', ') };
    } else {
      return item;
    }
  });

  console.log(modifiedData, 'modifiedData');
  var headers = ['Application Risk Profile', 'Business Process Name'];

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, modifiedData, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { modifiedData: ws }, SheetNames: ['modifiedData'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
export const downloadReports = (data, name) => {
  var headers = [
    'Factor',
    'Findings',
    'Issue Type',
    'Remediations',
    'Severity',
    'Title',
  ];

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, data, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
export const downloadThreatRepository = (data, name) => {
  // var headers = [
  //   'Name',

  //   'Description',
  //   'Threat Scenario',
  //   'Event frequency',
  //   'Cost of incident',
  //   'Cost of breach',
  //   'Threat rating',
  //   'Category',
  //   'Discoverability',
  //   'Exploitability',
  //   'Reproducibility',
  //   'Confidentiality',
  //   'Integrity',
  //   'Availability',
  //   'Percent of breach',
  //   'Lost business cost',
  // ];
  var headers = data[0]?.headers;

  const modifiedData = data.map(
    ({
      name,
      description,
      category,
      discoverability,
      exploitablity,
      reproducibility,
      confidentiality,
      integrity,
      availability,
      event_frequency,
      cost_of_incident,
      cost_of_breach,
      percent_of_breach,
      lost_business_cost,
      threat_senario,
      ...rest
    }) => ({
      category,
      discoverability,
      exploitablity,
      reproducibility,
      confidentiality,
      integrity,
      availability,
      event_frequency,
      cost_of_incident,
      cost_of_breach,
      percent_of_breach,
      lost_business_cost,
      threat_senario,
    })
  );

  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, modifiedData, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = { Sheets: { modifiedData: ws }, SheetNames: ['modifiedData'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: 'xlsx' });
  FileSaver.saveAs(finalData, `${name}.xlsx`);
};
