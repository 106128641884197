import React from "react";

const TableLoadingAnimation = () => {
	return (
		<div className="animate-pulse tables ">
			<div className="h-8 tables th"></div>
			<div className="tbody">
				<div className="h-8 bg-gray-200  dark:bg-gray-700 mt-3 mb-6 rounded"></div>
				<div className="h-8 bg-gray-300  dark:bg-gray-700 mb-6 rounded"></div>
				<div className="h-8 bg-gray-200  dark:bg-gray-700 mb-6 rounded"></div>
				<div className="h-8 bg-gray-300  dark:bg-gray-700 mb-6 rounded"></div>
				<div className="h-8 bg-gray-200  dark:bg-gray-700 mb-6 rounded"></div>
				<div className="h-8 bg-gray-300  dark:bg-gray-700 mb-6 rounded"></div>
				<div className="h-8 bg-gray-200  dark:bg-gray-700 mb-6 rounded"></div>
				<div className="paginationBttns"></div>
			</div>
		</div>
	);
};

export default TableLoadingAnimation;
