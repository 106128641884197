import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import http from '../resources/http';
import { useHistory } from 'react-router-dom';
import { baseURL } from '../resources/apiClient';
import swal from 'sweetalert';
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios';
const ChangePassword = (props) => {
  const [newPassword, setNewPassword] = useState();
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const handleOldPasswordChange = (e) => {
    setPasswords({ ...passwords, oldPassword: e.target.value });
    setErrors({ ...errors, oldPassword: '' });
  };
  const handleNewPasswordChange = (e) => {
    setPasswords({ ...passwords, newPassword: e.target.value });
    setErrors({ ...errors, newPassword: '' });
  };
  const handleConfirmPasswordChange = (e) => {
    setPasswords({ ...passwords, confirmPassword: e.target.value });
    setErrors({ ...errors, confirmPassword: '' });
  };
  const hadleSuccess = () => {
    setPasswords({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    swal('Success', 'It has been changed successfully.', 'success');
  };

  const history = useHistory();
  const temporaryPasswordStatus = false;
  const [viewPassword, setViewPassword] = useState();
  const [viewNewPassword, setViewNewPassword] = useState();
  const [viewConfirmPassword, setViewConfirmPassword] = useState();
  const toggleViewPassword = () => setViewPassword(!viewPassword);
  const toggleViewNewPassword = () => setViewNewPassword(!viewNewPassword);
  const toggleViewConfirmPassword = () =>
    setViewConfirmPassword(!viewConfirmPassword);

  const user = JSON.parse(localStorage.getItem('cyber-minds'))?.user;

  const has_temporary_password = user?.is_temporary_password;
  const userEmail = JSON.parse(localStorage.getItem('data'))?.email;
  console.log(userEmail);

  const [validationMessage, setValidationMessage] = useState('');
  const [error, setError] = useState(false);
  const passwordValidationRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;

  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleCopyPaste = (e) => {
    //Disable copy paste
    e.preventDefault();
    return false;
  };
  const existingData = JSON.parse(localStorage.getItem('data')) || {};

  // Step 2: Update the password in the retrieved data
  const updatedData = {
    ...existingData, // Spread the existing data
    password: passwords.newPassword, // Update the password
  };

  const validatePassword = () => {
    if (!passwordValidationRegex.test(passwords.newPassword)) {
      setError(true);
      setValidationMessage(
        // "New password must contain at least 6 characters, including a letter, number, and a special character"
        'Strong password required: Enter 8-256 characters. A combination of at least one uppercase letter, one lowercase letter, number and symbols. Do not include common words and names.'
      );
    } else {
      setValidationMessage('');
    }
  };

  const updateTemporaryStatus = () => {
    http
      .patch(`${baseURL}/account/update-user/${user?.id}`, {
        is_temporary_password: temporaryPasswordStatus,
      })
      .then((response) => {
        console.log(response.data);
        if (has_temporary_password) {
          history.push('/multifactor-authentication-setup');
        } else {
          history.push('/signout');
        }
      })
      .catch((err) => {
        swal(err);
      });
  };

  const submit = async (e) => {
    try {
      e.preventDefault();
      let isValid = true;

      // Validation
      if (!passwords.oldPassword.trim()) {
        setErrors({ ...errors, oldPassword: 'Old password cannot be blank' });
        isValid = false;
      }

      if (!passwords.newPassword || passwords.newPassword === '') {
        setErrors({ ...errors, newPassword: 'New password is required' });
        isValid = false;
      } else {
        setErrors({ ...errors, newPassword: '' });
      }
      if (!passwords.confirmPassword || passwords.confirmPassword === '') {
        setErrors({
          ...errors,
          confirmPassword: 'Confirm password is required',
        });
        isValid = false;
      } else {
        setErrors({ ...errors, confirmPassword: '' });
      }
      if (passwords.newPassword !== passwords.confirmPassword) {
        setErrors({
          ...errors,
          confirmPassword:
            'Confirm password is should be identical to new password',
        });
        isValid = false;
      } else {
        setErrors({ ...errors, confirmPassword: '' });
      }

      if (isValid) {
        setLoading(true);
        const response = await http.put(`${baseURL}/account/change-password`, {
          old_password: passwords.oldPassword,
          new_password: passwords.newPassword,
        });

        localStorage.setItem('data', JSON.stringify(updatedData));
        updateTemporaryStatus();
        setLoading(false);
        swal('Success', 'Password changed successfully!', 'success');
      }
    } catch (error) {
      setLoading(false);
      swal('Error', error.message, 'error');
    }
  };

  return (
    <div className="flex  flex-col justify-center items-center mt-36">
      <form onSubmit={(e) => submit(e)} className="w-2/6">
        <p className="mb-3 text-3xl">Change Password</p>
        <div class="mb-6">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Current Password
          </label>
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <input
              type={viewPassword ? 'text' : 'password'}
              id="password"
              value={passwords.oldPassword}
              onChange={handleOldPasswordChange}
              class="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="•••••••••"
              required
            />
            <span
              className="cursor-pointer"
              onClick={() => toggleViewPassword()}
            >
              {viewPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </span>
            <p className="text-red-500">{errors.oldPassword}</p>
          </div>
        </div>
        <div class="mb-6">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Create new password
          </label>
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <input
              type={viewNewPassword ? 'text' : 'password'}
              id="password"
              // onChange={handlePasswordChange}
              value={passwords.newPassword}
              onChange={handleNewPasswordChange}
              onBlur={validatePassword}
              class="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="•••••••••"
              required
            />
            <span
              className="cursor-pointer"
              onClick={() => toggleViewNewPassword()}
            >
              {viewNewPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </span>
          </div>
          <p className="text-red-500">{errors.newPassword}</p>
        </div>
        <PasswordStrengthBar password={passwords.newPassword} />
        <div class="mb-6">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Confirm new password
          </label>
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <input
              type={viewConfirmPassword ? 'text' : 'password'}
              id="password"
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
              value={passwords.confirmPassword}
              onChange={handleConfirmPasswordChange}
              class="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="•••••••••"
              required
            />
            <span
              className="cursor-pointer"
              onClick={() => toggleViewConfirmPassword()}
            >
              {viewConfirmPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </span>
          </div>
          <p className="text-red-500">{errors.confirmPassword}</p>
        </div>

        {validationMessage && (
          <p className="text-red-500 text-sm mt-2 w-96">{validationMessage}</p>
        )}

        <div className="flex flex-col justify-end items-end">
          {loading ? (
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <button
              class="focus:outline-none text-white    focus:ring-4  font-medium rounded-lg text-lg  px-5 py-3 mr-2 mb-2 "
              type="submit"
              style={{ backgroundColor: '#ce9f2c' }}
              // onClick={(e) => {
              //   submit(e);
              // }}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
