import React, { useState } from 'react';
import bg from '../../assets/imgs/bgg.jpg';
import ReportIcon from '@mui/icons-material/Report';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import swal from 'sweetalert';
import http from './../../resources/http';
import ManageTickets from '../../Support/ManageTickets';
import { USER_SUPPORT } from '../../api/support';
import SupportTicketManagemet from '../../Support/SupportTicketManagemet';
import SubmitIssues from './SubmitIssues';

const Support = ({ data }) => {
  let localStorageData = JSON.parse(localStorage.getItem('cyber-minds'));
  const user = localStorageData?.user?.id;
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [urgency, setUrgency] = useState('');
  const [category, setCategory] = useState('');

  // State variables for error messages
  const [titleError, setTitleError] = useState('');
  const [additionalError, setAdditionalError] = useState('');
  const [urgencyError, setUrgencyError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  // State variable to track the selected option
  const [selectedOption, setSelectedOption] = useState('My open request');

  const handleOptionClick = (option) => {
    // Set the selected option
    setSelectedOption(option);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setTitleError(''); // Clear error message when input changes
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError(''); // Clear error message when input changes
  };
  const hadleSuccess = () => {
    setTitle('');
    setDescription('');
    setUrgency('');
    setCategory('');
    swal('Success', 'It has been added successfully.', 'success');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    // Validation: Check if the title, image, file, and description are not empty
    if (!title.trim()) {
      setTitleError('Title cannot be blank');
      isValid = false;
    }

    if (!urgency || urgency === '') {
      setUrgencyError('Urgency is required');
      isValid = false;
    } else {
      setUrgencyError('');
    }

    if (!category || category === '') {
      setCategoryError('Category is required');
      isValid = false;
    } else {
      setCategoryError('');
    }

    if (!description.trim()) {
      setDescriptionError('Description cannot be blank');
      isValid = false;
    }

    if (isValid) {
      // Create a FormData object to send the form data
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('urgency', urgency); // Add urgency to the FormData
      formData.append('category', category); // Add category to the FormData
      formData.append('user', user);
      if (image !== null) {
        formData.append('file', image);
      }

      // Make the POST request using Axios
      http
        .post('https://api-dev.cronusgrx.io/api/support/tickets', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for file uploads
          },
        })
        .then((response) => {
          swal('Success', 'It has been added successfully.', 'success');
          console.log(response, 'response');
        })
        .catch((error) => {
          let errorMessage = 'error';
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            errorMessage = error.response.data.error; // Access the 'error' property in the response
          }
          errorMessage === 'error'
            ? hadleSuccess()
            : swal('Error', `${errorMessage}`, 'error');
        });
    }
  };

  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '100%',
          height: '240px',
        }}
        className="h-56 bg-green-500"
      >
        <form className="flex flex-col space-y-4 justify-center items-center mt-10">
          <p className="text-4xl text-white dark:text-gray-500">
            How can we help you?
          </p>
          <div className="flex justify-center items-center relative w-1/2">
            <input
              type="text"
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search how to upload excel files, how to manage controls...and more"
              required
            />
            <button
              type="submit"
              className="button p-3.5 ml-2  font-medium text-white  rounded-lg border  hover:bg-blue-800 focus:ring-4 focus:outline-none "
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
        </form>
      </div>
      <form className="flex items-center h-20 bg-gray-100 dark:bg-gray-600">
        <div className="text-red-500 dark:text-gray-800 w-11/12 flex justify-start items-center space-x-16 mx-auto p-4 ">
          <div
            onClick={() => handleOptionClick('Report')}
            className="cursor-pointer flex justify-center items-center space-x-3"
          >
            <ReportIcon style={{ fontSize: 65 }} />
            <p className="text-2xl  ">Report a Break/Fix Incident</p>
          </div>
          <div
            onClick={() => handleOptionClick('My open request')}
            className="text-yellow-500 dark:text-gray-800 cursor-pointer flex justify-center items-center space-x-3"
          >
            <LaunchIcon className="" style={{ fontSize: 65 }} />
            <p className="text-2xl  ">My request's</p>
          </div>
          {/* <div
            onClick={() => handleOptionClick('My pending request')}
            className="text-gray-500 dark:text-gray-800 cursor-pointer flex justify-center items-center space-x-3"
          >
            <AccessTimeFilledIcon style={{ fontSize: 65, color: 'gray' }} />
            <p className="text-2xl  ">My pending request</p>
          </div> */}
          <div
            onClick={() => handleOptionClick('Get more information')}
            className="text-blue-300 dark:text-gray-800 cursor-pointer flex justify-center items-center space-x-3"
          >
            <InfoIcon style={{ fontSize: 65 }} />
            <p className="text-2xl ">Get more information</p>
          </div>
        </div>
      </form>
      {selectedOption === 'Report' && <SubmitIssues action={"add"}/>}
      {selectedOption === 'My open request' && (
        <div className=" w-2/3 mx-auto ">
          <SupportTicketManagemet url={USER_SUPPORT} />
        </div>
      )}
      {/* {selectedOption === 'My pending request' && (
        <div className=" w-2/3 mx-auto p-4">
          {' '}
          <ManageTickets />
        </div>
      )} */}
      {selectedOption === 'Get more information' && (
        <div className=" w-2/3 mx-auto p-4"> (Get Information)</div>
      )}
    </div>
  );
};

export default Support;
