import { React } from 'react';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const MultiFactorSetup = () => {
  const history = useHistory();
  const otp = localStorage.getItem('totp');
  const totp = otp ?? '';
  
  return (
    <div className="flex flex-col text-2xl   justify-center items-center text-center mt-10 ">
      <div className="flex flex-col justify-center items-center text-center space-y-10">
        <div className="justify-content-md-center">
          <div md="auto">
            <p className="text-2xl text-left">
              Please scan the QR code with your authentication app to access
              your Cronus account.
            </p>
          </div>
        </div>
        <div className="justify-content-md-center">
          <div md="auto">
            <div className="">
              <QRCode value={totp} />
            </div>
          </div>
        </div>
        <div className="justify-content-md-center">
          <div md="auto">
            <button
              class="focus:outline-none text-white    focus:ring-4  font-medium rounded-lg text-lg  px-5 py-3 mr-2 mb-2 "
              type="submit"
              style={{ backgroundColor: '#ce9f2c' }}
              onClick={(e) => {
                localStorage.removeItem('cyber-minds');
                history.push('/multifactor-authentication');
              }}
            >
              I have finished scanning, Proceed
            </button>
          </div>
        </div>
        <div className="justify-content-md-center">
          <div md="auto">
            <p
              onClick={(e) => {
                history.push('/authenticator-app-setup');
              }}
              className="color text-blue-2xl underline cursor-pointer"
            >
              First time Authenticator App Setup Instructions?
            </p>
            <p>
              Thank you for being a valued partner with CyberMinds Consulting.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiFactorSetup;
