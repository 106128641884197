export const BUSINESS_CRITICALITY_RPO =
	"/configurations/business-criticality-rpo";
export const DATA_CLASSIFICATION_LEVEL =
	"/configurations/data-classification-level";
export const BROADEST_USER_POPULATION =
	"/configurations/broadest-user-population";
export const USER_POPULATION = "/configurations/user-population";
export const APPLICATION_ACCESSIBILITY =
	"/configurations/application-accessibility";
export const HOSTING_MODEL = "/configurations/hosting-model";
export const USER_INTERFACE_ACCESSIBILITY =
	"/configurations/user-interface-accessibility";

export const DATA_SOURCE = "/configurations/data-source";
export const BUSINESS_IMPACT = "/configurations/business-impact";
export const CONTROL_NAME = "/configurations/control-name";
export const CONTROL_NAME_BY_PK = "/configurations/control-name/:pk";
export const CONTROL_DOMAIN = "/configurations/control-domain";
export const CONTROL_DOMAIN_BY_PK = "/configurations/control-domain/:pk";
export const CONTROL_CATEGORY = "/configurations/control-category";
export const CONTROL_CATEGORY_BY_PK = "/configurations/control-category/:pk";
export const CONTROL_APPLICABILITY = "/excel-upload/control-applicability";
export const INDUSTRY_USECASE = "/configurations/industry-usecase";
export const INDUSTRY_USECASE_BY_PK = "/configurations/industry-usecase/:pk";
export const INDUSTRY_STANDARD = "/configurations/industry-standard";
export const INDUSTRY_STANDARD_BY_PK = "/configurations/industry-standard/:pk";
export const CONTROL_IMPACT = "/excel-upload/control-impact";
export const CONTROL_SCOPE = "/excel-upload/control-scope";
export const CONTROL_TYPE = "/excel-upload/control-type";
export const THREAT_VECTORS = "/threat-catalog/threat-vectors";
