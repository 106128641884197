const SupportNavBarItems = [
  {
    id: 'manageReports',
    name: 'Reports',
    link: '/manage_reports',
  },
  {
    id: 'manageTickets',
    name: 'Tickets',
    link: '/manage_tickets',
  },
  {
    id: 'manageUsers',
    name: 'Users Account',
    link: '/manage_users_account',
  },
  {
    id: 'logout',
    name: 'Logout',
    link: '/signout',
  },
];

export default SupportNavBarItems;
