import { useEffect, useState } from 'react';
import http from './../resources/http';

export const useApi = (url) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const response = await http.get(url);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsError(true);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [url]);

  return { data, isLoading, isError, fetchData };
};
