import React, { useEffect, useState } from "react";
import http from "../resources/http";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { baseURL } from "../resources/apiClient";
// import ExploitablePathsTabsWrapper from "../components/SuperAdmin/Configuration/ExploitablePathsTabsWrapper";
import ExploitablePathWrapperClient from "./ExploitablePathWrapperClient";
import { Chart } from "react-google-charts";
import { pathData, pathOptions } from "../data/data";

const ExploitablePaths = () => {
	const [path, setPath] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const processId = useSelector((state) => state.pid);
	const clientId = useSelector((state) => state.id);
	let type = JSON.parse(localStorage.getItem("cyber-minds"));
	let clientID = type?.user?.client;
	const usersPerPage = 10;
	const pagesVisited = pageNumber * usersPerPage;
	const incidentLength = path?.paths?.filter(
		(types) => types?.type === "incident"
	)?.length;
	const breachLength = path?.paths?.filter(
		(types) => types?.type === "breach"
	)?.length;

	const pageCount = Math.ceil(incidentLength / usersPerPage);
	const pageCountBreach = Math.ceil(breachLength / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const isAdmin = false;
	// useEffect(() => {
	// 	http
	// 		.get(
	// 			`${baseURL}/business_process/exploitable-paths?businessProcessId=${processId}&isAdmin=${isAdmin}`
	// 		)
	// 		.then((response) => {
	// 			setPath(response.data);
	// 		})
	// 		.then(
	// 			(response) => {},
	// 			(err) => {
	// 				console.log(err);
	// 			}
	// 		)
	// 		.catch((err) => {
	// 			return false;
	// 		});
	// }, []);
	return <ExploitablePathWrapperClient path={path} />;
};

export default ExploitablePaths;
