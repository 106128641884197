import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import { INVESTMENT_REQUIREMENT } from "../../../api/businessProcess";
import { CONTROL_DOMAIN, CONTROL_CATEGORY } from "../../../api/configurations";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";

const ModalHandler = ({
	isOpen,
	mode,
	onClose,
	selectedItemId,
	selectedItem,
	fetchData,
}) => {
	const [domainsDropdownOptions, setDomainsDropdownOptions] = useState([]);
	const [categoryDropdownOptions, setCategoryDropdownOptions] = useState([]);

	async function fetchFormOptionData() {
		try {
			const listOfDomains = await http.get(CONTROL_DOMAIN);
			const listOfCategory = await http.get(CONTROL_CATEGORY);

			const domainsOptionsData = listOfDomains.data?.map((item) => item.name);
			setDomainsDropdownOptions(domainsOptionsData);
			const categoryOptions = listOfCategory.data?.map((item) => item.title);
			setCategoryDropdownOptions(categoryOptions);
		} catch (error) {
			console.log("error occurred while fetching form dropdown options.");
		}
	}
	useEffect(() => {
		if (isOpen) {
			fetchFormOptionData();
		}
	}, [isOpen]);

	const initialState = {
		control_domain: "",
		control_solutions: "",
		investment_requirement: "",
		comment: "",
	};
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ initialState });
	const [errors, setErrors] = useState({});

	const validations = {
		control_domain: (value) => (value ? "" : "This field is required."),
		control_solutions: (value) => (value ? "" : "This field is required."),
		investment_requirement: (value) => (value ? "" : "This field is required."),
	};

	const handleChange = (key, value) => {
		setFormData((prevState) => ({ ...prevState, [key]: value }));
		if (validations[key]) {
			const error = validations[key](value);
			setErrors((prevState) => ({ ...prevState, [key]: error }));
		}
	};

	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			console.log(selectedItem, mode, "selectedItem");
			setLoading(true);
			setFormData(selectedItem);
			setLoading(false);
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const formFieldsConfig = {
		control_domain: {
			label: "Control Domain",
			type: "select",
			placeholder: "Select...",
			options: domainsDropdownOptions,
			value: formData.control_domain || "",
			onChange: (e) => handleChange("control_domain", e.target.value),
			error: errors.control_domain,
		},
		control_solutions: {
			label: "Control Solutions",
			type: "select",
			placeholder: "Select...",
			options: categoryDropdownOptions,
			value: formData.control_solutions || "",
			onChange: (e) => handleChange("control_solutions", e.target.value),
			error: errors.control_solutions,
		},
		investment_requirement: {
			label: "Investment Requirement",
			type: "number",
			placeholder: "Number...",
			min: 0,
			value: formData?.investment_requirement,
			onChange: (e) => handleChange("investment_requirement", e.target.value),
			error: errors.investment_requirement,
		},
		comment: {
			label: "Comment",
			type: "textarea",
			maxLength: 400,
			placeholder: "Text...",
			value: formData?.comment,
			onChange: (e) => handleChange("comment", e.target.value),
			error: errors.comment,
		},
	};

	const handleSubmit = () => {
		let newErrors = {};
		Object.entries(formFieldsConfig).forEach(([key, config]) => {
			if (validations[key]) newErrors[key] = validations[key](formData[key]);
		});
		setErrors(newErrors);
		if (Object.values(newErrors).every((error) => !error)) {
			// console.log("Submitting form data:", formData);
			if (mode === "edit") {
				http
					.patch(`${INVESTMENT_REQUIREMENT}/${selectedItemId}`, {
						control_domain: formData.control_domain,
						control_solutions: formData.control_solutions,
						investment_requirement: formData.investment_requirement,
						comment: formData.comment,
					})
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((err) => {
						swal("Error", "Something went wrong, please try again", "error");
					});
			} else {
				http
					.post(`${INVESTMENT_REQUIREMENT}`, {
						control_domain: formData.control_domain,
						control_solutions: formData.control_solutions,
						investment_requirement: formData.investment_requirement,
						comment: formData.comment,
					})
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((err) => {
						swal("Error", "Something went wrong, please try again", "error");
					});
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg">
			<Modal.Header>
				<Modal.Title>
					{mode === "edit"
						? "Update Investment Requirement"
						: "Add New Investment Requirement"}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className="modal-body-two-columns">
				<Form>
					{Object.entries(formFieldsConfig || {}).map(([key, fieldConfig]) => (
						<Form.Group key={key}>
							<Form.Label>{fieldConfig.label}</Form.Label>
							<Form.Control {...fieldConfig} />
							{errors[key] && (
								<Form.Control.Feedback type="invalid">
									{errors[key]}
								</Form.Control.Feedback>
							)}
						</Form.Group>
					))}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{mode === "edit" ? (
					<>
						<button
							className="btn-cancel"
							onClick={() => {
								onClose();
							}}
						>
							Cancel
						</button>
						<button className="btn-add-new" onClick={handleSubmit}>
							Save Changes
						</button>
					</>
				) : (
					<>
						<button
							className="btn-cancel"
							onClick={() => {
								onClose();
							}}
						>
							Cancel
						</button>
						<button className="btn-add-new" onClick={handleSubmit}>
							Add
						</button>
					</>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;
