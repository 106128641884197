import React from "react";
import Chart from "react-apexcharts";
import { useHandleTheme } from "../../hooks/useHandleTheme";

const CurrentIndustryThreats = () => {
  const { darkMode } = useHandleTheme();

  return (
    <div className="p-0">
      <Chart
        type="donut"
        width={475}
        height={250}
        series={[20, 20, 10, 50]}
        options={{
          labels: [
            "Web Application Attacks ",
            "DDoS",
            "Malicious Code",
            "Others",
          ],
          colors: [
            "#062341",
            "#00BFFF",
            "#229466",
            "#ce9f2c",

            //colors
            // color: "#00BFFF",
            // color: "#229466",
            // color: "#ce9f2c",
            // color: "#062341",
          ],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
            },
            formatter: function (val, opts) {
              /* Format labels here */
              return opts.w.config.series[opts.seriesIndex];
            },
          },
          //start
          responsive: [
            {
              breakpoint: 535,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  offsetY: -13,
                  offsetX: 0,
                },
                dataLabels: {
                  style: {
                    fontSize: "10px",
                    fontWeight: "normal",
                  },
                },
              },
            },
          ],
          stroke: {
            width: 0,
          },
          legend: {
            fontSize: "14px",
            fontWeight: "bold",
            itemMargin: "left",
            labels: {
              colors: darkMode ? '#979ea5' : 'black',
            },
          },
          ticks: {
            precision: 0,
          },
        }}
      />
    </div>
  );
};

export default CurrentIndustryThreats;
