import { useState, useMemo } from 'react';

const useTicketStatusFilter = (data) => {
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedUrgency, setSelectedUrgency] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  const handleStatusChange = (event) => {
    const { value, checked } = event.target;
    setSelectedStatus((prevSelectedStatus) => {
      if (checked) {
        return [...prevSelectedStatus, value];
      } else {
        return prevSelectedStatus?.filter((status) => status !== value);
      }
    });
  };
  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCategory((prevSelectedCategory) => {
      if (checked) {
        return [...prevSelectedCategory, value];
      } else {
        return prevSelectedCategory?.filter((category) => category !== value);
      }
    });
  };

  const handleUrgencyChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedUrgency((prevSelectedUrgency) => [
        ...prevSelectedUrgency,
        value,
      ]);
    } else {
      setSelectedUrgency((prevSelectedUrgency) =>
        prevSelectedUrgency?.filter((urgency) => urgency !== value)
      );
    }
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleClearSelections = () => {
    setSelectedStatus([]);
    setSelectedUrgency([]);
    setSelectedCategory([]);
    setSelectedDate('');
  };

  const filteredTickets = useMemo(() => {
    return data?.filter((row) => {
      const isStatusMatch =
        selectedStatus.length === 0 || selectedStatus.includes(row.status);
      const isUrgencyMatch =
        selectedUrgency.length === 0 || selectedUrgency.includes(row.urgency);
      const isCategoryMatch =
        selectedCategory.length === 0 ||
        selectedCategory.includes(row.category);
      // Convert the created_at date to the same format as selectedDate
      const rowDate = new Date(row.created_at).toISOString().split('T')[0];

      const isDateSelected = !selectedDate || selectedDate === rowDate;
      return (
        isStatusMatch && isUrgencyMatch && isDateSelected && isCategoryMatch
      );
    });
  }, [data, selectedStatus, selectedUrgency, selectedCategory, selectedDate]);

  return {
    selectedDate,
    selectedStatus,
    selectedUrgency,
    selectedCategory,
    handleCategoryChange,
    handleDateChange,
    handleStatusChange,
    handleUrgencyChange,
    handleClearSelections,
    filteredTickets,
  };
};

export default useTicketStatusFilter;
