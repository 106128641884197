import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import QRCode from 'react-qr-code';
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios';
import { baseURL } from '../../resources/apiClient';
import http from '../../resources/http';
const ForgotPasswordReset = (props) => {
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const history = useHistory();
  const temporaryPasswordStatus = false;
  const [viewPassword, setViewPassword] = useState();
  const [viewNewPassword, setViewNewPassword] = useState();
  const [viewConfirmPassword, setViewConfirmPassword] = useState();
  const toggleViewPassword = () => setViewPassword(!viewPassword);
  const toggleViewNewPassword = () => setViewNewPassword(!viewNewPassword);
  const toggleViewConfirmPassword = () =>
    setViewConfirmPassword(!viewConfirmPassword);

  const user = JSON.parse(localStorage.getItem('cyber-minds'))?.user;
  const totp = user?.totp ?? '';
  const userEmail = JSON.parse(localStorage.getItem('email'))?.email;

  const [validationMessage, setValidationMessage] = useState('');
  const [error, setError] = useState(false);
  const passwordValidationRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const validatePassword = () => {
    if (!passwordValidationRegex.test(newPassword)) {
      setError(true);
      setValidationMessage(
        'New password must contain at least 6 characters, including a letter, number, and a special character'
      );
    } else {
      setValidationMessage('');
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      axios
        .put(`${baseURL}/account/change-password`, {
          old_password: oldPassword,
          new_password: newPassword,
          email: userEmail,
        })
        .then(
          (response) => {
            swal(
              'Success',
              'Your password has been reset successful. Please login using your new password!',
              'success'
            );
            history.push('/signin');
          },
          (err) => {
            swal('Error', 'Change password failed. Please try again!', 'error');
          }
        );
    } else if (newPassword === confirmPassword) {
      swal(
        'Error',
        'Old password should not be the same as new Password',
        'error'
      );
    } else {
      swal('Error', 'Confirm password does not match', 'error');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center mt-36">
      <Form>
        <h2 className="mb-3">Changing your password</h2>
        <div class="mb-6">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Password
          </label>
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <input
              type={viewPassword ? 'text' : 'password'}
              id="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              class="bg-gray-50 focus:outline-none w-full  "
              placeholder="•••••••••"
              required
            />
            <span
              className="cursor-pointer"
              onClick={() => toggleViewPassword()}
            >
              {viewPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </span>
          </div>
        </div>
        <div class="mb-6">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            New password
          </label>
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <input
              type={viewNewPassword ? 'text' : 'password'}
              id="password"
              onChange={handlePasswordChange}
              onBlur={validatePassword}
              class="bg-gray-50 focus:outline-none w-full  "
              placeholder="•••••••••"
              required
            />
            <span
              className="cursor-pointer"
              onClick={() => toggleViewNewPassword()}
            >
              {viewNewPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </span>
          </div>
        </div>
        <PasswordStrengthBar password={newPassword} />
        <div class="mb-6">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Confirm your new password
          </label>
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <input
              type={viewConfirmPassword ? 'text' : 'password'}
              id="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              class="bg-gray-50 focus:outline-none w-full  "
              placeholder="•••••••••"
              required
            />
            <span
              className="cursor-pointer"
              onClick={() => toggleViewConfirmPassword()}
            >
              {viewConfirmPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </span>
          </div>
        </div>
        <div className="content-footer flex justify-center items-center mt-6">
          {validationMessage && (
            <p className="text-red-500 text-sm mt-2 w-96">
              {validationMessage}
            </p>
          )}

          {validationMessage.length > 1 ? (
            <button
              style={{ backgroundColor: '#ce9f2c' }}
              class="flex justify-center items-center focus:outline-none text-white  focus:ring-4  font-medium rounded-lg text-lg  px-5 py-2"
              type="submit"
              // onClick={(e) => {
              //   submit(e);
              // }}
            >
              Submit
            </button>
          ) : (
            <button
              style={{ backgroundColor: '#ce9f2c' }}
              class="flex justify-center items-center focus:outline-none text-white  focus:ring-4  font-medium rounded-lg text-lg  px-5 py-2"
              type="submit"
              onClick={(e) => {
                submit(e);
              }}
            >
              Submit
            </button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ForgotPasswordReset;
