import React, { useEffect } from "react";
import FileUploadLoading from "../../utils/FileUploadLoading";

const LoadingComponent = ({ fetchData }) => {
	return (
		<div
			style={{
				position: "fixed",
				top: "0",
				left: "0",
				width: "100%",
				height: "100%",
				background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				zIndex: 9999,
			}}
		>
			<div>
				<div className=" lds-facebook">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default LoadingComponent;
