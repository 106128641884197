import { createSlice } from "@reduxjs/toolkit";

const industryStandardSlice = createSlice({
	name: "industryStandard",
	initialState: [],
	reducers: {
		setIndustryStandard: (state, action) => action.payload,
		addIndustryStandard: (state, action) => {
			state.push(action.payload);
		},
		updateIndustryStandard: (state, action) => {
			const index = state.findIndex(
				(industryStandard) => industryStandard.id === action.payload.id
			);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteIndustryStandard: (state, action) => {
			return state.filter(
				(industryStandard) => industryStandard.id !== action.payload.id
			);
		},
	},
});

export const {
	setIndustryStandard,
	addIndustryStandard,
	updateIndustryStandard,
	deleteIndustryStandard,
} = industryStandardSlice.actions;

export default industryStandardSlice.reducer;
