import { useState } from "react";

const formatNumber = (
	num,
	decimalSeparator = ".",
	digitGroupSeparator = ","
) => {
	if (isNaN(num) || !isFinite(num)) {
		if (typeof num === "string") {
			num = parseFloat(num.replace(/[^0-9.-]/g, ""));
			if (isNaN(num) || !isFinite(num)) {
				return "Invalid Number";
			}
		} else {
			return "Invalid Number";
		}
	}
	const absNum = Math.abs(num);
	const isNegative = num < 0;
	const absTrillion = 1000000000000; // 10^12
	const suffixes = ["K", "M", "B", "T"];

	if (absNum < 1000) {
		return num.toLocaleString(undefined, {
			maximumFractionDigits: 1,
			minimumFractionDigits: 1,
			decimalSeparator,
			digitGroupSeparator,
		});
	}

	const formattedNum = absNum < absTrillion ? absNum : absTrillion;

	// Calculate suffix index based on moving the decimal point
	const suffixIndex = Math.floor(Math.log10(formattedNum) / 3);

	// Adjust the index by 1 to match the correct suffix
	const suffix = suffixes[Math.min(suffixIndex - 1, suffixes.length - 1)];

	// const suffix = suffixes[Math.min(suffixIndex, suffixes.length - 1)];

	return `${isNegative ? "-" : ""}${(
		formattedNum / Math.pow(1000, suffixIndex)
	).toFixed(1)}${suffix}`;
};

const useNumberFormatter = (
	value,
	decimalSeparator = ".",
	digitGroupSeparator = ","
) => {
	const [formattedValue, setFormattedValue] = useState(
		formatNumber(value, decimalSeparator, digitGroupSeparator)
	);

	const format = (value) => {
		setFormattedValue(
			formatNumber(value, decimalSeparator, digitGroupSeparator)
		);
	};

	return [formattedValue, format];
};

export default useNumberFormatter;
