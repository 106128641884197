// Array of field names to validate
export const fieldNames = [
  "businessProcessName",
  "application_accessibility",
  "business_criticality_rpo",
  "business_impact",
  "data_classification_level",
  "data_source",
  "hosting_model",
  "user_broadest_population",
  "user_interface_accessibility",
  "user_population",
  "max_sensitive_records",
  "business_owner",
  "application_owner",
  "revenue_contribution",
  "compliance_requirements",
];
