import React, { useState } from 'react';
import { useHandleTheme } from './../../hooks/useHandleTheme';
import { baseURL } from '../../resources/apiClient';
import { useApi } from '../../hooks/useApis';
import { RESPONSE_DISTRIBUTION_LIST } from '../../api/excelUpload';
import ReactPaginate from 'react-paginate';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../utils/formatNumbers';

const ResponseDistributionHeatMap = () => {
  const { darkMode } = useHandleTheme();
  const processId = useSelector((state) => state.pid);

  const { data, isLoading, fetchData, isError } = useApi(
    `${baseURL}/business_process/threat-profiling-response-distribution?businessProcessId=${processId}`
  );

  const dataPerPage = 6;
  // page pagination
  const [pageNumber, setPageNumber] = useState(0);
  // const dataPerPage = 7;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(data?.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const DisplayData = data
    ?.slice(pagesVisited, pagesVisited + dataPerPage)
    ?.map((items) => {
      return (
        <tr className="text-2xl h-28">
          <td>{capitalizeFirstLetter(items?.attack_type)}</td>
          <td>{formatNumber(items?.lost_business_cost)}</td>
          <td>{formatNumber(items?.detection_and_escalation)}</td>
          <td>{formatNumber(items?.ex_post_response)}</td>
          <td>{formatNumber(items?.notification)}</td>
        </tr>
      );
    });
  return (
    <div className="flex flex-col h-full w-full justify-evenly relative overflow-hidden  ">
      <table className="text-black tables h-full">
        <thead>
          <tr>
            <th className="">Attack Type</th>
            <th className="">Lost Busines Cost</th>
            <th className="">Detection and escalation</th>
            <th className="">Ex Post Response</th>
            <th className="">Notification</th>
          </tr>
        </thead>
        <tbody> {DisplayData}</tbody>
      </table>
      {data?.length > 6 ? (
        <div className="paginationBttns">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            activeClassName={'paginationActive'}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previousBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
          />
        </div>
      ) : null}
    </div>
  );
};
export default ResponseDistributionHeatMap;