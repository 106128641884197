export const sessionChecker = (lastLogin) => {
  let localStorageData = JSON.parse(localStorage.getItem("cyber-minds"));
  const lastLoginDate = localStorageData?.user?.last_login;
  const loginDuration = 60 * 60 * 1000; // 1 hour in milliseconds
  const now = new Date();
  const lastLoginTime = new Date(lastLogin);
  const timeSinceLastLogin = now - lastLoginTime;
  if (timeSinceLastLogin > loginDuration) {
    localStorage.removeItem("cyber-minds");
  } else {
    setTimeout(() => sessionChecker(lastLoginDate), loginDuration);
  }
};
