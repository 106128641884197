import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import http from '../../../../resources/http';
import { Link } from 'react-router-dom';
import Dist from './Dist';
import { products } from '../../../../authentication/store/actions';
import { baseURL } from '../../../../resources/apiClient';

const VendersDetail = ({ textMe }) => {
  const selectedVendor = useSelector((state) => state.vendor);
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const cpeValue = useSelector((state) => state.cpe);
  const selectedProduct = useSelector((state) => state.pr);
  const processId = useSelector((state) => state.pid);
  let type = JSON.parse(localStorage.getItem('cyber-minds'));
  let clientID = type?.user?.client;
  const [pageNumber, setPageNumber] = useState(0);
  console.log(data, 'cpeData');

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  useEffect(() => {
    http
      .get(
        `${baseURL}/excel-upload/assets?clientId=${clientID}&processId=${processId}`
      )
      .then((response) => {
        setDatas(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log('No Data To Show');
        }
      )
      .catch((err) => {
        return false;
      });
    http
      .get(
        `${baseURL}/business_process/business-impact/cev-summery/${cpeValue}`
      )
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  }, []);
  console.log(selectedProduct, 'selected product');
  //Detail properties to displied for every product
  const critical = data
    ?.filter(
      (vendors) =>
        vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === 'CRITICAL'
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  const high = data
    ?.filter(
      (vendors) => vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === 'HIGH'
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  console.log(high.length, 'length of high');
  const medium = data
    ?.filter(
      (vendors) =>
        vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === 'MEDIUM'
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  const low = data
    ?.filter(
      (vendors) => vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === 'LOW'
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  console.log(selectedVendor, 'product new set');
  const DisplayCpeData = data
    ?.filter((vendors) => vendors?.product === selectedProduct)
    .map((risk) => {
      return (
        <tr>
          <td className="product_color">{risk.product}</td>
          <td className="critical_color">{critical.length}</td>
          <td className="high_color">{high.length}</td>
          <td className="medium_color">{medium.length}</td>
          <td className="low_color">{low.length}</td>
        </tr>
      );
    });

  const DisplayData = data
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((vender) => {
      return (
        <tr>
          <td className="color_id font-semibold">{vender?.id}</td>
          <td className="table_width">{vender?.descriptions?.[0]?.value}</td>
          <td>{vender?.metrics?.cvssMetricV2?.[0]?.exploitabilityScore}</td>
          <td>{vender?.metrics?.cvssMetricV2?.[0]?.baseSeverity}</td>
          <td>{vender?.metrics?.cvssMetricV2?.[0]?.impactScore}</td>
        </tr>
      );
    });
  console.log(critical.length, 'critical');
  const pageCount = Math.ceil(data?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div className="relative ">
      <div className="z-100  flex flex-col space-y-10 justify-center relative p-10 items-center">
        <div className="w-96">
          <div className="rounded overflow-hidden h-full shadow-md flex flex-col  justify-center items-center">
            <div className="flex flex-col justify-center items-center space-y-4 w-full">
              <div className="flex space-x-4">
                <h3 className="text-4xl font-semibold">
                  Vulnerability Heat Map
                </h3>{' '}
              </div>
              <table className="text-black tables h-full">
                <thead>
                  <tr>
                    <th className="product_color">Product</th>
                    <th className="critical_color">Critical</th>
                    <th className="high_color">High</th>
                    <th className="medium_color">Medium</th>
                    <th className="low_color">Low</th>
                  </tr>
                </thead>
                <tbody>
                  {' '}
                  <tr>
                    <td className="product_color">{selectedVendor}</td>
                    <td className="critical_color">{critical.length}</td>
                    <td className="high_color">{high.length}</td>
                    <td className="medium_color">{medium.length}</td>
                    <td className="low_color">{low.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="text-black ">
          <div className="rounded overflow-hidden flex  justify-center items-center">
            <table class="tables">
              <thead>
                <tr>
                  <th>Vuln ID</th>
                  <th>Description Data</th>
                  <th>Exploitability Score</th>
                  <th>Severity</th>
                  <th>Impact Score</th>
                </tr>
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          </div>

          <div className="w-full">
            {data.length > 10 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
              />
            )}
          </div>
        </div>
      </div>
      <div className="p-10 flex justify-start items-center absolute top-[20rem]">
        <span className="">Powered by</span>
        <img
          className="h-14 w-20"
          src="https://www.cmswire.com/-/media/d895fbf3203a47808fbf4157e55d5cc8.ashx"
        />
      </div>
    </div>
  );
};

export default VendersDetail;
