// Define initial state
const initialState = {
  criticalities: [],
  classifications: [],
  broadcasts: [],
  maxUsers: [],
  appAccesss: [],
  hostingModels: [],
  userInterfaces: [],
  sources: [],
  businessImpacts: [],
  businessProcessList: [],
};

// Define action types
const actionTypes = {
  SET_CRITICALITIES: "SET_CRITICALITIES",
  SET_CLASSIFICATIONS: "SET_CLASSIFICATIONS",
  SET_BROADCASTS: "SET_BROADCASTS",
  SET_MAX_USERS: "SET_MAX_USERS",
  SET_APP_ACCESSS: "SET_APP_ACCESSS",
  SET_HOSTING_MODELS: "SET_HOSTING_MODELS",
  SET_USER_INTERFACES: "SET_USER_INTERFACES",
  SET_SOURCES: "SET_SOURCES",
  SET_BUSINESS_IMPACTS: "SET_BUSINESS_IMPACTS",
  SET_BUSINESS_PROCESS_LIST: "SET_BUSINESS_PROCESS_LIST",
};

// Define reducer function
const businessProcessReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_CRITICALITIES:
      return { ...state, criticalities: action.payload };
    case actionTypes.SET_CLASSIFICATIONS:
      return { ...state, classifications: action.payload };
    case actionTypes.SET_BROADCASTS:
      return { ...state, broadcasts: action.payload };
    case actionTypes.SET_MAX_USERS:
      return { ...state, maxUsers: action.payload };
    case actionTypes.SET_APP_ACCESSS:
      return { ...state, appAccesss: action.payload };
    case actionTypes.SET_HOSTING_MODELS:
      return { ...state, hostingModels: action.payload };
    case actionTypes.SET_USER_INTERFACES:
      return { ...state, userInterfaces: action.payload };
    case actionTypes.SET_SOURCES:
      return { ...state, sources: action.payload };
    case actionTypes.SET_BUSINESS_IMPACTS:
      return { ...state, businessImpacts: action.payload };
    case actionTypes.SET_BUSINESS_PROCESS_LIST:
      return { ...state, businessProcessList: action.payload };
    default:
      return state;
  }
};

export { initialState, actionTypes, businessProcessReducer };
