import { createSlice } from "@reduxjs/toolkit";

const impactSlice = createSlice({
	name: "impact",
	initialState: [],
	reducers: {
		setImpact: (state, action) => action.payload,
		addImpact: (state, action) => {
			state.push(action.payload);
		},
		updateImpact: (state, action) => {
			const index = state.findIndex(
				(impact) => impact.id === action.payload.id
			);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteImpact: (state, action) => {
			return state.filter((impact) => impact.id !== action.payload.id);
		},
	},
});

export const { setImpact, addImpact, updateImpact, deleteImpact } =
	impactSlice.actions;

export default impactSlice.reducer;
