import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import http from "../../../resources/http";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import IndustryUseCaseModalHandler from "./ControlModalsUntilBEupdate/IndustryUseCaseModalHandler";
import { INDUSTRY_USECASE } from "../../../api/configurations";
import {
	setUsecase,
	addUsecase,
	updateUsecase,
	deleteUsecase,
} from "../../../authentication/store/reducers/usecaseSlice";
import swal from "sweetalert";

const IndustryUseCase = () => {
	const url = INDUSTRY_USECASE;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const usecase = useSelector((state) => state.controls.usecase);
	const dispatch = useDispatch();

	const [id, setId] = useState();
	const [data, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["title", "description"];

	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url);
			const industryUseCaseList = response.data.map((item) => item.title);
			console.log(industryUseCaseList);
			dispatch(setUsecase(industryUseCaseList));
			setData(response.data);
			setFetchLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setFetchLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const filteredData = SearchHandler(data, searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (itemId) => {
		setFetchLoading(true);
		try {
			await http.delete(`${INDUSTRY_USECASE}/${itemId}`);
			fetchData();
			setFetchLoading(false);
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			swal("Failed", "Failed to delete item", "error");
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};

	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span>Industry Use Case</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							className="btn-add-new"
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
						>
							<AddIcon />
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
					/>
				)}
				{/* add and edit modal */}
				<IndustryUseCaseModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="lg"
					selectedItemId={id}
					selectedItem={selectedItem}
					url={url}
					header="Industry Use Case"
					fetchData={fetchData}
				/>
			</div>
		</main>
	);
};

export default IndustryUseCase;
