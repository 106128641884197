import React, { useEffect, useState } from "react";
import BreachsConfiguration from "../ConfigureExploitablePath/BreachsConfiguration";
import { Form } from "react-bootstrap";
import { baseURL } from "../../../resources/apiClient";
import http from "../../../resources/http";
import { useDispatch, useSelector } from "react-redux";

const ExploitablePathsTabsWrapper = () => {
	const [openTab, setOpenTab] = React.useState(1);
	const [businessProcessName, setBusinessProcessName] = useState([]);

	//toggle add
	const [searchValue, setSearchValue] = useState("");
	const [showFileUploader, setShowFileUploader] = useState(false);
	const toggleFileUploader = () => setShowFileUploader(!showFileUploader);

	const [data, setData] = useState([]);
	const clientId = useSelector((state) => state.id);
	const [singleClient, setSingleClient] = useState([]);

	const [company_name, setCompany] = useState(clientId);
	const [business_process, setBusinessProcess] = useState([]);
	const dispatch = useDispatch();
	const [hide, setHide] = useState(false);
	const showHideButton = () => setHide(!hide);
	const [path, setPath] = useState([]);
	const [selectedBusinessProcessId, setSelectedBusinessProcessId] =
		useState("");
	const processId = useSelector((state) => state.pid);
	let type = JSON.parse(localStorage.getItem("cyber-minds"));
	let clientID = type?.user?.client;

	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 1;
	const pagesVisited = pageNumber * usersPerPage;

	const pageCount = Math.ceil(path?.paths?.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const isAdmin = true;
	// Handle dropdown selection
	const fetchPaths = async () => {
		// setLoading(true);
		try {
			const response = await http.get(
				`${baseURL}/business_process/exploitable-paths?businessProcessId=${selectedBusinessProcessId}&isAdmin=${isAdmin}`
			);
			setPath(response.data);
		} catch (err) {
			// swal("No Data To Show");
		} finally {
			//   setLoading(false);
		}
	};

	useEffect(() => fetchPaths(), [selectedBusinessProcessId]);
	// Load initial data (if needed)
	const fetchData = () => {
		http
			.get(`${baseURL}/business_process/business-process/${processId}/`)
			.then((response) => {
				setBusinessProcessName(response.data);
			})
			.then(
				(response) => {},
				(err) => {
					console.log(err);
				}
			);
		http
			.get(`${baseURL}/account/get-clients`)
			.then((response) => {
				setData(response.data);
			})
			.then(
				(response) => {},
				(err) => {
					console.log(err);
				}
			);
		http
			.get(`${baseURL}/account/client/${clientId}`)
			.then((response) => {
				setSingleClient(response.data);
			})
			.then(
				(response) => {},
				(err) => {
					console.log(err);
				}
			);

		http
			.get(`${baseURL}/business_process/get-my-business-process`)
			.then((response) => {
				setBusinessProcess(response.data);
			})
			.then(
				(response) => {},
				(err) => {
					console.log(err);
				}
			);
	};
	useEffect(() => {
		fetchData();
	}, [company_name]);

	return (
		<div className="p-10 ">
			<div className="table-title ">
				<div className="flex justify-between items-center space-x-4">
					<span>Exploitability Paths</span>
					<span>| {businessProcessName?.name}</span>
					<div className="flex justify-between items-center">
						<div class="select ">
							<Form.Control
								as="select"
								className="bg-gray-700"
								value={selectedBusinessProcessId}
								onChange={(e) => {
									e.preventDefault();
									setSelectedBusinessProcessId(e.target.value);
								}}
							>
								<option>Select Business Process</option>
								{business_process?.map((x, y) => (
									<option value={x.id}>{x.name}</option>
								))}
							</Form.Control>
						</div>
					</div>
				</div>
			</div>
			<div className="">
				<BreachsConfiguration path={path} fetchData={fetchData} />
			</div>
		</div>
	);
};

export default ExploitablePathsTabsWrapper;
