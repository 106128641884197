import { useState } from 'react';
import swal from 'sweetalert';

function useFileUpload(toggleFileUploader) {
  const [file, setFile] = useState(null);

  const handleFile = (e) => {
    // Get the selected file
    let selectedFile = e.target.files[0];

    if (selectedFile) {
      // Extract the file extension from the selected file's name
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

      // Check if the file extension is allowed
      if (fileExtension === 'xlsx') {
        // Set the selected file in the component state
        setFile(selectedFile);
      } else {
        // If the selected file is not of the allowed type, show an error message
        swal('Error', 'Please upload a valid .xlsx file', 'error');
        // Clear the file input field
        e.target.value = null;
      }
    } else {
      toggleFileUploader();
    }
  };

  return [file, handleFile];
}

export default useFileUpload;
