import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';
import { SUPPORT_URL_REPORT, USER_SUPPORT } from '../api/support';
import SubmitIssues from '../components/Support/SubmitIssues';
import { ticketStatus, userTicketStatus } from '../data/data';
import useTicketStatusFilter from '../hooks/useTicketStatusFilter';
import { baseURL } from '../resources/apiClient';
import ImageViewer from '../shared/FileUploader/ImageViewer';
import { downloadExcelReports } from '../utils/ExportExcelUtility';
import http from './../resources/http';
import { formatDate } from './../utils/formatDates';
import {
  capitalizeFirstLetter,
  getFileExtension,
  replaceCommaWithSpace,
} from './../utils/helpers';
const ManageTickets = ({ url }) => {
  const [status, setStatus] = useState('');
  const [record, setRecord] = useState({});
  const [recordStatus, setRecordStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [urgency, setUrgency] = useState('');
  const [urgencyError, setUrgencyError] = useState('');
  const [image, setImage] = useState('');
  const [showImageViewer, setShowImageViewer] = useState(false);
  const toggleImageViewer = () => setShowImageViewer((prev) => !prev);
  const [statusFilter, setStatusFilter] = useState(false);
  const [urgencyFilter, setUrgencyFilter] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const showFilterToggle = () => setShowFilters((prev) => !prev);
  const [showUpdates, setShowUpdates] = useState(false);
  const showUpdateToggle = () => setShowUpdates((prev) => !prev);
  const [filterStatus, setFilterStatus] = useState('');
  let localStorageData = JSON.parse(localStorage.getItem('cyber-minds'));
  const user_type = localStorageData?.user?.user_type?.[0];

  const statusDropDown =
    user_type === 'CMCSupport' ? ticketStatus : userTicketStatus;

  const [data, setData] = useState([]);
  const [showHideMe, setSetShowHideMe] = useState(false);

  const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);
  const [id, setId] = useState();

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError(''); // Clear error message when input changes
  };

  const {
    selectedDate,
    selectedStatus,
    selectedUrgency,
    selectedCategory,
    handleCategoryChange,
    handleDateChange,
    handleStatusChange,
    handleUrgencyChange,
    handleClearSelections,
    filteredTickets,
  } = useTicketStatusFilter(data);

  const filteredData = filteredTickets?.filter((row) => {
    const statusMatch =
      !statusFilter || row?.status.match(new RegExp(status, 'i'));
    const urgencyMatch =
      !urgencyFilter || row?.urgency.match(new RegExp(urgency, 'i'));
    // const createdAtMatch = !createdAtFilter || /* Add your created_at filtering logic here */;

    return statusMatch && urgencyMatch;
  });

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 7;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(filteredData.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const statusList = [...new Set(data?.map((row) => row?.status))];
  const urgentList = [...new Set(data?.map((row) => row?.urgency))];
  const categoryList = [...new Set(data?.map((row) => row?.category))];

  console.log(data, 'filter hook');
  const handleClear = () => {
    setDescription('');
    setStatus('');
  };
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!status.trim()) {
      setStatusError('Status cannot be blank');
      isValid = false;
    }

    if (
      (status === 'closed' && !description) ||
      (status === 'closed' && description === '')
    ) {
      setDescriptionError('description is required');
      isValid = false;
    } else {
      setDescriptionError('');
    }

    if (isValid) {
      http
        .patch(`${baseURL}${USER_SUPPORT}/${id}`, {
          status: status,
          response: description,
        })
        .then((res) => {
          swal('success', 'Ticket Status updated successfully');
          fetchData();
          handleClear();
          handleModalShowHideMe();
        })
        .catch((err) => {
          handleModalShowHideMe();
          swal('no success');
        });
    }
  };

  const fetchData = () => {
    http
      .get(`${baseURL}${url}`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const DisplayData = filteredData
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((items) => {
      return (
        <>
          <tr key={items.id}>
            {USER_SUPPORT !== url && <td>{items?.user_obj?.email}</td>}
            <td>{items?.title}</td>
            <td>{items?.description}</td>
            <td>{items?.status}</td>
            <td>{items?.urgency}</td>
            <td>
              {items?.response === null || items?.response === ''
                ? 'N/A'
                : items?.response}
            </td>
            <td>{formatDate(items?.created_at)}</td>
            {SUPPORT_URL_REPORT === url && (
              <td>{formatDate(items?.updated_at)}</td>
            )}

            {SUPPORT_URL_REPORT !== url && (
              <td>
                {getFileExtension(items?.file) === 'IMAGE' ? (
                  <p
                    onClick={() => {
                      setImage(items?.file);
                      toggleImageViewer();
                    }}
                  >
                    {items?.file?.length > 1 ? (
                      <p className="button cursor-pointer rounded p-1 text-center">
                        View
                      </p>
                    ) : (
                      'N/A'
                    )}
                  </p>
                ) : (
                  <a
                    href={items.file}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {items?.file?.length > 1 ? (
                      <p className="button rounded p-1 text-center">Download</p>
                    ) : (
                      'N/A'
                    )}
                  </a>
                )}
              </td>
            )}
            {SUPPORT_URL_REPORT !== url && (
              <td>
                {user_type === 'CMCSupport' || items.status === 'pending' ? (
                  <IconButton
                    onClick={() => {
                      setId(items.id);
                      handleModalShowHideMe();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : user_type === 'CMCSupport' || items.status === 'open' ? (
                  // Action for 'open' status
                  <div>
                    <IconButton
                      onClick={() => {
                        setRecord(items);
                        showUpdateToggle();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    {USER_SUPPORT === url ? (
                      <IconButton
                        onClick={() => {
                          http
                            .patch(`${baseURL}${USER_SUPPORT}/${items.id}`, {
                              status: 'canceled',
                            })
                            .then((res) => {
                              swal('success', 'Ticket canceled successfully');
                              fetchData();
                            })
                            .catch((err) => {
                              swal('no success');
                            });
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    ) : null}
                  </div>
                ) : (
                  // Default action if none of the conditions are met for closed tickets show only status
                  <p className="border border-green-500 p-1 text-center rounded">
                    {items.status}{' '}
                  </p>
                )}
              </td>
            )}
          </tr>
        </>
      );
    });

  return (
    <>
      <div className="relative p-10">
        {showImageViewer ? (
          <ImageViewer image={image} toggleImageViewer={toggleImageViewer} />
        ) : null}
        <div className="w-full rounded-md overflow-hidden  shadow-sm">
          <div className="main text-white p-3 flex justify-between items-center">
            <div>
              <span className="font-bold ">Tickets</span>
            </div>

            <div className="flex  justify-end items-end space-x-6">
              {SUPPORT_URL_REPORT === url && (
                <div className="btn_file_download cursor-pointer">
                  <svg
                    onClick={() => downloadExcelReports(data, 'Reports')}
                    xmlns="http://www.w3.org/2000/svg"
                    height={28}
                    width={28}
                    fill-rule="evenodd"
                    fill="white"
                    clip-rule="evenodd"
                    image-rendering="optimizeQuality"
                    shape-rendering="geometricPrecision"
                    text-rendering="geometricPrecision"
                    viewBox="0 0 17639 17639"
                  >
                    <path
                      fill="#1e7145"
                      d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
                    />
                    <path
                      fill="#1e7145"
                      d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
                    />
                  </svg>
                </div>
              )}
              <div
                onClick={() => {
                  showFilterToggle();
                }}
                className="btn_file_download cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height={28}
                  width={28}
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                  />
                </svg>
              </div>

              {showFilters && (
                <div
                  // onMouseLeave={() => {
                  //   showFilterToggle();
                  //   // handleClearSelections();
                  // }}
                  className={`absolute text-white z-50 bg rounded-md p-2 top-24 space-y-2`}
                >
                  <div className="flex justify-between items-center">
                    <label>Filter by Status</label>
                    <div className=" flex justify-between items-center spae-x-2  w-16">
                      <label onClick={() => handleClearSelections()}>
                        <FilterAltOffIcon className="cursor-pointer" />
                      </label>
                      <label
                        className="button rounded-md p-1 cursor-pointer"
                        onClick={() => {
                          showFilterToggle();
                        }}
                      >
                        X
                      </label>
                    </div>
                  </div>
                  {statusList
                    ?.filter((list) => list?.length > 2)
                    .map((type) => (
                      <div key={type}>
                        <label className="mx-6 flex items-center space-x-2 text-xl">
                          <input
                            type="checkbox"
                            value={type}
                            checked={selectedStatus.includes(type)}
                            onChange={handleStatusChange}
                          />

                          <span>{capitalizeFirstLetter(type)}</span>
                        </label>
                      </div>
                    ))}
                  {user_type === 'CMCSupport' ? (
                    <div>
                      <label>Filter by Category</label>
                      {categoryList
                        ?.filter((list) => list?.length > 2)
                        .map((type) => (
                          <div key={type}>
                            <label className="mx-6 flex items-center space-x-2 text-xl">
                              <input
                                type="checkbox"
                                value={type}
                                checked={selectedCategory.includes(type)}
                                onChange={handleCategoryChange}
                              />
                              <span>{replaceCommaWithSpace(type)}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  ) : null}
                  {user_type === 'CMCSupport' ? (
                    <div>
                      <label>Filter by Urgency</label>
                      {urgentList
                        ?.filter((list) => list?.length > 2)
                        .map((type) => (
                          <div key={type}>
                            <label className="mx-6 flex items-center space-x-2 text-xl">
                              <input
                                type="checkbox"
                                value={type}
                                checked={selectedUrgency.includes(type)}
                                onChange={handleUrgencyChange}
                              />

                              <span>{capitalizeFirstLetter(type)}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  ) : null}
                  <div className="w-64">
                    <label>Filter by generated date</label>

                    <input
                      className="cursor-pointer mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      placeholder="Filter by date"
                    />
                  </div>
                  {/* <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={createdAtFilter}
                        onChange={() => handleCheckboxChange('created_at')}
                      />
                      Filter by Created At
                    </label>
                  </div> */}
                </div>
              )}
            </div>
          </div>
          <div className="rounded-md overflow-auto w-full">
            <table className="tables">
              <thead className="text-white sticky-header">
                <tr>
                  {USER_SUPPORT !== url && <th scope="col">User</th>}
                  <th scope="col">Title</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                  <th scope="col">Urgency</th>
                  <th scope="col">Response description</th>
                  <th scope="col">Created date</th>
                  {SUPPORT_URL_REPORT === url && (
                    <th scope="col">Closure date</th>
                  )}
                  {SUPPORT_URL_REPORT !== url && <th scope="col">File</th>}
                  {SUPPORT_URL_REPORT !== url && <th scope="col">Manage</th>}
                </tr>
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          </div>
        </div>

        <div className="">
          {data?.length > 7 ? (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              activeClassName={'paginationActive'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
            />
          ) : null}
        </div>
      </div>

      <Modal show={showHideMe}>
        <Modal.Header onClick={() => handleModalShowHideMe()}>
          <Modal.Title>Update Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-small">
          <Form.Control
            as="select"
            id="threats"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="">Select status</option>
            {statusDropDown?.map((item) => (
              <option value={item.name} key={item.id}>
                {item.label}
              </option>
            ))}
            <p className="text-red-500">{statusError}</p>
          </Form.Control>
          {status === 'closed' || status === 'canceled' ? (
            <div>
              <label htmlFor="description" className="block text-gray-600">
                * Description
              </label>
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={handleDescriptionChange}
                className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <p className="text-red-500">{descriptionError}</p>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => handleModalShowHideMe()}
          >
            Close
          </Button>
          <Button
            onClick={(e) => handleUpdateSubmit(e)}
            className="btn-add-new"
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUpdates}>
        <Modal.Header onClick={() => showUpdateToggle()}>
          <Modal.Title>Update Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-small">
          <SubmitIssues
            showUpdateToggle={showUpdateToggle}
            action="edit"
            payload={record}
            fetchData={fetchData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageTickets;
