import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	domain,
	vendors,
} from "../../../../authentication/store/actions/index";
import { useSelector, useDispatch } from "react-redux";
import http from "../../../../resources/http";
import { baseURL } from "../../../../resources/apiClient";
import LoadingSpinner from "../../../../utils/LoadingSpinner";

const VendorDetailsBrief = ({ name, showHide }) => {
	const [data, setData] = useState([]);
	const dispatch = useDispatch();
	const processId = useSelector((state) => state.pid);
	let type = JSON.parse(localStorage.getItem("cyber-minds"));
	let clientID = type.user.client;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		http
			.get(
				`${baseURL}/excel-upload/assets?clientId=${clientID}&processId=${processId}`
			)
			.then((response) => {
				setData(response.data);
				setLoading(true);
			})
			.then(
				(response) => {},
				(err) => {
					console.log("No Data To Show");
				}
			)
			.catch((err) => {
				return false;
			});
	}, []);
	console.log(data, "data");

	var uniqueVendors = data.reduce((unique, o) => {
		if (!unique.some((obj) => obj.vendors === o.vendors)) {
			unique.push(o);
		}
		return unique;
	}, []);
	const DisplayData = uniqueVendors.map((risk) => {
		return (
			<tr>
				<td
					className="link_hover pb-2 pt-2"
					onClick={() => {
						dispatch(vendors(risk.vendors));
						dispatch(domain(risk.vendors.toLowerCase() + ".com"));
					}}
				>
					<Link
						className=" link_hover text-decoration-none   vendor_list_font_size"
						to="/company-profile"
					>
						{risk.vendors}
					</Link>
				</td>
			</tr>
		);
	});
	return (
		<div className="z-100  flex justify-center   items-left">
			<div className=" flex flex-col ">
				<div className="rounded overflow-hidden flex  ">
					<table class="  table table-striped ">
						<thead>
							<tr>
								<th className=" text-2xl">Vendors</th>
							</tr>
						</thead>
						{loading ? (
							<tbody className="table_width ">{DisplayData}</tbody>
						) : (
							<LoadingSpinner />
						)}
					</table>
				</div>
			</div>
		</div>
	);
};

export default VendorDetailsBrief;
