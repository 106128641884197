import React, { useState, useEffect } from 'react';
import http from '../../../resources/http';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import swal from 'sweetalert';

const ModalHandler = ({
  isOpen,
  mode,
  onClose,
  header,
  selectedItem,
  selectedItemId,
  size,
  url_create,
  url_by_pk,
  fetchData,
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const initialState = {
    industry_sector: '',
    total_breaches: '',
    total_cost_of_data_breach: '',
    total_incidents: '',
    year: new Date().getFullYear(),
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ initialState });
  const [errors, setErrors] = useState({});

  const validations = {
    industry_sector: (value) => (value ? '' : 'This field is required.'),
    total_breaches: (value) => (value ? '' : 'This field is required.'),
    total_incidents: (value) => (value ? '' : 'This field is required.'),
    year: (value) => (value ? '' : 'This field is required.'),
    total_cost_of_data_breach: (value) =>
      value ? '' : 'This field is required.',
  };

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }
  };

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };

  useEffect(() => {
    if (mode === 'edit' && selectedItem) {
      console.log(selectedItem, mode, 'selectedItem');
      setLoading(true);
      setFormData(selectedItem);
      setLoading(false);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const formFieldsConfig = {
    industry_sector: {
      label: 'Industry Sector',
      type: 'text',
      placeholder: 'Text...',
      value: formData.industry_sector,
      onChange: (e) => handleChange('industry_sector', e.target.value),
      error: errors.industry_sector,
    },
    year: {
      label: 'year',
      type: 'number',
      placeholder: new Date().getFullYear(),
      for: 'yearInput',
      value: formData?.year || new Date().getFullYear(),
      onChange: (e) => handleChange('year', e.target.value),
      error: errors.year,
    },

    total_breaches: {
      label: 'Total Breaches',
      type: 'number',
      placeholder: 'Number...',
      inputMode: 'numeric',
      min: 0,
      value: formData?.total_breaches,
      onChange: (e) => handleChange('total_breaches', e.target.value),
      error: errors.total_breaches,
    },
    total_incidents: {
      label: 'Total Incidents',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.total_incidents,
      onChange: (e) => handleChange('total_incidents', e.target.value),
      error: errors.total_incidents,
    },
    total_cost_of_data_breach: {
      label: 'Total Cost of Breach',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.total_cost_of_data_breach,
      onChange: (e) =>
        handleChange('total_cost_of_data_breach', e.target.value),
      error: errors.total_cost_of_data_breach,
    },
  };

  const handleSubmit = () => {
    let newErrors = {};
    Object.entries(formFieldsConfig).forEach(([key, config]) => {
      if (validations[key]) newErrors[key] = validations[key](formData[key]);
    });
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      // console.log("Submitting form data:", formData);
      if (mode === 'edit') {
        http
          .patch(`${url_by_pk}${selectedItemId}`, formData)
          .then((res) => {
            fetchData();
            onClose();
            swal('Success!', 'It has been updated successfully', 'success');
            resetForm();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
                setErrors(newErrors);
              }
            } else {
              swal('Error', 'Something went wrong, please try again', 'error');
            }
          });
      } else {
        http
          .post(`${url_create}`, formData)
          .then((res) => {
            fetchData();
            onClose();
            swal('Success!', 'It has been updated successfully', 'success');
            resetForm();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
                setErrors(newErrors);
              }
            } else {
              swal('Error', 'Something went wrong, please try again', 'error');
            }
          });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === 'edit' ? `Update  ${header}` : `Add New  ${header}`}
        </Modal.Title>
      </Modal.Header>
      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body>
          <div className="modal-two-columns">
            <Form>
              {Object.entries(formFieldsConfig || {}).map(
                ([key, fieldConfig]) => (
                  <Form.Group key={key}>
                    <Form.Label>{fieldConfig.label}</Form.Label>
                    <Form.Control {...fieldConfig} />
                    {errors[key] && (
                      <Form.Control.Feedback type="invalid">
                        {errors[key]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
              )}
            </Form>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
