import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import swal from 'sweetalert';
import http from '../../../resources/http';
import { DATA_CLASSIFICATION_LEVEL } from '../../../api/configurations';
import CustomTable from '../../../shared/CustomTable';
import SearchHandler from '../../../shared/SearchHandler';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import ModalHandler from './ModalHandler';
import { GET_DATA_CLASSIFICATION } from '../../../graphql/superAdmin/query/businessProcessConfiguration/query';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_DATA_CALSSIFICATION, DELETE_DATA_CALSSIFICATION, UPDATE_DATA_CALSSIFICATION } from '../../../graphql/superAdmin/mutation/businessProcessConfiguration/mutation';
import toast from 'react-hot-toast';

const ClassificationLevel = () => {
  const url = DATA_CLASSIFICATION_LEVEL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add');
  const [selectedItem, setSelectedItem] = useState(null);

  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = ['label', 'value', 'description'];
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(GET_DATA_CLASSIFICATION, {
    variables: { limit: pageSize, offset: page * pageSize },
  });
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.data_classification_level_aggregate?.count || 0;
  //data
  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url);
      setData(response.data);
      setFetchLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchLoading(false);
    }
  }

  // useEffect(() => {
  // 	fetchData();
  // }, []);

  const filteredData = SearchHandler(
    data?.data_classification_level ?? [],
    searchValue,
    fieldsToFilterBy
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };
  const [update] = useMutation(UPDATE_DATA_CALSSIFICATION);
  const [create] = useMutation(ADD_DATA_CALSSIFICATION);
  const [del] = useMutation(DELETE_DATA_CALSSIFICATION);
  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });

      if (data?.delete_data_classification_level?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };
  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span className=" ">Data Classification Level</span>
          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            <button
              className="btn-add-new"
              onClick={() => {
                setSelectedItem(null);
                setMode('add');
                setIsModalOpen(true);
              }}
            >
              <AddIcon />
            </button>
          </div>
        </div>
        {/* table */}
        {fetchLoading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && filteredData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <CustomTable
            filteredData={filteredData}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            passedExcludeColumns={['__typename']}
          />
        )}
        {/* add and edit modal */}
        <ModalHandler
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode={mode}
          size="xl"
          selectedItemId={id}
          selectedItem={selectedItem}
          header="Data Classification Level"
          url={url}
          fetchData={refetch}
          createMutation={create}
          updateMutation={update}
        />
      </div>
    </main>
  );
};

export default ClassificationLevel;
