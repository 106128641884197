import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import { CLIENTS } from "../../../routes/accounts";
import {
	GET_BUSINESS_PROCESS_BY_ID,
	GET_BUSINESS_IMPACT_BY_SUPERUSER,
} from "../../../api/businessProcess";
import {
	BUSINESS_IMPACT_LIST,
	BUSINESS_IMPACT_BY_PK,
	UPLOAD_BIA_EXCEL_BY_SUPERUSER,
} from "../../../api/excelUpload";
import {
	THREAT_CATEGORIES,
	THREAT_VECTORS_LIST,
} from "../../../api/threatCatalog";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";

const ModalHandler = ({
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	url_create,
	url_by_pk,
	fetchData,
	selectedClientId,
	selectedBusinessProcessId,
}) => {
	const [fetchLoading, setFetchLoading] = useState(false);

	const initialState = {
		hierarchy: "",
		business_assets: "",
		asset_name: "",
		vendors: "",
		product: "",
		version: "",
		cpe: "",
		asset_type: "",
		asset_categorization: "",
		regulations: "",
		asset_risk: "",
		domain: "",
		business_process: "",
	};

	const [formData, setFormData] = useState({ initialState });
	const [errors, setErrors] = useState({});

	const validations = {
		threats: (value) => (value ? "" : "This field is required."),
		path: (value) => (value ? "" : "This field is required."),
	};

	const handleChange = (key, value) => {
		setFormData((prevState) => ({ ...prevState, [key]: value }));
		if (validations[key]) {
			const error = validations[key](value);
			setErrors((prevState) => ({ ...prevState, [key]: error }));
		}
	};

	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			console.log(selectedItem, mode, "selectedItem");
			setFetchLoading(true);
			setFormData(selectedItem);
			setFetchLoading(false);
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const formFieldsConfig = {
		hierarchy: {
			label: "Hierarchy",
			type: "text",
			placeholder: "Text...",
			value: formData?.hierarchy,
			onChange: (e) => handleChange("hierarchy", e.target.value),
			error: errors.hierarchy,
		},
		business_assets: {
			label: "Business Asset",
			type: "text",
			placeholder: "Text...",
			value: formData?.business_assets,
			onChange: (e) => handleChange("business_assets", e.target.value),
			error: errors.business_assets,
		},
		asset_name: {
			label: "Asset Name",
			type: "text",
			placeholder: "Text...",
			value: formData?.asset_name,
			onChange: (e) => handleChange("asset_name", e.target.value),
			error: errors.asset_name,
		},
		asset_risk: {
			label: "Asset Risk",
			type: "text",
			placeholder: "Text...",
			value: formData?.asset_risk,
			onChange: (e) => handleChange("asset_risk", e.target.value),
			error: errors.asset_risk,
		},
		asset_type: {
			label: "Asset Type",
			type: "text",
			placeholder: "Text...",
			value: formData?.asset_type,
			onChange: (e) => handleChange("asset_type", e.target.value),
			error: errors.asset_type,
		},
		asset_categorization: {
			label: "Asset Categorization",
			type: "text",
			placeholder: "Text...",
			value: formData?.asset_categorization,
			onChange: (e) => handleChange("asset_categorization", e.target.value),
			error: errors.asset_categorization,
		},
		domain: {
			label: "Domain",
			type: "text",
			placeholder: "Text...",
			value: formData?.domain,
			onChange: (e) => handleChange("domain", e.target.value),
			error: errors.domain,
		},
		vendors: {
			label: "Vendors",
			type: "text",
			placeholder: "Text...",
			value: formData?.vendors,
			onChange: (e) => handleChange("vendors", e.target.value),
			error: errors.vendors,
		},
		product: {
			label: "Product",
			type: "text",
			placeholder: "Text...",
			value: formData?.product,
			onChange: (e) => handleChange("product", e.target.value),
			error: errors.product,
		},
		version: {
			label: "Version",
			type: "text",
			placeholder: "Text...",
			value: formData?.version,
			onChange: (e) => handleChange("version", e.target.value),
			error: errors.version,
		},
		cpe: {
			label: "CPE",
			type: "text",
			placeholder: "Text...",
			value: formData?.cpe,
			onChange: (e) => handleChange("cpe", e.target.value),
			error: errors.cpe,
		},
		regulations: {
			label: "Regulations",
			type: "text",
			placeholder: "Text...",
			value: formData?.regulations,
			onChange: (e) => handleChange("regulations", e.target.value),
			error: errors.regulations,
		},
	};

	const handleSubmit = () => {
		let newErrors = {};
		Object.entries(formFieldsConfig).forEach(([key, config]) => {
			if (validations[key]) newErrors[key] = validations[key](formData[key]);
		});
		setErrors(newErrors);
		if (Object.values(newErrors).every((error) => !error)) {
			if (mode === "edit") {
				http
					.patch(`${url_by_pk}${selectedItemId}`, formData)
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			} else {
				http
					.post(`${url_create}`, formData)
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update  ${header}` : `Add New  ${header}`}
				</Modal.Title>
			</Modal.Header>
			{fetchLoading ? (
				<LoadingComponent />
			) : (
				<Modal.Body>
					<div className="modal-two-columns">
						<Form>
							{Object.entries(formFieldsConfig || {}).map(
								([key, fieldConfig]) => (
									<Form.Group key={key}>
										<Form.Label>{fieldConfig.label}</Form.Label>
										<Form.Control {...fieldConfig} />
										{errors[key] && (
											<Form.Control.Feedback type="invalid">
												{errors[key]}
											</Form.Control.Feedback>
										)}
									</Form.Group>
								)
							)}
						</Form>
					</div>
				</Modal.Body>
			)}
			<Modal.Footer>
				<button
					className="btn-cancel"
					onClick={() => {
						onClose();
						resetForm();
					}}
				>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;
