import React from 'react';
import Chart from 'react-apexcharts';

const BusinessObjectives = ({ darkMode }) => {
  return (
    <div className="p-0">
      <Chart
        className="z-0"
        type="donut"
        width={420}
        height={250}
        series={[15, 8, 8, 8, 46, 15]}
        options={{
          labels: [
            'New Product Launch',
            'Operational Efficiency',
            'Cloud Migration',
            'Online Presence',
            'Risk Reduction',
            'Regulations',
          ],
          colors: [
            '#04365d',
            '#e87c10',
            '#229466',
            '#872646',
            '#ce9f2c',
            '#00BFFF',
          ],
          stroke: {
            width: 0,
          },
          //start
          responsive: [
            {
              breakpoint: 610,
              options: {
                chart: {
                  width: 390,
                },
                legend: {
                  offsetY: -13,
                  offsetX: 0,
                },
                dataLabels: {
                  style: {
                    fontSize: '13px',
                    fontWeight: 'normal',
                  },
                  formatter: function (val, opts) {
                    /* Format labels here */
                    return opts.w.config.series[opts.seriesIndex];
                  },
                },
              },
            },
          ],
          //finish
          stroke: {
            width: 0,
          },
          legend: {
            fontSize: '14px',
            fontWeight: 'bold',
            itemMargin: 'left',
            labels: {
              colors: darkMode ? '#979ea5' : 'black',
            },
          },
          dataLabels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
            },
            enabled: true,
            formatter: function (val, opts) {
              /* Format labels here */
              return opts.w.config.series[opts.seriesIndex];
            },
          },
        }}
      />
    </div>
  );
};

export default BusinessObjectives;
