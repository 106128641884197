import React, { useState } from 'react';
import { RiArrowDownSFill, RiArrowRightDownFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import cronusLogo from '../../../assets/imgs/logo.svg';
import ClientAdminNavbarItems from './ClientAdminNavbarItems';

const ClientAdminNavbar = () => {
  const processName = useSelector((state) => state.name);
  try {
    var companyInfo = JSON.parse(localStorage.getItem('cyber-minds'));
    var hasAdminPermission = companyInfo?.user?.is_client_admin;
    var company_name = companyInfo?.user?.name;
  } catch (e) {
    <Redirect to="/signin" />;
  }
  const [activeSubLink, setActiveSubLink] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const [activeSecondaryDropdown, setActiveSecondaryDropdown] = useState(null);

  const handleDropdown = (id) => {
    if (activeDropdown === id) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(id);
    }
  };

  const handleSecondaryDropdown = (id) => {
    if (activeSecondaryDropdown === id) {
      setActiveSecondaryDropdown(null);
    } else {
      setActiveSecondaryDropdown(id);
    }
  };

  const [activeItemId, setActiveItemId] = useState(null);

  const handleItemClick = (id) => {
    setActiveItemId(id);
  };
  return (
    <nav className="nav">
      <div className="flex justify-between items-center">
        <img className="cronus-logo" src={cronusLogo} alt="Cronus logo" />
        <div className="company_name">{company_name}</div>
      </div>

      <ul>
        {ClientAdminNavbarItems.map((item) => (
          <li
            key={item.id}
            className={
              activeLink === item.id ? ' nav-content active' : ' nav-content'
            }
            onMouseLeave={() => {
              setActiveDropdown(null);
              setActiveSecondaryDropdown(null);
            }}
          >
            <Link
              to={item.link}
              onClick={() => {
                if (item.dropdown) handleDropdown(item.id);
                setActiveLink(item.id);
              }}
            >
              {item.name}
              {item.dropdown && <RiArrowDownSFill className="dropdown-icon" />}
            </Link>
            {item.dropdown && activeDropdown === item.id && (
              <div className="nav-dropdown-container">
                {item.dropdown.map((subItem) => (
                  <div key={subItem.name}>
                    <Link
                      to={subItem.link}
                      className={
                        activeSubLink === subItem.name
                          ? 'nav-dropdown-content active'
                          : 'nav-dropdown-content'
                      }
                      onClick={() => {
                        if (subItem.secondaryDropdown) {
                          handleSecondaryDropdown(subItem.name);
                        }
                        setActiveSubLink(subItem.name);
                      }}
                    >
                      {subItem.name}
                      {subItem.secondaryDropdown && (
                        <RiArrowRightDownFill className="dropdown-icon" />
                      )}
                    </Link>
                    {subItem.secondaryDropdown &&
                      activeSecondaryDropdown === subItem.name && (
                        <div
                          className="nav-secondary-dropdown-container"
                          onMouseLeave={() => {
                            setActiveSecondaryDropdown(null);
                          }}
                        >
                          {subItem.secondaryDropdown.map((secondaryItem) => (
                            <div>
                              <Link
                                key={secondaryItem.name}
                                to={secondaryItem.link}
                                className={
                                  activeSubLink === secondaryItem.name
                                    ? 'nav-dropdown-secondary-content active'
                                    : 'nav-dropdown-secondary-content'
                                }
                              >
                                {secondaryItem.name}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ClientAdminNavbar;
