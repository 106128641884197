import { gql } from '@apollo/client';
export const GET_THREATS = gql`
  query GetThreats($limit: Int!) {
    threat(limit: $limit) {
      id
      name
      event_frequency
      cost_of_incident
      percent_of_breach
      lost_business_cost
    }
  }
`;
export const GET_USERS = gql`
  query GETUSERS($limit: Int!) {
    user(limit: $limit) {
      id
      email
      username
      is_superuser
      is_active
      roles {
        id
        role_name
      }
      client {
        name
      }
    }
    client(limit: $limit) {
      id
      name
      currency {
        name
        symbol
        code
      }
      sensitivity_level
    }
    user_role {
      id
      role_name
    }
  }
`;
export const GET_EXPLOITABILITY_LEVELS = gql`
  query GetExploitableLevels($limit: Int!, $offset: Int) {
    exploitable_level(limit: $limit, offset: $offset) {
      id
      value
      difficulty_level
      description
    }
    exploitable_level_aggregate {
      count
    }
  }
`;
export const GET_AVAILABILITY_LEVELS = gql`
  query GetAvailabilityLevels($limit: Int!, $offset: Int) {
    availability_level(limit: $limit, offset: $offset) {
      id
      value
      difficulty_level
      description
    }
    availability_level_aggregate {
      count
    }
  }
`;
export const GET_CONFIDENTIALITY_LEVELS = gql`
  query GetConfidentialityLevels($limit: Int!, $offset: Int) {
    confidentiality_level(limit: $limit, offset: $offset) {
      id
      value
      difficulty_level
      description
    }
    confidentiality_level_aggregate {
      count
    }
  }
`;
export const GET_DISCOVERABILITY_LEVELS = gql`
  query GetDiscoveryLevels($limit: Int!, $offset: Int) {
    discovery_level(limit: $limit, offset: $offset) {
      id
      value
      difficulty_level
      description
    }
    discovery_level_aggregate {
      count
    }
  }
`;

export const GET_INTEGRITY_LEVELS = gql`
  query GetIntegrityLevels($limit: Int!) {
    integrity_level(limit: $limit) {
      id
      value
      difficulty_level
      description
    }
    integrity_level_aggregate {
      count
    }
  }
`;
export const GET_REPRODUCIBILITIES = gql`
  query GetReproducibilityLevels($limit: Int!) {
    reproducibility_level(limit: $limit) {
      id
      value
      difficulty_level
      description
    }
    reproducibility_level_aggregate {
      count
    }
  }
`;
export const GET_THREAT_CATEGORIES = gql`
  query GetReproducibilityLevels($limit: Int!) {
    reproducibility_level(limit: $limit) {
      id
      value
      difficulty_level
      description
    }
  }
`;

export const ADD_THREAT_VECTOR = gql`
  mutation ADD_THREAT_VECTOR($inputs: [threatInputType]!) {
    create_threat(inputs: $inputs) {
      affected_rows
      data {
        name
      }
    }
  }
`;
export const UPDATE_THREAT_VECTOR = gql`
  mutation UPDATE_THREAT_VECTOR($id: Int!, $input: threatInputType!) {
    update_threat(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const DELETE_THREAT_VECTOR = gql`
  mutation DELETE_THREAT_VECTOR($id: Int!) {
    delete_threat(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($limit: Int!) {
    client(limit: $limit) {
      id
      name
      currency {
        name
        symbol
        code
      }
      sensitivity_level
      industry_sector {
       name
    }
    }
    user_role {
      id
      role_name
    }
    enums_client_sensitivity_level
    currency(limit: $limit) {
      id
      name
      code
      symbol
    }
    industry_sector(limit: $limit) {
      id
      name
    }
  }
`;
export const GET_EXPLOITABLE_PATHS = gql`
  query GET_EXPLOITABLE_PATHS($limit: Int!, $offset: Int) {
    exploitable_path(limit: $limit, offset: $offset) {
      id
      path_type
      entry {
        name
        stage
      }
      middle {
        name
        stage
      }
      end {
        name
        stage
      }
    }
    exploitable_path_aggregate {
      count
    }
  }
`;
export const GET_CURRENCY = gql`
  query GETCURRENCY($limit: Int!, $offset: Int) {
    currency(limit: $limit, offset: $offset) {
      id
      name
      code
      symbol
    }
    currency_aggregate {
      count
    }
  }
`;
export const GET_RISK_SENSETIVITY_LEVEL = gql`
  query GETRISKSENSETIVITYLEVEL($limit: Int!, $offset: Int) {
    risk_sensitivity_level(limit: $limit, offset: $offset) {
      id
      level
      min_value
      max_value
    }
    risk_sensitivity_level_aggregate {
      count
    }
    enums_risk_sensitivity_level_level
  }
`;
export const GET_BIA_SUPER_ADMIN = gql`
  query GETBIASUPERADMIN($limit: Int!, $offset: Int) {
    asset(limit: $limit, offset: $offset) {
      id
      name
      vendor
      risk
      cpe
      version
    }
    asset_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_EFFECTIVENESS = gql`
  query GETCONTROLEFFECTIVENESS($limit: Int!, $offset: Int) {
    control_effectiveness(limit: $limit, offset: $offset) {
      id
      control_type{
       label
      }
      control_domain{
       label
      }
      control_category{
       label
      }
      effectiveness_in_percent
    }
    control_type(limit: $limit, offset: $offset) {
      id
      label
    }
    control_domain(limit: $limit, offset: $offset) {
      id
      label
    }
    control_category(limit: $limit, offset: $offset) {
      id
      label
    }
    control_effectiveness_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_CATALOG = gql`
  query GET_CONTROL_CATALOG($limit: Int!, $offset: Int) {
    control(limit: $limit, offset: $offset) {
      id
      name
      control_type{
        label
      }
      control_domain{
        label
      }
      control_category{
        label
      }
      }
      control_aggregate {
        count
      }
  }
`;
export const GET_TC_MAPPING = gql`
  query GETTCMAPPING($limit: Int!, $offset: Int) {
    threat_control_mapping(limit: $limit, offset: $offset) {
      id
      threat {
        name
      }
      control {
        name
      }
    }
    threat_control_mapping_aggregate {
      count
    }
  }
`;
export const GET_INVESTMENT_REQUIREMENTS = gql`
  query GETINVESTMENTREQUIREMENTS($limit: Int!, $offset: Int) {
    control_investment_requirement(limit: $limit, offset: $offset) {
      id
      control_domain
      control_category
      investment_requirement
    }
    control_investment_requirement_aggregate {
      count
    }
  }
`;
export const GET_INDUSTRY_SECTOR = gql`
  query GET_INDUSTRY_SECTOR($limit: Int!, $offset: Int) {
    industry_sector(limit: $limit, offset: $offset) {
      id
      name
      description
    }
    industry_sector_aggregate {
      count
    }
  }
`;
export const GET_AT_MAPPING = gql`
  query GET_AT_MAPPING($limit: Int!, $offset: Int) {
    asset_threat_mapping(limit: $limit, offset: $offset) {
      id
    asset{
      name
    }
    threat{
      name
    }
    }
    asset(limit: $limit, offset: $offset) {
      id
      name
      vendor
      risk
      cpe
      version
    }
    threat(limit: $limit, offset: $offset) {
      id
      name
      event_frequency
      cost_of_incident
      percent_of_breach
      lost_business_cost
    }
    asset_threat_mapping_aggregate {
      count
    }
  }
`;
