import { React, useState } from 'react';
import { TiArrowBack } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import Figure from 'react-bootstrap/Figure';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Step_1 from '../../assets/imgs/Step_1.png';
import Step_2 from '../../assets/imgs/Step_2.png';
import Step_3 from '../../assets/imgs/Step_3.png';

const AuthenticatorSetup = () => {
  const [showHideHelp, setShowHideHelp] = useState(false);
  const handleShowHideHelp = () => setShowHideHelp(!showHideHelp);

  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('cyber-minds'))?.user;
  const totp = user?.totp ?? '';

  return (
    <div className="">
      <div className="flex flex-col  justify-center items-center text-center">
        <div className="justify-content-md-center">
          <div md="auto">
            <h1
              onClick={(e) => {
                history.push('/multifactor-authentication-setup');
              }}
            >
              <TiArrowBack className="text-black-2xl " />
            </h1>
          </div>
          <div md="auto">
            <h1 className="text-black-2xl">
              Authenticator App Setup Instructions{' '}
            </h1>
          </div>
        </div>
        <div className="justify-content-md-center flex justify-center items-center">
          <div xs={6} md={4}>
            <Figure>
              <Figure.Caption md="justify-content-md-center"></Figure.Caption>
              <Figure.Image
                width={450}
                height={650}
                alt="StepOne"
                src={Step_1}
              />
              <h2 className="text-black-2xl button">First Step </h2>
            </Figure>
          </div>
          <div xs={6} md={4}>
            <Figure>
              <Figure.Image
                width={450}
                height={650}
                alt="171x180"
                src={Step_2}
              />
              <h2 className="text-black-2xl button">Second Step</h2>
            </Figure>
          </div>{' '}
          <div xs={6} md={4}>
            <Figure>
              <Figure.Image
                width={450}
                height={650}
                alt="171x180"
                src={Step_3}
              />
              <h2 className="text-black-2xl button ">Third Step</h2>
            </Figure>
          </div>
        </div>
        <div className="justify-content-md-center">
          <Col md="auto">
            <p>
              Thank you for being a valued partner with CyberMinds Consulting.
            </p>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatorSetup;
