import React, { useState, useEffect } from "react";
import Modal from "../../../shared/Modal";
import swal from "sweetalert";
import toast from "react-hot-toast";

const ATAddEditHandler = ({
  data,
  isOpen,
  mode,
  onClose,
  selectedItemId,
  selectedItem,
  updateMutation,
  createMutation,
  fetchData,
}) => {
  const [formData, setFormData] = useState({
    asset: "",
    threat: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle input change
  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (errors[key]) {
      setErrors((prevState) => ({ ...prevState, [key]: "" }));
    }
  };

  // Validate the form before submission
  const validateForm = () => {
    const newErrors = {};
    if (!formData.asset) newErrors.asset = "Please select an asset.";
    if (!formData.threat) newErrors.threat = "Please select a threat.";
    return newErrors;
  };

  // Reset form data when needed
  const resetForm = () => {
    setFormData({
      asset: "",
      threat: "",
    });
    setErrors({});
  };

  // Prefill the form if we're in edit mode
  useEffect(() => {
    if (mode === "edit" && selectedItem) {
      setFormData({
        asset: selectedItem?.asset_id || "",
        threat: selectedItem?.threat_id || "",
      });
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  // Handle form submission
  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      let response;

      if (mode === "edit") {
        response = await updateMutation({
          variables: {
            id: parseInt(selectedItemId),
            input: {
              asset_pk: parseInt(formData.asset), // Single asset ID
              threat_pk: parseInt(formData.threat), // Single threat ID
            },
          },
        });
      } else {
        response = await createMutation({
          variables: {
            inputs: {
              created_at: new Date().toISOString(),
              asset_pk: parseInt(formData.asset), // Single asset ID
              threat_pk: parseInt(formData.threat), // Single threat ID
            },
          },
        });
      }

      toast.success("Operation successful!");
      fetchData(); // Refresh data after mutation
      onClose();   // Close modal after success
    } catch (error) {
      toast.error("Error occurred while processing.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h3>{mode === "edit" ? "Edit Mapping" : "Add Mapping"}</h3>

      <div>
        {/* Asset Dropdown */}
        <label>Asset</label>
        <select
          value={formData.asset}
          onChange={(e) => handleChange("asset", e.target.value)}
        >
          <option value="">Select Asset</option>
          {data?.asset?.map((asset) => (
            <option key={asset.id} value={asset.id}>
              {asset?.name}
            </option>
          ))}
        </select>
        {errors.asset && <p className="error">{errors.asset}</p>}

        {/* Threat Dropdown */}
        <label>Threat</label>
        <select
          value={formData.threat}
          onChange={(e) => handleChange("threat", e.target.value)}
        >
          <option value="">Select Threat</option>
          {data?.threat?.map((threat) => (
            <option key={threat.id} value={threat.id}>
              {threat?.name}
            </option>
          ))}
        </select>
        {errors.threat && <p className="error">{errors.threat}</p>}

        {/* Submit and Cancel Buttons */}
        <div className="modal-actions">
          <button onClick={handleSubmit} disabled={loading}>
            {loading ? "Saving..." : mode === "edit" ? "Update" : "Create"}
          </button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default ATAddEditHandler;
