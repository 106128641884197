import React, { useEffect, useState } from 'react';
import CustomTable from '../../../shared/CustomTable';
import http from '../../../resources/http';
import {
  CONTROLS_CATALOG_LIST,
  CONTROLS_CATALOG,
  UPLOAD_CONTROLS_CATALOG,
} from '../../../api/excelUpload';
import { CONTROL_DOMAIN } from '../../../api/configurations';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import SearchHandler from '../../../shared/SearchHandler';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import swal from 'sweetalert';
import ModalHandler from './ModalHandler';
import { downloadExcelDataControls } from '../../../utils/ExportExcelUtility';
// import UploadControlsCatalog from "./UploadControlsCatalog";
import ModalFileUpload from '../../../shared/ModalFileUpload';
import { GET_CONTROL_CATALOG } from '../../../graphql/superAdmin/query/query';
import { useQuery,useMutation } from '@apollo/client';
import { EXPORT_CONTROL_CATALOG, IMPORT_CONTROL_CATALOG } from '../../../graphql/superAdmin/mutation/files/mutation';
import useExportAndDownload from '../../../hooks/useExportAndDownload';

const ViewControlsCatalog = () => {
  const UploadURL = UPLOAD_CONTROLS_CATALOG;
  const url_get = CONTROLS_CATALOG_LIST;
  const url_by_pk = CONTROLS_CATALOG;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [threatCategoryDropdownOptions, setThreatCategoryDropdownOptions] =
    useState([]);
  const [domainDropdownOptions, setDomainDropdownOptions] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);

  // for file upload
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

  // search
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = [
    'control_name',
    'control_domain',
    'control_category',
    'control_scope',
    'control_impact',
    'control_type',
    'control_applicability',
    'primary_threat',
    'secondary_threat',
    'industry_usecase',
    'industry_standard_text',
    'control_description',
  ];
  const debounceDelay = 400; // Debounce delay in milliseconds

  const [exportControl] = useMutation(EXPORT_CONTROL_CATALOG); // Specific mutation
  const { exportAndDownloadFile } = useExportAndDownload();
  const [fileExtension, setFileExtension] = useState("xlsx");

  const handleExport = () => {
    exportAndDownloadFile(exportControl, fileExtension, "Controls Catalog"); // Pass the mutation
  };

  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url_get);
      const listOfDomain = await http.get(CONTROL_DOMAIN);

      setData(response.data);
      const domainOptions = listOfDomain.data?.map((item) => item.name);
      setDomainDropdownOptions(domainOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setFetchLoading(false);
    }
  }
  // useEffect(() => {
  // 	fetchData();
  // }, []);

  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(GET_CONTROL_CATALOG, {
    variables: { limit: pageSize, offset: page * pageSize },
  });

  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };

  const totalItems = data?.control_aggregate?.count || 0;

  const formatted = data?.control?.map(items=>({
    id:items?.id,
    name:items.name,
    control_type:items?.control_type?.label,
    control_domain:items?.control_domain?.label,
    control_category:items?.control_category?.label,
  }))
  console.log(formatted, 'formatted');

  const filteredData = SearchHandler(
    formatted ?? [],
    searchValue,
    fieldsToFilterBy,
    // arrayFieldsToFilterBy,
    debounceDelay
  );
  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };

  const handleDelete = async (itemId) => {
    setFetchLoading(true);
    try {
      await http.delete(`${url_by_pk}/${itemId}`);
      fetchData();
      setFetchLoading(false);
      swal('Success', 'It has been deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete item:', error);
      setFetchLoading(false);
    }
  };
  const [importControlsCatalog] = useMutation(IMPORT_CONTROL_CATALOG); //100, 100, 1000, 400

  return (
    <div className="main-container">
      <div className="table-title ">
        <span className="">Controls Catalog</span>

        <div className="flex justify-between items-center space-x-4">
          <div class="">
            <button
              className="btn-add-new"
              onClick={() => setDropdownShow(true)}
            >
              <FilterListIcon />
            </button>
            {/* Select Control Domain fielter goes here  */}
          </div>
          <SearchInput
            searchValue={searchValue}
            setSearchValue={handleInputChange}
          />
          <button
            onClick={() => {
              setIsModalUploadOpen(true);
            }}
            className="btn_file_download"
          >
            <img
              alt="upload-avatar"
              className="w-7 h-7"
              src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
            />
          </button>
          <button className="btn_file_download">
            <svg
              onClick={handleExport}
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fill-rule="evenodd"
              fill="white"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              setSelectedItem(null);
              setMode('add');
              setIsModalOpen(true);
            }}
            className="btn-add-new"
          >
            <AddIcon />
          </button>
        </div>
      </div>
      {loading ? (
        <LoadingComponent />
      ) :  formatted?.length < 1 ? (
        <div className="alert-no-data">
          No data available for
          <span className="search-value">{searchValue}</span>
          at the moment.
        </div>
      ) : (
        <CustomTable
          filteredData={formatted}
          onEditClick={handleEdit}
          onDeleteClick={handleDelete}
          dataPerPage={5}
          page={page}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          passedExcludeColumns={['__typename']}
        />
      )}

      {/* add and edit modal */}
      <ModalHandler
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode={mode}
        size="xl"
        header="Controls Catalog"
        selectedItemId={id}
        selectedItem={selectedItem}
        url_create={url_get}
        url_get={url_get}
        url_by_pk={url_by_pk}
        fetchData={fetchData}
      />

      {/* for uploading new file */}
      <ModalFileUpload
        importMutation={importControlsCatalog}
        UploadURL={UploadURL}
        fetchData={fetchData}
        isOpen={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
      />
    </div>
  );
};

export default ViewControlsCatalog;
