import React from 'react';

const PleaseWait = () => {
  return (
    <div
      style={{ color: '#ce9f2c' }}
      className=" flex justify-center items-center space-x-2 "
    >
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p>Please Wait...</p>
    </div>
  );
};

export default PleaseWait;
