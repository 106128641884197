import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import swal from 'sweetalert';
import http from '../../../resources/http';
import {
  DISCOVERABILITY_LEVELS,
  DISCOVERABILITY_LEVEL_BY_PK,
} from '../../../api/threatCatalog';
import CustomTable from '../../../shared/CustomTable';
import SearchHandler from '../../../shared/SearchHandler';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import ModalHandler from './ModalHandler';
import { GET_DISCOVERABILITY_LEVELS } from '../../../graphql/superAdmin/query/query';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_DISCOVERY,
  DELETE_DISCOVERY,
  UPDATE_DISCOVERY,
} from '../../../graphql/superAdmin/mutation/mutation';

const Discoverability = () => {
  const url_create = DISCOVERABILITY_LEVELS;
  const url_by_pk = DISCOVERABILITY_LEVEL_BY_PK;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);

  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = ['label', 'value', 'description'];

  //data
  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url_create);
      setData(response.data);
      setFetchLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchLoading(false);
    }
  }
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(
    GET_DISCOVERABILITY_LEVELS,
    {
      variables: { limit: pageSize, offset: page * pageSize },
    }
  );
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.discovery_level_aggregate?.count || 0;
  // useEffect(() => {
  // 	fetchData();
  // }, []);
  const [update] = useMutation(UPDATE_DISCOVERY);
  const [create] = useMutation(ADD_DISCOVERY);
  const filteredData = SearchHandler(
    data?.discovery_level ?? [],
    searchValue,
    fieldsToFilterBy
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };
  const [del] = useMutation(DELETE_DISCOVERY);

  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });
      if (data?.delete_discovery_level?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        swal('Success', 'It has been deleted successfully', 'success');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };
  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span className=" ">Discoverability</span>
          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            <button
              className="btn-add-new"
              onClick={() => {
                setSelectedItem(null);
                setMode('add');
                setIsModalOpen(true);
              }}
            >
              <AddIcon />
            </button>
          </div>
        </div>
        {/* table */}
        {fetchLoading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && filteredData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <CustomTable
            filteredData={filteredData}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            passedExcludeColumns={['__typename']}
          />
        )}
        {/* add and edit modal */}
        <ModalHandler
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode={mode}
          size="xl"
          selectedItemId={id}
          selectedItem={selectedItem}
          header="Discoverability"
          url_create={url_create}
          url_by_pk={url_by_pk}
          fetchData={refetch}
          createMutation={create}
          updateMutation={update}
        />
      </div>
    </main>
  );
};

export default Discoverability;
