import { useState } from "react";
import toast from "react-hot-toast";

const useExportAndDownload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const exportAndDownloadFile = async (exportMutation, fileExtension, fileName) => {
    setLoading(true);
    setError(null);

    try {
      // Call the passed GraphQL mutation and pass the file extension dynamically
      const { data } = await exportMutation({
        variables: {
          inputs: {
            extension: fileExtension, // Pass the file extension
          },
        },
      });

      const dynamicKey = Object.keys(data)[0];

      // Extract the base64 file content from the response
      const fileContent = data[dynamicKey]?.data?.[0]?.file_content;
      if (!fileContent) throw new Error("No file content received.");

      // Convert base64 to Blob
      const byteCharacters = atob(fileContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `application/${fileExtension}` });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.${fileExtension}`; // Use provided file name and extension

      // Append link, trigger download, and clean up
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("File downloaded successfully!");

    } catch (err) {
      toast.error("Something went wrong! Please try again.");
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { exportAndDownloadFile, loading, error };
};

export default useExportAndDownload;
