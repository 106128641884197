import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useHandleTheme } from '../hooks/useHandleTheme';

function ThemeController() {
  const { theme, setTheme } = useContext(ThemeContext);
  const { handleDarkTheme, handleLightTheme, darkMode } = useHandleTheme();

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      handleDarkTheme();
    } else {
      setTheme('light');
      handleLightTheme();
    }
  };
  return (
    <span onClick={toggleTheme}>
      {theme === 'light' ? (
        <>
          <DarkModeIcon className="inline-block" /> Switch to Dark
        </>
      ) : (
        <>
          <LightModeIcon className="inline-block" /> Switch to Light
        </>
      )}
    </span>
  );
}
export default ThemeController;
