import { useEffect, useState } from "react";

// Delay the search using debounce
const debounce = (func, delay) => {
	let debounceTimer;
	return function () {
		const context = this;
		const args = arguments;
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => func?.apply(context, args), delay);
	};
};

const searchInNestedObjects = (nestedObject, searchValue, fields) => {
	try {
		// console.log(fields);
		return fields.some((field) => {
			// Skip search on invalid or non-existing fields
			if (!field || !nestedObject?.hasOwnProperty(field)) {
				return false;
			}

			const fieldValue = nestedObject[field];

			

			if (fieldValue !== undefined) {
				if (typeof fieldValue === "string") {
					return fieldValue
						?.toLowerCase()
						?.includes(searchValue?.toLowerCase());
				} else if (Array.isArray(fieldValue)) {
					return fieldValue.some((nestedItem) =>
						searchInNestedObjects(nestedItem, searchValue, fields)
					);
				} else if (typeof fieldValue === "number") {
					return fieldValue === Number(searchValue);
				} else if (typeof fieldValue === "boolean") {
					return (
						(searchValue?.toLowerCase() === "true" && fieldValue) ||
						(searchValue?.toLowerCase() === "false" && !fieldValue)
					);
				}
			}
			return false;
		});
	} catch (error) {
		console.error("Error in searchInNestedObjects:", error);
		return false;
	}
};

const SearchHandler = (data, searchValue, fields, debounceDelay = 300) => {
	const [filteredData, setFilteredData] = useState(data);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		// Check if data is available
		if (!data) {
			// Handle the case where data is not available
			setFilteredData([]);
			return;
		}

		// Debounce the filter function
		const debouncedFilter = debounce(() => {
			try {
				// Normalize search value
				const normalizedSearch = searchValue
					?.trim()
					?.replace(/\s+/g, " ")
					?.toLowerCase();

				// Check if any field is valid
				const hasValidField = fields?.some(
					(field) => field && data?.some((item) => item?.hasOwnProperty(field))
				);

				// Filter data based on search criteria
				const filtered = hasValidField
					? data?.filter((item) =>
							searchInNestedObjects(item, normalizedSearch, fields)
					  )
					: data;

				// Update the filtered data in the state
				// setFilteredData((prevData) => filtered);

				// Using JSON stringify/parse for deep cloning in this context
				setFilteredData(JSON.parse(JSON.stringify(filtered)));
			} catch (error) {
				console.error("Error filtering data:", error);
				setFilteredData([]);
			} finally {
				setIsLoading(false);
			}
		}, debounceDelay);

		// Call the debounced filter function
		debouncedFilter();
	}, [data, searchValue, fields, debounceDelay]);

	const clearSearch = () => {
		setFilteredData(data);
		setIsLoading(false);
	};

	// Return the filtered data
	// return { filteredData, isLoading, clearSearch };
	return filteredData;
};

export default SearchHandler;
