import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { baseURL } from "../../../resources/apiClient";
import http from "../../../resources/http";

const BreachsConfiguration = ({ path, fetchData }) => {
	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 10;
	const pagesVisited = pageNumber * usersPerPage;

	const pageCount = Math.ceil(path?.breaches_count / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const [checkedItems, setCheckedItems] = useState({});

	const handleChange = (event) => {
		setCheckedItems({
			...checkedItems,
			[event.target.name]: event.target.checked,
		});
	};
	return (
		<div>
			<table class="tables ">
				<thead>
					<tr>
						<th>Manage</th>
						<th>Entry</th>
						<th>Middle</th>
						<th>End</th>
					</tr>
				</thead>
				<tbody>
					{path?.paths
						?.filter((types) => types?.type === "breach")
						?.slice(pagesVisited, pagesVisited + usersPerPage)
						.map((items) => {
							const { entry, middle, end, enabled, id } = items;
							return (
								<tr>
									<td
										onClick={() => {
											http
												.patch(
													`${baseURL}/business_process/exploitable-path/${id}`,
													{
														enabled: enabled ? false : true,
													}
												)
												.then((response) => {
													fetchData();
													swal("Success", "Updated Successfully", "success");
												})
												.catch((err) => {
													swal("Error");
												});
										}}
									>
										{" "}
										<input
											type="checkbox"
											name={id}
											checked={enabled}
											onChange={handleChange}
										/>
									</td>
									<td>{entry}</td>
									<td>{middle}</td>
									<td>{end}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<div>
				<ReactPaginate
					previousLabel={"<"}
					nextLabel={">"}
					activeClassName={"paginationActive"}
					pageCount={pageCount}
					onPageChange={changePage}
					containerClassName={"paginationBttns"}
					previousLinkClassName={"previousBttn"}
					nextLinkClassName={"nextBttn"}
					disabledClassName={"paginationDisabled"}
				/>
			</div>
		</div>
	);
};

export default BreachsConfiguration;
