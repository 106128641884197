import React, { useState, useEffect } from 'react';
import http from '../../../resources/http';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import swal from 'sweetalert';
import { ApolloError, useMutation } from '@apollo/client';
import {
  ADD_AVAILABILITY,
  UPDATE_AVAILABILITY,
} from '../../../graphql/superAdmin/mutation/mutation';
import toast from 'react-hot-toast';

const ModalHandler = ({
  isOpen,
  mode,
  onClose,
  size,
  selectedItemId,
  selectedItem,
  header,
  create_object,
  update_object,
  updateMutation,
  createMutation,
  fetchData,
}) => {
  const initialState = {
    difficulty_level: '',
    value: '',
    description: '',
  };
  const [fetchLoading, setFetchLoading] = useState(false);
  const [formData, setFormData] = useState({ initialState });
  const [errors, setErrors] = useState({});

  const validations = {
    difficulty_level: (value) => (value ? '' : 'This field is required.'),
    value: (value) => (value ? '' : 'This field is required.'),
  };

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }
  };

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };

  useEffect(() => {
    if (mode === 'edit' && selectedItem) {
      setFormData(selectedItem);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const formFieldsConfig = {
    difficulty_level: {
      label: 'Difficulty Level',
      type: 'text',
      placeholder: 'Text...',
      value: formData?.difficulty_level,
      onChange: (e) => handleChange('difficulty_level', e.target.value),
      error: errors.difficulty_level,
    },
    value: {
      label: 'Value',
      type: 'number',
      placeholder: 'Number...',
      min: 0,
      value: formData?.value,
      onChange: (e) => handleChange('value', e.target.value),
      error: errors.value,
    },
    description: {
      label: 'Description',
      type: 'textarea',
      maxLength: 400,
      placeholder: 'Text...',
      value: formData?.description,
      onChange: (e) => handleChange('description', e.target.value),
      error: errors.description,
    },
  };

  const handleSubmit = async () => {
    let newErrors = {};
    Object.entries(formFieldsConfig).forEach(([key, config]) => {
      if (validations[key]) newErrors[key] = validations[key](formData[key]);
    });
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      setFetchLoading(true);
      try {
        let response;
        if (mode === 'edit') {
          response = await updateMutation({
            variables: {
              id: parseInt(selectedItemId),
              input: {
                created_at: new Date().toISOString().split('T')[0],
                difficulty_level: formData.difficulty_level,
                value: formData.value,
                description: formData.description,
              },
            },
          });
        } else {
          response = await createMutation({
            variables: {
              inputs: {
                created_at: new Date().toISOString().split('T')[0],
                difficulty_level: formData.difficulty_level,
                value: parseInt(formData.value),
                description: formData.description,
              },
            },
          });
        }

        const dynamicKey = Object.keys(response.data)[0]; // Get the dynamic key
        if (response.data[dynamicKey].affected_rows > 0) {
          toast.success(
            mode === 'edit'
              ? 'It has been updated successfully'
              : 'It has been added successfully'
          );
          resetForm();
          fetchData();
          onClose();
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          const errorMessage = error.message.includes('duplicate key value')
            ? 'The difficulty level already exists. Please choose a different one.'
            : 'An error occurred while processing your request.';
          toast.error(errorMessage);
        } else {
          toast.error('Unexpected error:', error);
          toast.error('An unexpected error occurred.');
        }
      } finally {
        setFetchLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === 'edit' ? `Update  ${header}` : `Add New  ${header}`}
        </Modal.Title>
      </Modal.Header>
      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body>
          <div className="modal-three-columns">
            <Form>
              {Object.entries(formFieldsConfig || {}).map(
                ([key, fieldConfig]) => (
                  <Form.Group key={key}>
                    <Form.Label>{fieldConfig.label}</Form.Label>
                    <Form.Control {...fieldConfig} />
                    {errors[key] && (
                      <Form.Control.Feedback type="invalid">
                        {errors[key]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
              )}
            </Form>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
