export const newsData = [
  {
    source: { id: null, name: "Internet" },
    author: "info@thehackernews.com (The Hacker News)",
    title:
      "Cybersecurity budgets are going up. So why aren't breaches going down?",
    description:
      "Over the past few years, cybersecurity has become a major concern for businesses around the globe. With the total cost of cybercrime in 2023 forecasted to reach $8 Trillion – with a T, not a B – it’s no wonder that cybersecurity is top of mind for leaders acr…",
    url: "https://thehackernews.com/2023/02/cybersecurity-budgets-are-going-up-so.html",
    urlToImage:
      "https://thehackernews.com/new-images/img/b/R29vZ2xl/AVvXsEhUV2j6nSmoy12Pxk2d4sib5g-UFDj4Y5rlBvnBo7lnkQ4wH77tTEemqVPjaXShQx0baCJhmblDlH3MZQGYXvt3nOgQnaa7lVNTpohBqx4NU8l6AthXgvv6cDwprH_U6odQIut2oot2V8ruHnzD59-Q3XkNC_7Ycob6CDuXfYhsGz62FJGLxQnxrPaI/s728-e3650/cyberint.png",
    publishedAt: "2023-02-02T10:04:00Z",
    content:
      "Over the past few years, cybersecurity has become a major concern for businesses around the globe. With the total cost of cybercrime in 2023 forecasted to reach $8 Trillion – with a T, not a B – it's… [+5757 chars]",
  },
  {
    source: { id: null, name: "Forbes" },
    author:
      "Raja Patel, Forbes Councils Member, \n Raja Patel, Forbes Councils Member\n https://www.forbes.com/sites/forbestechcouncil/people/rajapatel/",
    title: "Five Tips For Adopting CSaaS To Reduce Long-Term Cyberattack Risks",
    description:
      "For companies looking to integrate CSaaS into their operations, these tips can help build an internal foundation that supports a streamlined security services relationship.",
    url: "https://www.forbes.com/sites/forbestechcouncil/2023/01/09/5-tips-for-adopting-csaas-to-reduce-long-term-cyberattack-risks/",
    urlToImage:
      "https://imageio.forbes.com/specials-images/imageserve/61095ac215869a022481acb3/0x0.jpg?format=jpg&width=1200",
    publishedAt: "2023-01-09T14:15:00Z",
    content:
      "Senior Vice President, Products, Sophos.\r\ngetty\r\nEven if a security breach does not compromise user data, it still likely results in a loss of consumer trust.\r\nLoss of trust is common in the aftermat… [+5797 chars]",
  },
  {
    source: { id: null, name: "Infosecurity Magazine" },
    author: "Phil Muncaster",
    title: "FinServ Firms See 81% Surge in Attacks Since Russia-Ukraine War",
    description:
      "UK financial services organizations are confident in their cyber defense measures",
    url: "https://www.infosecurity-magazine.com/news/finserv-81-surge-attacks/",
    urlToImage:
      "https://assets.infosecurity-magazine.com/webpage/og/132888fe-86e2-41e9-993e-78fae7b1083d.png",
    publishedAt: "2023-01-18T10:00:00Z",
    content:
      "The UK’s financial services sector has come under a barrage of cyber-attacks since Russia’s invasion of Ukraine, but organizations are largely confident in their ability to mitigate these risks, acco… [+1959 chars]",
  },
  {
    source: { id: "business-insider", name: "Business Insider" },
    author:
      "klong@insider.com (Katherine Long,Jack Newsham,Nick Robins-Early,Madison Hall)",
    title:
      "Attacks on power grids plunged thousands of Americans into darkness, as authorities worry neo-Nazis are targeting critical infrastructure",
    description:
      "Power grids from California to North Carolina have come under attack, concerning authorities that extremists are targeting critical US infrastructure.",
    url: "https://www.businessinsider.com/electrical-power-grid-attack-us-infrastructure-terrorism-2023-1",
    urlToImage:
      "https://i.insider.com/63d93e590a08ae0018a62c76?width=1200&format=jpeg",
    publishedAt: "2023-02-06T15:32:11Z",
    content:
      "On the evening of December 3, the lights went out in Moore County.\r\nFor tens of thousands of central North Carolina residents among the Piedmont hills west of Fayetteville, electric heaters became us… [+18956 chars]",
  },
  {
    source: { id: null, name: "Forbes" },
    author:
      "Vincent Weafer, Forbes Councils Member, \n Vincent Weafer, Forbes Councils Member\n https://www.forbes.com/sites/forbestechcouncil/people/vincentweafer/",
    title:
      "Buttoning Up On The Basics: BEC Is A Simple Yet Precarious Attack Method",
    description:
      "It’s important to understand what BEC is and what makes it such a dangerous threat to your organization’s security.",
    url: "https://www.forbes.com/sites/forbestechcouncil/2023/01/13/buttoning-up-on-the-basics-bec-is-a-simple-yet-precarious-attack-method/",
    urlToImage:
      "https://imageio.forbes.com/specials-images/imageserve/63c00efd31cc9bd7fec92cf2/0x0.jpg?format=jpg&width=1200",
    publishedAt: "2023-01-13T14:30:00Z",
    content:
      "Chief technology officer of Corvus Insurance.\r\ngetty\r\nAs we look toward a new year, security remains a top priority for business leaders. There is a continued level of variability in malicious actors… [+6358 chars]",
  },
  {
    source: { id: null, name: "Cshub.com" },
    author: "Olivia Powell",
    title:
      "Why good email security is essential for every business - Cyber Security Hub",
    description:
      "Understand the most dangerous threats to email security and how to combat them & learn how to gain and keep customer trust through email security efforts.",
    url: "https://www.cshub.com/security-strategy/articles/why-good-email-security-is-essential-for-every-business-2",
    urlToImage:
      "https://eco-cdn.iqpc.com/eco/images/channel_content/images/email_security_excellence_articlez24WMRDd3KtUKznLC0rXT8WSv7jzvFBzpZVz5Vry.jpg",
    publishedAt: "2023-01-16T00:00:27Z",
    content:
      "What is email security?\r\nThreats to email security are on the rise. Research conducted for Cyber Security Hub’s Mid-Year Market Report 2022 found that 75 percent of cyber security practitioners think… [+11701 chars]",
  },

  {
    source: { id: null, name: "Blr.com" },
    author: null,
    title: "New Cybersecurity Regulation Poised to Reshape Business ... - BLR",
    description:
      "As security risks and attacks continue to rise, new regulations are seeking to hold businesses accountable for their cybersecurity protections. An industry expert discusses how companies can adhere to the new requirements.",
    url: "https://totalsecurityadvisor.blr.com/cybersecurity/new-cybersecurity-regulation-poised-to-reshape-business-priorities/",
    urlToImage:
      "https://totalsecurityadvisor.blr.com/app/uploads/sites/7/2023/01/shutterstock_1625495629.jpg",
    publishedAt: "2023-01-26T10:05:44Z",
    content:
      "The new U.S. Securities and Exchange Commission (SEC) rules set to come into play in the spring of 2023 will force listed companies to report their cyberattacks to core stakeholders, such as investor… [+8755 chars]",
  },
  {
    source: { id: null, name: "BetaNews" },
    author: "Ian Barker",
    title:
      "Addressing the challenge of cybersecurity infrastructure fragmentation [Q&A]",
    description:
      "When threat actors evaluate a company's attack surface, they're not thinking in terms of organizational silos. They're probing for the right combination of vulnerabilities, misconfigurations and identity privileges. It follows that security organizations shou…",
    url: "https://betanews.com/2023/01/13/addressing-the-challenge-of-cybersecurity-infrastructure-fragmentation-qa/",
    urlToImage:
      "https://betanews.com/wp-content/uploads/2015/04/network-virus-spread-sprawl-50x50.jpg",
    publishedAt: "2023-01-13T10:10:12Z",
    content:
      "When threat actors evaluate a company's attack surface, they're not thinking in terms of organizational silos. They're probing for the right combination of vulnerabilities, misconfigurations and iden… [+4672 chars]",
  },
  {
    source: { id: null, name: "BetaNews" },
    author: "Ian Barker",
    title:
      "Why focusing on technology spend at the expense of human cyber resources is risky [Q&A]",
    description:
      "As we enter 2023, factors such as an uncertain economy, inflation, the fear of a recession, hiring freezes and layoffs, and supply chain issues continue to take their toll on businesses -- impacting not only daily operations, but budgets for the new year. Whe…",
    url: "https://betanews.com/2023/01/18/why-focusing-on-technology-spend-at-the-expense-of-human-cyber-resources-is-risky-qa/",
    urlToImage:
      "https://betanews.com/wp-content/uploads/2022/03/AI-robot-money-50x50.jpg",
    publishedAt: "2023-01-18T10:10:02Z",
    content:
      "As we enter 2023, factors such as an uncertain economy, inflation, the fear of a recession, hiring freezes and layoffs, and supply chain issues continue to take their toll on businesses -- impacting … [+5566 chars]",
  },
  {
    source: { id: null, name: "Forbes" },
    author:
      "Rabiul Islam, Forbes Councils Member, \n Rabiul Islam, Forbes Councils Member\n https://www.forbes.com/sites/forbestechcouncil/people/rabiulislam/",
    title: "Top 10 Cloud Security Checkpoints That Can Save Your Business",
    description:
      "Protecting your business data from data breaches should be your top priority.",
    url: "https://www.forbes.com/sites/forbestechcouncil/2023/01/25/top-10-cloud-security-checkpoints-that-can-save-your-business/",
    urlToImage:
      "https://imageio.forbes.com/specials-images/imageserve/602fc1a424cbeb39326c6b6f/0x0.jpg?format=jpg&width=1200",
    publishedAt: "2023-01-25T12:45:00Z",
    content:
      "Rabiul Islam is a seasoned cybersecurity specialist. He is also the founder, CEO and managing director of TechForing Ltd.\r\ngetty\r\nAs cloud-based solutions are becoming increasingly popular, more and … [+6843 chars]",
  },
  {
    source: { id: null, name: "Help Net Security" },
    author: "Industry News",
    title:
      "Cloud Range for Critical Infrastructure improves operational efficiency of security teams",
    description:
      "Cloud Range has introduced Cloud Range for Critical Infrastructure—the live-fire simulation training specifically designed to proactively train and prepare incident responders (IR) and security operations (SOC) teams in operational technology (OT) and informa…",
    url: "https://www.helpnetsecurity.com/2023/01/27/cloud-range-for-critical-infrastructure/",
    urlToImage:
      "https://img.helpnetsecurity.com/wp-content/uploads/2021/10/20092552/security_platforma_1200.jpg",
    publishedAt: "2023-01-27T03:10:13Z",
    content:
      "Cloud Range has introduced Cloud Range for Critical Infrastructurethe live-fire simulation training specifically designed to proactively train and prepare incident responders (IR) and security operat… [+7019 chars]",
  },
  {
    source: { id: null, name: "BetaNews" },
    author: "Peter Davidson",
    title: "How does SASE simplify and strengthen cloud security?",
    description:
      "A new data breach is revealed to the public every day. Some of the most recent ones that included large businesses are Mailchimp, LastPass, and T-Mobile. One of the many reasons we increasingly read about such incidents is because it’s getting more and more c…",
    url: "https://betanews.com/2023/01/25/sase-cloud-security/",
    urlToImage:
      "https://betanews.com/wp-content/uploads/2023/01/Cloud-50x50.jpg",
    publishedAt: "2023-01-25T12:44:04Z",
    content:
      "A new data breach is revealed to the public every day. Some of the most recent ones that included large businesses are Mailchimp, LastPass, and T-Mobile. One of the many reasons we increasingly read … [+6518 chars]",
  },
  {
    source: { id: null, name: "Securityweek.com" },
    author: "Kevin Townsend",
    title: "Cyber Insights 2023 | Supply Chain Security",
    description:
      "The supply chain threat is directly linked to attack surface management, but the supply chain must be known and understood before it can be remediated.\nThe post Cyber Insights 2023 | Supply Chain Security appeared first on SecurityWeek.",
    url: "https://www.securityweek.com/cyber-insights-2023-supply-chain-security/",
    urlToImage:
      "https://www.securityweek.com/wp-content/uploads/2023/02/Supply_Chain_Security-Insights.jpg",
    publishedAt: "2023-02-02T12:00:00Z",
    content:
      "About SecurityWeek Cyber Insights |At the end of 2022, SecurityWeek liaised with more than 300 cybersecurity experts from over 100 different organizations to gain insight into the security issues of … [+19357 chars]",
  },
  {
    source: { id: null, name: "Financialexecutives.org" },
    author: null,
    title:
      "In Bracing for Cyber-Attacks, Tech Company Boards Play A Critical ... - FEI Daily",
    description:
      "In Bracing for Cyber-Attacks, Tech Company Boards Play A Critical ...  FEI Daily",
    url: "https://www.financialexecutives.org/FEI-Daily/January-2023/In-Bracing-for-Cyber-Attacks,-Tech-Company-Boards.aspx",
    urlToImage:
      "https://www.financialexecutives.org/getattachment/FEI-Daily/January-2023/In-Bracing-for-Cyber-Attacks,-Tech-Company-Boards-/GettyImages-1370233320.jpg.aspx?lang=en-US&width=727&height=480&ext=.jpg",
    publishedAt: "2023-01-27T01:32:53Z",
    content:
      "Ransomware. Data breaches. Malware. Social hacking. When cyber criminals attack, their victims may pay a hefty price — financially, reputationally and legally. For technology companies, the largest o… [+6957 chars]",
  },
  {
    source: { id: null, name: "Forbes" },
    author:
      "David Carmiel, Forbes Councils Member, \n David Carmiel, Forbes Councils Member\n https://www.forbes.com/sites/forbestechcouncil/people/davidcarmiel/",
    title: "How To Prepare Your Organization For The Future Of Cybercrime",
    description:
      "The future of cybercrime is uncertain, but organizations can help protect themselves from becoming the next victim by preparing for the worst.",
    url: "https://www.forbes.com/sites/forbestechcouncil/2023/02/06/how-to-prepare-your-organization-for-the-future-of-cybercrime/",
    urlToImage:
      "https://imageio.forbes.com/specials-images/imageserve/1200285823/0x0.jpg?format=jpg&width=1200",
    publishedAt: "2023-02-06T12:45:00Z",
    content:
      "CEO of KELA.\r\nGetty\r\nTo be prepared for the future of cybercrime, security teams must remain vigilant, as the threat of malicious actors continues to evolve. Businesses and institutions must understa… [+6554 chars]",
  },
  {
    source: { id: null, name: "Help Net Security" },
    author: "Help Net Security",
    title:
      "Week in review: Critical git vulnerabilities, increasingly malicious Google Search ads",
    description:
      "Here’s an overview of some of last week’s most interesting news, articles, interviews and videos: Cacti servers under attack by attackers exploiting CVE-2022-46169 If you’re running the Cacti network monitoring solution and you haven’t updated it since early …",
    url: "https://www.helpnetsecurity.com/2023/01/22/week-in-review-critical-git-vulnerabilities-increasingly-malicious-google-search-ads/",
    urlToImage:
      "https://img.helpnetsecurity.com/wp-content/uploads/2020/11/16094612/week_in_review1.jpg",
    publishedAt: "2023-01-22T09:30:28Z",
    content:
      "Heres an overview of some of last weeks most interesting news, articles, interviews and videos:\r\nCacti servers under attack by attackers exploiting CVE-2022-46169If youre running the Cacti network mo… [+5056 chars]",
  },
  {
    source: { id: null, name: "Business Wire" },
    author: null,
    title:
      "Cloud Range Launches the First OT/ICS Cyber Range for Live-Fire Training to Reduce Risk from Cyber Attacks on Critical Infrastructure",
    description:
      "NASHVILLE, Tenn.--(BUSINESS WIRE)--Cloud Range, the industry’s leading cyber range solution to reduce cyber risk across the organization, today introduced Cloud Range for Critical Infrastructure—the first-of-its-kind full-service, live-fire simulation trainin…",
    url: "https://www.businesswire.com/news/home/20230125005220/en/Cloud-Range-Launches-the-First-OTICS-Cyber-Range-for-Live-Fire-Training-to-Reduce-Risk-from-Cyber-Attacks-on-Critical-Infrastructure",
    urlToImage:
      "https://mms.businesswire.com/media/20230125005220/en/1694858/23/Cloud_Range_logo_color_RGB_3000.jpg",
    publishedAt: "2023-01-25T14:08:38Z",
    content:
      "NASHVILLE, Tenn.--(BUSINESS WIRE)--Cloud Range, the industrys leading cyber range solution to reduce cyber risk across the organization, today introduced Cloud Range for Critical Infrastructurethe fi… [+8924 chars]",
  },
  {
    source: { id: null, name: "Business Wire" },
    author: null,
    title: "Travelers Institute to Host Cybersecurity Symposium in Phoenix",
    description:
      "PHOENIX--(BUSINESS WIRE)--The Travelers Institute, the public policy division of The Travelers Companies, Inc. (NYSE: TRV), will host an in-person Cyber: Prepare, Prevent, Mitigate, Restore® symposium on Thursday, Jan. 26, in Phoenix. The event, which is part…",
    url: "https://www.businesswire.com/news/home/20230119005175/en/Travelers-Institute-to-Host-Cybersecurity-Symposium-in-Phoenix",
    urlToImage:
      "https://mms.businesswire.com/media/20230119005175/en/1076814/23/TravelersLogo.jpg",
    publishedAt: "2023-01-19T14:09:10Z",
    content:
      "PHOENIX--(BUSINESS WIRE)--The Travelers Institute, the public policy division of The Travelers Companies, Inc. (NYSE: TRV), will host an in-person Cyber: Prepare, Prevent, Mitigate, Restore® symposiu… [+2772 chars]",
  },
  {
    source: { id: null, name: "Dzone.com" },
    author: "Alex Vakulov",
    title: "The Ongoing Battle: Is Time on the Side of Hackers or Defenders?",
    description:
      "After a new CVE (common vulnerabilities and exposures) is made public, cybercriminals often start searching the internet for systems that can be exploited within an hour. However, what happens if the developers of the vulnerable software do not release patche…",
    url: "https://dzone.com/articles/the-ongoing-battle-is-time-on-the-side-of-hackers",
    urlToImage:
      "https://dz2cdn3.dzone.com/storage/article-thumb/16687714-thumb.jpg",
    publishedAt: "2023-02-06T14:58:45Z",
    content:
      "After a new CVE (common vulnerabilities and exposures) is made public, cybercriminals often start searching the internet for systems that can be exploited within an hour. However, what happens if the… [+6173 chars]",
  },
  {
    source: { id: null, name: "Malwarebytes.com" },
    author: "/blog/authors/cboyd",
    title: "Multiple schools hit by Vice Society ransomware attack",
    description:
      "<table><tr><td>Categories: News\r\nTags: ransomware\r\n\nTags: high society\r\n\nTags: compromise\r\n\nTags: school\r\n\nTags: schools\r\n\nTags: learning\r\n\nTags: documents\r\n\nTags: data\r\n\nTags: leak\r\n\nWe take a look at reports of 14 schools being compromised by ransomware gro…",
    url: "https://www.malwarebytes.com/blog/news/2023/01/multiple-schools-in-uk-hit-by-vice-society-ransomware-attack",
    urlToImage:
      "https://www.malwarebytes.com/blog/news/2023/01/asset_upload_file47502_253950.jpg",
    publishedAt: "2023-01-15T18:15:00Z",
    content:
      "The real world impact of cybercrime rears its head once more, with word that 14 schools in the UK have been caught out by ransomware. The schools, attacked by the group known as Vice Society, have ha… [+5758 chars]",
  },
  {
    source: { id: null, name: "Technewsworld.com" },
    author: "TechNewsWorld",
    title:
      "Cyber Forecast for 2023 and Beyond: Hang on for a Bumpy Digital Ride",
    description:
      "2023 might be a volatile year for cybersecurity officers as they deal with the pressures of maintaining a ridged security posture while also dodging the bullet of blame when attacks are successful. The post Cyber Forecast for 2023 and Beyond: Hang on for a Bu…",
    url: "https://www.technewsworld.com/story/cyber-forecast-for-2023-and-beyond-hang-on-for-a-bumpy-digital-ride-177752.html",
    urlToImage:
      "https://www.technewsworld.com/wp-content/uploads/sites/3/2023/01/IT-team.jpg",
    publishedAt: "2023-02-03T13:00:45Z",
    content:
      "The year 2023 offers lots of promise and a whole lot of insecurity on the digital pathways — both for personal and business encounters.\r\nTechnology continues to bring innovative business solutions wh… [+8430 chars]",
  },
  {
    source: { id: null, name: "Securityweek.com" },
    author: "Kevin Townsend",
    title: "Cyber Insights 2023: Ransomware",
    description:
      "The changing nature of what we still generally call ransomware will continue through 2023, driven by three primary conditions.\nThe post Cyber Insights 2023: Ransomware appeared first on SecurityWeek.",
    url: "https://www.securityweek.com/cyber-insights-2023-ransomware/",
    urlToImage:
      "https://www.securityweek.com/wp-content/uploads/2023/02/Ransomware-Information-2023.jpg",
    publishedAt: "2023-02-02T12:00:00Z",
    content:
      "About SecurityWeek Cyber Insights |At the end of 2022, SecurityWeek liaised with more than 300 cybersecurity experts from over 100 different organizations to gain insight into the security issues of … [+18593 chars]",
  },
  {
    source: { id: null, name: "Business Wire" },
    author: null,
    title:
      "Allianz Risk Barometer 2023: Cyber and Business Interruption Top Threats as Economic and Energy Risks Rise",
    description:
      "NEW YORK--(BUSINESS WIRE)-- #AGCS--Allianz Global Corporate & Specialty Reveals Top 2023 Global Business Risks in its annual Risk Barometer",
    url: "https://www.businesswire.com/news/home/20230117005300/en/Allianz-Risk-Barometer-2023-Cyber-and-Business-Interruption-Top-Threats-as-Economic-and-Energy-Risks-Rise",
    urlToImage:
      "https://mms.businesswire.com/media/20230117005300/en/739121/23/Allianz_Logo_-_Blue.jpg",
    publishedAt: "2023-01-17T12:55:01Z",
    content:
      "NEW YORK--(BUSINESS WIRE)--It is both stability and change in the Allianz Risk Barometer 2023. Cyber incidents and Business interruption rank as the biggest company concerns for the second year in su… [+10322 chars]",
  },
  {
    source: { id: null, name: "Cisco.com" },
    author: "Richard Archdeacon",
    title: "Nine Top of Mind Issues for CISOs Going Into 2023",
    description:
      "Here are the topics that Richard Archdeacon, Advisory CISO at Cisco thinks will be top of mind in 2023, and what CISOs can do to prepare.",
    url: "https://blogs.cisco.com/security/nine-top-of-mind-issues-for-cisos-going-into-2023",
    urlToImage:
      "https://storage.googleapis.com/blogs-images/ciscoblogs/1/2022/12/Screenshot-2022-12-12-at-10.02.57-AM-1024x523.png",
    publishedAt: "2023-01-10T10:58:56Z",
    content:
      "As the majority of the global Covid fog finally started lifting in 2022, other events and their associated risks started to fill the headspace of C-level execs the world over. In my role, I regularly… [+11136 chars]",
  },
  {
    source: { id: null, name: "Insurance Journal" },
    author: "admin",
    title:
      "Cyber and Business Interruption Top 2023 Threats: Allianz Risk Barometer",
    description:
      "According to Allianz’s Risk Barometer 2023, cyber incidents and business interruption rank as the biggest company concerns for the second year in a row. At the same time, macroeconomic developments such as inflation, financial market volatility and a looming …",
    url: "https://www.insurancejournal.com/news/national/2023/01/18/703465.htm",
    urlToImage:
      "https://www.insurancejournal.com/app/uploads/2016/01/risk-management.jpg",
    publishedAt: "2023-01-18T14:08:33Z",
    content:
      "New You can now listen to Insurance Journal articles!According to Allianz’s Risk Barometer 2023, cyber incidents and business interruption rank as the biggest company concerns for the second year in … [+8893 chars]",
  },
  {
    source: { id: null, name: "Tenable.com" },
    author: "Juan Perez",
    title:
      "Cybersecurity Snapshot: U.S. Gov’t Turns Up Heat on Breach Notifications, While Cyber Concerns Still Hamper Cloud Value",
    description:
      "Learn all about the FCC’s plan to accelerate telecom breach reports. Plus, why cyber worries remain a cloud obstacle. Also, find out how to rate your cloud MSP’s cybersecurity strength. Then check out our ad-hoc poll on cybersecurity. And much more!\n\nDive int…",
    url: "https://www.tenable.com/blog/cybersecurity-snapshot-u-s-govt-turns-up-heat-on-breach-notifications-while-cyber-concerns",
    urlToImage:
      "https://www.tenable.com/sites/default/files/styles/640x360/public/images/articles/U.S.%20Government%20Turns%20Up%20Heat%20on%20Breach%20Notifications%2C%20While%20Cyber%20Concerns%20Still%20Hamper%20Cloud%20Value.jpg?itok=ZhmFlCUR",
    publishedAt: "2023-01-13T11:00:00Z",
    content:
      "Learn all about the FCCs plan to accelerate telecom breach reports. Plus, why cyber worries remain a cloud obstacle. Also, find out how to rate your cloud MSPs cybersecurity strength. Then check out … [+7753 chars]",
  },
  {
    source: { id: null, name: "Security Boulevard" },
    author: "Sandeep Lota",
    title: "A Comprehensive Guide to IoT Security - Security Boulevard",
    description:
      "As digital transformation fuels the proliferation of IoT devices across industrial environments, having a strong IoT security program in place has become vital to protect critical infrastructure from cyberattacks. Table of Contents: What Is IoT Security? The …",
    url: "https://securityboulevard.com/2023/01/a-comprehensive-guide-to-iot-security/",
    urlToImage:
      "https://www.nozominetworks.com/wp-content/uploads/2023/01/IoT-Flowchart-1024x484.jpg",
    publishedAt: "2023-01-19T03:53:49Z",
    content:
      "As digital transformation fuels the proliferation of IoT devices across industrial environments, having a strong IoT security program in place has become vital to protect critical infrastructure from… [+11459 chars]",
  },
  {
    source: { id: null, name: "Trendmicro.com" },
    author: "Jon Clay",
    title: "Ransomware Recovery Plan for 2023",
    description:
      "It’s important to defend against ransomware attacks, but is your organization prepared to deal with the consequences of a breach? Find out how to plan an effective ransomware recovery strategy.",
    url: "https://www.trendmicro.com/en_us/ciso/23/a/ransomware-recovery-plan.html",
    urlToImage:
      "https://www.trendmicro.com/content/dam/trendmicro/global/en/ciso/thumbnails/23/ransomware-recovery-plan.png",
    publishedAt: "2023-01-24T00:00:00Z",
    content:
      "Ransomware continues to be a significant global threat for organizations in all sectors. In 2022, it accounted for 41% of breaches, with an average cost per breach of $4.5 million. While authorities … [+6340 chars]",
  },
  {
    source: { id: null, name: "Business Wire" },
    author: null,
    title:
      "Exabeam Survey Finds Organizations Prioritize Prevention Over Detection While Breaches Continue to Rise",
    description:
      "FOSTER CITY, Calif.--(BUSINESS WIRE)--Research shows the inability to prevent bad things from happening as the worst part of a security job.",
    url: "https://www.businesswire.com/news/home/20230131005315/en/Exabeam-Survey-Finds-Organizations-Prioritize-Prevention-Over-Detection-While-Breaches-Continue-to-Rise",
    urlToImage:
      "https://mms.businesswire.com/media/20230131005315/en/1700166/23/Exabeam_Black_Logo_%281%29.jpg",
    publishedAt: "2023-01-31T14:09:52Z",
    content:
      "FOSTER CITY, Calif.--(BUSINESS WIRE)--Exabeam, a global cybersecurity leader and creator of New-Scale SIEM for advancing security operations, today announced its State of the SIEM survey of 500 U.S. … [+6668 chars]",
  },
  {
    source: { id: null, name: "Yahoo Entertainment" },
    author: "Check Point Software Technologies INC",
    title:
      "December 2022’s Most Wanted Malware: Glupteba Entering Top Ten and Qbot in First Place",
    description:
      "Check Point Research reports that Glupteba has returned to the top ten list for the first time since July 2022. Qbot overtook Emotet as the most prevalent...",
    url: "https://finance.yahoo.com/news/december-2022-most-wanted-malware-110000673.html",
    urlToImage:
      "https://media.zenfs.com/en/globenewswire.com/52da43d451b467d02998b2b8cb21dd88",
    publishedAt: "2023-01-13T11:00:00Z",
    content:
      "Check Point Software Technologies INC\r\nCheck Point Research reports that Glupteba has returned to the top ten list for the first time since July 2022. Qbot overtook Emotet as the most prevalent malwa… [+8925 chars]",
  },
  {
    source: { id: null, name: "Petri.com" },
    author: "Mustafa Toroman",
    title:
      "What is Microsoft Sentinel and How Does It Protect Cloud and On-Premises Resources?",
    description:
      "Microsoft Sentinel, which was previously known as Azure Sentinel, is a Security Information and Event Management (SIEM) solution for Azure and Microsoft 365. In this article, we explain how Microsoft Sentinel works and how it can help organizations to protect…",
    url: "https://petri.com/what-is-microsoft-sentinel/",
    urlToImage: "https://petri-media.s3.amazonaws.com/2022/02/Security_02.jpg",
    publishedAt: "2023-02-02T14:00:00Z",
    content:
      "Microsoft Sentinel, which was previously known as Azure Sentinel, is a Security Information and Event Management (SIEM) solution for Azure and Microsoft 365. Just like other SIEM solutions, it combin… [+9285 chars]",
  },
  {
    source: { id: null, name: "Csoonline.com" },
    author: "Brand Post",
    title:
      "BrandPost: Is Your Organization Security Resilient? Here’s How to Get There",
    description:
      "Security resilience is top of mind for the vast majority of executives; 96% say its highly important to their business, according to the Cisco Security Outcomes Report, Volume 3.\r\nAnd with good reason: data breaches, ransomware, and other cyberattacks continu…",
    url: "https://www.csoonline.com/article/3686476/is-your-organization-security-resilient-here-s-how-to-get-there.html",
    urlToImage:
      "https://images.idgesg.net/images/article/2023/01/cso_1200x800_photowhysecuritybigdeal-100936962-large.jpg?auto=webp&quality=85,70",
    publishedAt: "2023-01-31T16:04:00Z",
    content:
      "Security resilience is top of mind for the vast majority of executives; 96% say its highly important to their business, according to the Cisco Security Outcomes Report, Volume 3.\r\nAnd with good reaso… [+3340 chars]",
  },
  {
    source: { id: null, name: "Tenable.com" },
    author: "Juan Perez",
    title: "Google: Monitor These Emerging Cloud Security Challenges in 2023",
    description:
      "A new report from Google outlines a set of evolving threats that cloud security teams should keep an eye on in the new year.\n\nIn its latest “Threat Horizons” report, Google’s Cybersecurity Action Team details cloud security trends that it expects will have an…",
    url: "https://www.tenable.com/blog/google-monitor-these-emerging-cloud-security-challenges-in-2023",
    urlToImage:
      "https://www.tenable.com/sites/default/files/styles/640x360/public/images/articles/AdobeStock_194985867%20%281%29.jpeg?itok=5jWLTBS_",
    publishedAt: "2023-01-12T14:00:00Z",
    content:
      "A new report from Google outlines a set of evolving threats that cloud security teams should keep an eye on in the new year.\r\nIn its latest Threat Horizons report, Googles Cybersecurity Action Team d… [+4982 chars]",
  },
  {
    source: { id: null, name: "Csoonline.com" },
    author: "Rosalyn Page",
    title: "How CISOs can manage the cybersecurity of high-level executives",
    description:
      "High-level executives, including board members and C-level executives, often have access to sensitive information, making them prime targets for bad actors looking to penetrate corporate defenses. Their personal devices, among other points of entry, are glari…",
    url: "https://www.csoonline.com/article/3685415/how-cisos-can-manage-the-security-of-high-level-executives.html",
    urlToImage:
      "https://images.idgesg.net/images/article/2023/01/shutterstock_1808484295_board_meeting_security-100936365-large.jpg?auto=webp&quality=85,70",
    publishedAt: "2023-01-19T10:00:00Z",
    content:
      "High-level executives, including board members and C-level executives, often have access to sensitive information, making them prime targets for bad actors looking to penetrate corporate defenses. Th… [+9843 chars]",
  },
  {
    source: { id: null, name: "Marketscreener.com" },
    author: "MarketScreener",
    title: "Travelers Institute to Host Cybersecurity Symposium in Phoenix",
    description:
      "(marketscreener.com) \nThe Travelers Institute, the public policy division of The Travelers Companies, Inc. , will host an in-person Cyber: Prepare, Prevent, Mitigate, Restore® symposium on Thursday, Jan. 26, in Phoenix. The event, which is part of a national …",
    url: "https://www.marketscreener.com/quote/stock/THE-TRAVELERS-COMPANIES-14449/news/Travelers-Institute-to-Host-Cybersecurity-Symposium-in-Phoenix-42767973/?utm_medium=RSS&utm_content=20230119",
    urlToImage: "https://www.marketscreener.com/images/twitter_MS_fdgris.png",
    publishedAt: "2023-01-19T14:05:02Z",
    content:
      "The Travelers Institute, the public policy division of The Travelers Companies, Inc. (NYSE: TRV), will host an in-person Cyber: Prepare, Prevent, Mitigate, Restore® symposium on Thursday, Jan. 26, in… [+2864 chars]",
  },
  {
    source: { id: null, name: "ComputerWeekly.com" },
    author: "Daniel Hofmann",
    title: "Tips on improving cyber training for home workers",
    description:
      "How better security training can help firms tackle new cyber threats facing remote workers",
    url: "https://www.computerweekly.com/opinion/Tips-on-improving-cyber-training-for-home-workers",
    urlToImage:
      "https://cdn.ttgtmedia.com/visuals/ComputerWeekly/Hero Images/careers-training-skills-2-fotolia.jpg",
    publishedAt: "2023-02-01T05:06:00Z",
    content:
      "The pandemic accelerated the use of technology in business, causing many organisations to have greater reliance on email, chat applications and virtual meeting software. As a result, remote working s… [+4079 chars]",
  },
  {
    source: { id: null, name: "Lawfare" },
    author: "jhealey",
    title: "The Lingering Power of Cyber Brandishing",
    description:
      "General James E. Cartwright (https://commons.wikimedia.org/wiki/File:USMC-08749.jpg, Public Domain)\r\n\n\r\n\n\r\nAcademics are among those who have oversimplified that “brandishing a cyber capability for signaling purposes is counterproductive when, by the very act…",
    url: "https://www.lawfareblog.com/lingering-power-cyber-brandishing",
    urlToImage:
      "https://www.lawfareblog.com/sites/default/files/USMC-08749.jpeg",
    publishedAt: "2023-01-18T13:31:17Z",
    content:
      "Academics are among those who have oversimplified that brandishing a cyber capability for signaling purposes is counterproductive when, by the very act of revealing it, the capability can be rendered… [+10096 chars]",
  },
  {
    source: { id: null, name: "Opsera.io" },
    author: "Gilbert Martin",
    title: "9 Ways You Can Improve Security Posture",
    description:
      "Cyber threats are growing exponentially, both in volume and sophistication. A major portion of these threats can go unidentified, or they are detected too late for an organization to avoid data breaches or other related risks. The tangible costs of any cyber …",
    url: "https://www.opsera.io/blog/9-ways-you-can-improve-security-posture",
    urlToImage:
      "https://dz2cdn1.dzone.com/storage/article-thumb/16675292-thumb.jpg",
    publishedAt: "2023-02-02T15:18:22Z",
    content:
      "Cyber threats are growing exponentially, both in volume and sophistication. A major portion of these threats can go unidentified, or they are detected too late for an organization to avoid data breac… [+14485 chars]",
  },
  {
    source: { id: null, name: "Techtarget.com" },
    author: "Sean Michael Kerner",
    title: "34 cybersecurity statistics to lose sleep over in 2023",
    description:
      "Here are 34 eye-opening cybersecurity stats from dozens of security experts -- on crime, jobs and trends -- to chew on while developing your 2023 security plan.",
    url: "https://www.techtarget.com/whatis/34-Cybersecurity-Statistics-to-Lose-Sleep-Over-in-2020",
    urlToImage:
      "https://cdn.ttgtmedia.com/ITKE/images/logos/TTlogo-379x201.png",
    publishedAt: "2023-01-26T00:00:00Z",
    content:
      "Hackers and data miners continue to become more sophisticated, malicious and just plain greedy. Even the general public has become aware of security threats and incidents that splash across news head… [+13312 chars]",
  },
  {
    source: { id: null, name: "GlobeNewswire" },
    author: "Check Point Software Technologies INC",
    title:
      "December 2022’s Most Wanted Malware: Glupteba Entering Top Ten and Qbot in First Place",
    description:
      "Check Point Research reports that Glupteba has returned to the top ten list for the first time since July 2022. Qbot overtook Emotet as the most prevalent malware in December, while android malware Hiddad made a comeback Check Point Research reports that Glup…",
    url: "https://www.globenewswire.com/news-release/2023/01/13/2588443/0/en/December-2022-s-Most-Wanted-Malware-Glupteba-Entering-Top-Ten-and-Qbot-in-First-Place.html",
    urlToImage:
      "https://ml.globenewswire.com/Resource/Download/c512f8ea-24a4-4802-9285-0434d241f6ea?size=1",
    publishedAt: "2023-01-13T11:00:00Z",
    content:
      "SAN CARLOS, Calif., Jan. 13, 2023 (GLOBE NEWSWIRE) -- Check Point® Software Technologies Ltd. (NASDAQ: CHKP), a leading provider of cybersecurity solutions globally, has published its Global Threat I… [+8644 chars]",
  },
  {
    source: { id: null, name: "PRNewswire" },
    author: null,
    title:
      "Release Correction: New Total Economic Impact™ Study Reveals JupiterOne Delivered 332% Return on Investment",
    description:
      "Increased security operations and compliance efficiency and reduced security risk saved customers $3.99M over three years MORRISVILLE, N.C., Jan. 26, 2023 /PRNewswire/ -- JupiterOneⓇ, the industry's leading cyber asset attack surface management (CAASM) platfo…",
    url: "https://www.prnewswire.com/news-releases/release-correction-new-total-economic-impact-study-reveals-jupiterone-delivered-332-return-on-investment-301731354.html",
    urlToImage:
      "https://mma.prnewswire.com/media/1492212/JupiterOne_Logo.jpg?p=facebook",
    publishedAt: "2023-01-26T14:00:00Z",
    content:
      "Increased security operations and compliance efficiency and reduced security risk saved customers $3.99M over three years \r\nMORRISVILLE, N.C., Jan. 26, 2023 /PRNewswire/ -- JupiterOne\r\n, the industry… [+7195 chars]",
  },
  {
    source: { id: null, name: "WebProNews" },
    author: "Brian Wallace",
    title: "Lessons From the Latest Cyber Incidents",
    description:
      "WebProNews\nLessons From the Latest Cyber Incidents\nRoyal Mail ransomware and LastPass data breaches have been some of the latest major hacking cases. How can endpoint security and anti-ransomware solutions prevent such incidents?\nLessons From the Latest Cyber…",
    url: "https://www.webpronews.com/cyber-incidents-lessons/",
    urlToImage:
      "https://www.webpronews.com/wp-content/uploads/2023/01/pexels-tima-miroshnichenko-5380664.jpg",
    publishedAt: "2023-01-26T11:27:33Z",
    content:
      "The LastPass data breach. Ransomware on The Guardian and Royal Mail. Hackers exploiting the platform CircleCI with zero-day malware.\r\nJanuary is not even over and major hacking incidents or the after… [+6085 chars]",
  },
  {
    source: { id: null, name: "Techtarget.com" },
    author: "Alexis Zacharakos",
    title: "Schools don't pay, but ransomware attacks still increasing",
    description:
      "Ransomware gangs have increasingly focused their attacks on the K-12 education sector, even though most school districts do not pay the ransom. But how long will that last?",
    url: "https://www.techtarget.com/searchsecurity/news/365529754/Schools-dont-pay-but-ransomware-attacks-still-increasing",
    urlToImage:
      "https://cdn.ttgtmedia.com/rms/onlineimages/books_g675971346.jpg",
    publishedAt: "2023-01-30T10:24:00Z",
    content:
      "Ransomware gangs continued to attack K-12 schools in 2022 despite the low probability of collecting a ransom payment.\r\nWhile the total number of ransomware cases is unknown, reports reveal that ranso… [+8408 chars]",
  },
  {
    source: { id: null, name: "Vmblog.com" },
    author: "David",
    title:
      "Miscommunications in IT security lead to cybersecurity incidents in 80% of U.S. companies",
    description:
      "According to Kaspersky, 80% of top-tier managers in the U.S. admit that a miscommunication with the IT department or IT security team has resulted in at least one cybersecurity incident in their organizations.",
    url: "https://vmblog.com:443/archive/2023/01/11/miscommunications-in-it-security-lead-to-cybersecurity-incidents-in-80-of-u-s-companies.aspx",
    urlToImage: null,
    publishedAt: "2023-01-11T19:42:00Z",
    content:
      "According to a new report from\r\nKaspersky, 80% of top-tier managers in the U.S. admit that a miscommunication\r\nwith the IT department or IT security team has resulted in at least one\r\ncybersecurity i… [+3779 chars]",
  },
  {
    source: { id: null, name: "Ifsecglobal.com" },
    author: "IFSEC Global, Staff",
    title:
      "Securing the World's Energy Systems: Where Physical Security and Cybersecurity Must Meet",
    description:
      "Energy has become the new battleground for both physical and cyber security warfare, driven by nation state actors, increasing financial rewards for ransomware gangs and decentralized devices. Chris Price reports.",
    url: "https://www.ifsecglobal.com/borders-infrastructure/securing-the-worlds-energy-systems-where-physical-and-cyber-security-must-meet/",
    urlToImage:
      "https://eu-images.contentstack.com/v3/assets/blt66983808af36a8ef/blt43ead4d6d652ff3f/63bedde19168c607a56347b8/Power_Ken_Gillespie_Photography_Alamy.jpg",
    publishedAt: "2023-01-12T15:00:00Z",
    content:
      "The physical threat to the world's critical national infrastructure (CNI) has never been greater. At least 50 meters of the Nord Stream 1 and 2 underground pipelines that once transported Russian gas… [+10882 chars]",
  },
  {
    source: { id: null, name: "Dark Reading" },
    author: "Microsoft Security, Microsoft",
    title: "CISOs Are Focused on These 3 Trends. Are You?",
    description:
      "The macro issues shaping the threat landscape can help security pros reset their priorities and reformulate strategy.",
    url: "https://www.darkreading.com/microsoft/cisos-are-focused-on-these-3-trends-are-you-",
    urlToImage:
      "https://eu-images.contentstack.com/v3/assets/blt66983808af36a8ef/blt0b9052c762a7c66f/63b8a89ff4a71125918806a2/magnifyingglass_Brain_light_Alamy_Stock_Photo.jpg",
    publishedAt: "2023-01-06T19:02:34Z",
    content:
      "As remote work grows, many organizations are managing a complex web of in-person, online, and hybrid work scenarios while also juggling cloud migration to support their diversified workforce. For CIS… [+4664 chars]",
  },
  {
    source: { id: null, name: "Vmblog.com" },
    author: "David",
    title: "Data Privacy Day 2023: Tips and Views from Top Industry Experts",
    description:
      "VMblog has compiled some detailed perspectives, as well as some tips for better protection of sensitive corporate data, from a few industry experts ahead of Data Privacy Day 2023.",
    url: "https://vmblog.com:443/archive/2023/01/26/data-privacy-day-2023-tips-and-views-from-top-industry-experts.aspx",
    urlToImage: null,
    publishedAt: "2023-01-26T13:30:00Z",
    content:
      'Data\r\nPrivacy Day, an international "holiday" that occurs each year on January\r\n28, was created to raise awareness and promote privacy and data protection best\r\npractices. Data Privacy Day began in t… [+52629 chars]',
  },
  {
    source: { id: null, name: "Dark Reading" },
    author:
      "Adam Isles, Principal and Head of Cybersecurity Practice, The Chertoff Group",
    title:
      "Optimizing Cybersecurity Investments in a Constrained Spending Environment",
    description: "Three ways to stay safe in an economically uncertain 2023.",
    url: "https://www.darkreading.com/operations/optimizing-cybersecurity-investments-in-a-constrained-spending-environment",
    urlToImage:
      "https://eu-images.contentstack.com/v3/assets/blt66983808af36a8ef/bltaf066d42d234b5f6/63dd511a0b3eae53e4a21792/Pig_dice_concept_CalypsoArt_Alamy.jpg",
    publishedAt: "2023-02-07T15:00:00Z",
    content:
      "Last month, a Wall Street Journal article highlighted how chief information security officers (CISOs) will increasingly see budgets constrained based on the growing economic uncertainty facing compan… [+6320 chars]",
  },
  {
    source: { id: null, name: "Barron's" },
    author: "Glenn S. Gerstell",
    title:
      "Biden’s New Cyber Strategy Will Acknowledge an Essential Truth: Market Forces Aren’t Enough",
    description:
      "The Biden administration is expected to announce a new Cyber Strategy soon. It will call for major change, Glenn S. Gerstell writes.",
    url: "https://www.barrons.com/articles/biden-new-cyber-strategy-market-forces-cybersecurity-51675459082",
    urlToImage: "https://images.barrons.com/im-716719/social",
    publishedAt: "2023-02-06T09:00:00Z",
    content:
      "About the author: Glenn S. Gerstell served as the general counsel of the National Security Agency from 2015 to 2020 and is currently a senior adviser at the Center for Strategic &amp; International S… [+11667 chars]",
  },
  {
    source: { id: null, name: "Marketscreener.com" },
    author: "MarketScreener",
    title: "TAID ACQU : RISK FACTORS - Form 8-K",
    description:
      "(marketscreener.com) \n \n RISK FACTORS\n \n \n Capitalized terms used but not defined in this Exhibit 99.1 shall have the meanings ascribed to them in the Current Report on Form 8-K to which this exhibit is attached and, if not defined in the Form 8-K, the defini…",
    url: "https://www.marketscreener.com/quote/stock/TAID-ACQU-114201732/news/TAID-ACQU-RISK-FACTORS-Form-8-K-42912520/?utm_medium=RSS&utm_content=20230206",
    urlToImage: "https://www.marketscreener.com/images/twitter_MS_fdgris.png",
    publishedAt: "2023-02-06T21:10:42Z",
    content:
      'RISK FACTORS\r\nCapitalized terms used but not defined in this Exhibit 99.1 shall have the meanings ascribed to them in the Current Report on Form 8-K (the "Form 8-K") to which this exhibit is attached… [+213841 chars]',
  },
  {
    source: { id: "the-washington-post", name: "The Washington Post" },
    author: "Tim Starks",
    title:
      "The latest cyberattack on health care shows how vulnerable the sector is",
    description:
      "The latest cyberattack on health care shows how vulnerable the sector iswashingtonpost.com",
    url: "https://www.washingtonpost.com/politics/2023/01/23/latest-cyberattack-health-care-shows-how-vulnerable-sector-is/",
    urlToImage:
      "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/MHCZTUQCYYI63C7LFNHEQGYVAA.jpg&w=1440",
    publishedAt: "2023-01-23T12:17:11Z",
    content:
      "Comment on this story\r\nWelcome to The Cybersecurity 202! Aubrey Plaza is a national treasure.\r\nBelow: Cybercriminals stole more than $500,000 from a senators campaign committee, and T-Mobile is again… [+9590 chars]",
  },
];
