import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import swal from 'sweetalert';
import { Button, Modal, Form } from 'react-bootstrap';
import { baseURL } from '../../../resources/apiClient';
import http from '../../../resources/http';
import { downloadExcelData } from '../../../utils/ExportExcelUtility';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import { downloadReports } from './../../../utils/ExportExcelUtility';

const CompanyReports = ({ selectedScores }) => {
  const [report, setReport] = useState([]);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const pageCount = Math.ceil(report?.entry?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //update fields
  const [id, setId] = useState();
  const [industry_Sector, setIndustrySector] = useState();
  const [frequency_of_occurrence, setFrequencyOfOccurrence] = useState('');
  const [success_rate, setSuccessRate] = useState('');
  const [total_breaches, setTotalBreaches] = useState('');
  const [total_cost_of_data_breach, setTotalCostOfDataBreach] = useState('');
  const [total_incidents, setTotalIncidents] = useState('');
  const [year, setYear] = useState('');
  const domain = useSelector((state) => state.domain);

  const [showHideMe, setSetShowHideMe] = useState(false);
  const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);
  const [selectedScore, setSelectedScore] = useState(100);
  const [loading, setLoading] = useState(false);
  const fetchData = () => {
    setLoading(true);
    http
      .get(`${baseURL}/excel-upload/industry-data-list/`)
      .then((response) => {
        setData(response.data);
      })
      .then()
      .catch((err) => {
        swal(
          'Error',
          'There Is No Data to Display at The Moment, Please Upload More industry Data',
          'No Data'
        );
      });
    http
      .get(
        `${baseURL}/business_process/security-score-card-plan-by-target/${domain}/${selectedScores}`
      )
      .then((response) => {
        setReport(response.data);
        setLoading(false);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      )
      .catch((err) => {
        return false;
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(report, 'reports');

  const DisplayData = report.entry
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((asset) => {
      return (
        <tr>
          <td>{asset.factor?.replace(/_/g, ' ')}</td>
          <td>{asset?.findings}</td>
          <td>{asset.issue_type?.replace(/_/g, ' ')}</td>
          <td>{asset.remediations}</td>
          <td>{asset.severity}</td>
          <td>{asset.title}</td>
        </tr>
      );
    });
  return (
    <div className="">
      <div className="mt-6 flex space-x-10 justify-between asset-end text-lg">
        <div className="flex space-x-2 justify-center items-center">
          <p>Projected Score:</p>
          <p className="color_octa text-3xl">{selectedScores}</p>
          <img
            className="h-14 w-14 fill-blue-500"
            alt="grade-url"
            src={
              selectedScores > 89 && selectedScores <= 100
                ? 'https://s3.amazonaws.com/ssc-static/grades/factor_a.svg'
                : selectedScores > 79 && selectedScores <= 89
                ? 'https://s3.amazonaws.com/ssc-static/grades/factor_b.svg'
                : selectedScores > 69 && selectedScores <= 79
                ? 'https://s3.amazonaws.com/ssc-static/grades/factor_c.svg'
                : selectedScores > 59 && selectedScores <= 69
                ? 'https://s3.amazonaws.com/ssc-static/grades/factor_d.svg'
                : 'https://s3.amazonaws.com/ssc-static/grades/factor_f.svg'
            }
          />
        </div>

        <div className="flex justify-end items-end mb-8">
          <Button
            variant="outline-warning"
            className="flex justify-end items-end cmbutton"
            onClick={() => downloadReports(report.entry, 'Reports')}
          >
            Export As Excel
          </Button>
        </div>
      </div>
      <div className="flex flex-col text-black  pt-3 ">
        <div className="rounded-md shadow overflow-auto ">
          <table class="tables rounded-md overflow-hidden ">
            <thead className="sticky-header">
              <tr>
                <th className="text-center">Factor</th>
                <th className="text-center">Findings</th>
                <th className="text-center">Issue Type</th>
                <th className="text-center">Remediations</th>
                <th className="text-center">Severity</th>
                <th className="text-center">Title</th>
              </tr>
            </thead>
            <tbody>{DisplayData}</tbody>
          </table>
        </div>
        {loading ? <LoadingSpinner /> : null}
        <div className="mt-10 flex flex-col justify-center items-center">
          {report?.entry?.length > 10 ? (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              activeClassName={'paginationActive'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
            />
          ) : null}
        </div>
      </div>
      <Modal show={showHideMe}>
        <Modal.Header onClick={() => setSetShowHideMe()}>
          <Modal.Title>Edit Industry Data Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-modal flex flex-col space-y-2 ">
            <Form.Group class="mb-6 Year">
              <Form.Label
                for="text"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Year
              </Form.Label>
              <Form.Control
                type="text"
                id="text"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                name="text"
              ></Form.Control>
            </Form.Group>
            <Form.Group class="mb-6 Total-Incidents">
              <Form.Label
                for="text"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Total Incidents
              </Form.Label>
              <Form.Control
                type="text"
                id="text"
                value={total_incidents}
                onChange={(e) => setTotalIncidents(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                name="text"
              ></Form.Control>
            </Form.Group>
            <Form.Group class="mb-6 Total-Breaches">
              <Form.Label
                for="text"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Total Breaches
              </Form.Label>
              <Form.Control
                type="text"
                id="text"
                value={total_breaches}
                onChange={(e) => setTotalBreaches(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                name="text"
              ></Form.Control>
            </Form.Group>
            <Form.Group class="mb-6 Total-Cost-of -Data-Breaches">
              <Form.Label
                for="text"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Total Cost of Data Breaches
              </Form.Label>
              <Form.Control
                type="text"
                id="text"
                value={total_cost_of_data_breach}
                onChange={(e) => setTotalCostOfDataBreach(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                name="text"
              ></Form.Control>
            </Form.Group>
            <Form.Group class="mb-6 Success-Rate">
              <Form.Label
                for="text"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Success Rate
              </Form.Label>
              <Form.Control
                type="text"
                id="text"
                value={success_rate}
                onChange={(e) => setSuccessRate(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                name="text"
              ></Form.Control>
            </Form.Group>
            <Form.Group class="mb-6 Frequency-of-Occurrence ">
              <Form.Label
                for="text"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Frequency of Occurrence
              </Form.Label>
              <Form.Control
                type="text"
                id="text"
                value={frequency_of_occurrence}
                onChange={(e) => setFrequencyOfOccurrence(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                name="text"
              ></Form.Control>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={() => setSetShowHideMe()}>
            Close
          </Button>
          <Button
            className="btn-add-new"
            onClick={() => {
              http
                .patch(`${baseURL}/excel-upload/industry-data/${id}/`, {
                  frequency_of_occurrence: frequency_of_occurrence,
                  success_rate: success_rate,
                  total_breaches: total_breaches,
                  total_cost_of_data_breach: total_cost_of_data_breach,
                  total_incidents: total_incidents,
                  year: year,
                })
                .then((res) => {
                  handleModalShowHideMe();
                  fetchData();

                  swal(
                    'Industry Data!',
                    'Record edited Successfully!',
                    'success'
                  );
                  console.log(res, 'res');
                  fetchData();
                })
                .catch((err) => {
                  swal(err);
                });
            }}
          >
            Save Changes
          </Button>{' '}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyReports;
