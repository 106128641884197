import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { Form } from 'react-bootstrap';
import cronusLogo from '../../assets/imgs/cronusLogo.png';
import { baseURL } from '../../resources/apiClient';
import http from '../../resources/http';
import axios from 'axios';
const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const history = useHistory();

  const [form, setForm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const { email = '' } = form; //declare the variables and assign the values from the form object
    const newErrors = {};
    if (!email || email === '')
      newErrors.email = 'Please enter your email address.';
    return newErrors;
  };
  const submit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      axios
        .post(`${baseURL}/account/password-reset`, {
          email: form.email,
        })
        .then(
          (response) => {
            swal(
              'Success',
              'One time password has been sent to your email address.',
              'success'
            );
            localStorage.setItem(
              'email',
              JSON.stringify({
                email: form.email,
              })
            );
            history.push('/forgot-password-change');
          },
          (err) => {
            swal('Error', 'Change password failed. Please try again!', 'error');
          }
        );
    }
  };
  return (
    <div>
      <section class="bg-gray-50 dark:bg-gray-900">
        <div class="flex flex-col items-center justify-center px-6 py-5 mx-auto md:h-screen lg:py-0">
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <div className="flex justify-center items-center">
              <img
                className="h-36 w-36  object-contain rounded-full"
                src={cronusLogo}
                alt="cyberminds-logo"
              />
            </div>
            <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Having trouble signing in?{' '}
            </h2>
            <div class="mt-6">
              <Form.Group class="mt-6">
                <Form.Label
                  for="email"
                  class="block text-sm font-medium text-gray-900 dark:text-gray-300 mb-3"
                >
                  Enter your email address to get started.
                </Form.Label>
                <Form.Control
                  type="text"
                  value={form.email}
                  onChange={(e) => setField('email', e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  isInvalid={!!errors.email}
                  placeholder="name@company.com"
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="content-footer flex justify-center items-center mt-6">
              <button
                id="forget-password"
                type="submit"
                onClick={(e) => {
                  submit(e);
                }}
                style={{ backgroundColor: '#ce9f2c' }}
                class="flex justify-center items-center focus:outline-none text-white  focus:ring-4  font-medium rounded-lg text-lg  px-5 py-2"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PasswordReset;
