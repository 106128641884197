export const storeCurrentDateTimeInLocalStorage = () => {
  // Get the current date and time
  const currentDateTime = new Date();

  // Convert the date and time to a string (you can format it as needed)
  const formattedDateTime = currentDateTime.toISOString();

  // Store the formatted date and time in localStorage
  localStorage.setItem('lastVisitDateTime', formattedDateTime);
};
export const getFileExtension = (url) => {
  const fileName = url?.split('/')?.pop();
  const fileExtension = fileName?.includes('.')
    ? fileName?.split('.')?.pop()?.toLowerCase()
    : null;

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

  return fileExtension && imageExtensions?.includes(fileExtension)
    ? 'IMAGE'
    : fileExtension
    ? fileExtension?.toUpperCase()
    : null;
};
export function replaceCommaWithSpace(name) {
  // Check if the file name contains underscores
  if (name.includes('_')) {
    // Replace underscores with spaces
    name = name.replace(/_/g, ' ');
  }

  return name;
}

export const formatDate = (dateString) => {
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return formattedDate;
};
export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}
export const getLabelsAsList = (arr) => {
  const labelValues = arr.map((obj) => obj.label).join(', ');
  return labelValues;
};
export const removeDuplicates = (data) => {
  const uniqueNames = Array.from(new Set(data.map((item) => item.name))).map(
    (name) => {
      const { id } = data.find((item) => item.name === name);
      return { id, name };
    }
  );

  return uniqueNames;
};
export function formatListWithComma(res) {
  if (Array.isArray(res)) {
    return res.join(', ');
  }
  return res;
}
export const getControlCatalogIds = (data) => {
  if (Array.isArray(data)) {
    return data
      .filter(
        (item) =>
          item._control_catalog &&
          item._control_catalog.id &&
          item._control_catalog.control_id
      )
      .map((item) => ({
        id: item._control_catalog.id,
        control_id: item._control_catalog.control_id,
      }));
  }
  return [];
};
// Function to resolve response and extract label or return text
export function resolveResponse(response) {
  let parsedResponse;

  try {
    parsedResponse = JSON.parse(response.replace(/'/g, '"'));
  } catch (error) {
    // Parsing failed, treat response as a simple text
    return response;
  }

  if (typeof parsedResponse === 'object' && parsedResponse.label) {
    return parsedResponse.label;
  } else {
    return response;
  }
}
export const removeCategoryDuplicates = (data) => {
  const uniqueNames = Array.from(
    new Set(data.map((item) => item.threat_category))
  )
    .filter((threat_category) => threat_category) // Remove null or empty values
    .map((threat_category) => ({ threat_category }));

  return uniqueNames;
};

export const removeFirstThreeLetters = (string) => {
  return string.slice(3);
};

export function formatText(text) {
  const words = text.split('_');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(' ');
}
export const capitalizeWords = (sentence) => {
  return sentence
    .split(' ')
    .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    .join(' ');
};
export function truncateString(inputString) {
  return inputString.slice(0, 30);
}
export function replaceUnderscoresAndCapitalize(str) {
  return str
    ?.replace(/_/g, ' ')
    ?.replace(/\b\w/g, (char) => char?.toUpperCase());
}
export function calculatePercentageReduction(
  average_risk_score,
  initial_average_risk_score
) {
  var initialScore = initial_average_risk_score?.value ?? 100;
  var currentScore = average_risk_score?.value ?? 10;

  var reductionScore = initialScore - currentScore;
  var percentageReductionScore = (
    (reductionScore / initialScore) *
    100
  ).toFixed(1);

  return percentageReductionScore;
}
export function toLowercase(word) {
  return word.toLowerCase();
}
