class LocalStorage {
  constructor(key) {
    this.key = key;
  }

  loadState() {
    try {
      const serializedState = localStorage.getItem(this.key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }

  saveState(state) {
    if (state?.token?.length > 0) {
      // Only save data if it exists
      try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(this.key, serializedState);
      } catch (err) {
        console.error(err);
      }
    } else {
      this.removeState(); // Clear the data if it's falsy
    }
  }

  removeState() {
    try {
      localStorage.removeItem(this.key);
    } catch (err) {
      console.error(err);
    }
  }
}

export default LocalStorage;
