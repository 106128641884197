import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import http from '../../../resources/http';
import {
  EXPLOITABLE_PATH,
  EXPLOITABLE_PATHS,
  FORMATTED_EXPLOITABLE_PATHS,
} from '../../../api/businessProcess';
import Form from '../../../shared/Form';
import MultiSelect from '../../../shared/MultiSelect';
import CustomTable from '../../../shared/CustomTable';
import SearchHandler from '../../../shared/SearchHandler';
import SortAndFilterByHandler from '../../../shared/SortAndFilterByHandler';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { TbFilterOff, TbFilter } from 'react-icons/tb';
import { useQuery } from '@apollo/client';
import { GET_EXPLOITABLE_PATHS } from '../../../graphql/superAdmin/query/query';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

const ExploitablePath = () => {
  const url = EXPLOITABLE_PATHS;
  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({
    entry: [],
    middle: [],
    end: [],
    type: [],
  });
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchUsedFirst, setSearchUsedFirst] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(GET_EXPLOITABLE_PATHS, {
    variables: { limit: pageSize, offset: page * pageSize },
  });

  //   // Transform the data
  //   const transformedPaths = data.exploitable_path.map((path) => ({
  //     entry: path.entry ? path.entry.name : null,
  //     middle: path.middle ? path.middle.name : null,
  //     end: path.end ? path.end.name : null,
  // 	type:path.path_type??null
  //   }));

  //   // Example of exporting the transformed data or using it in the component
  //   console.log('Transformed Paths:', transformedPaths);
  console.log('Transformed Paths:', data);
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.integrity_level_aggregate?.count || 0;

  const handleCheckboxChangeItem = (field, value) => {
    setFilterCriteria((prev) => ({
      ...prev,
      [field]: prev[field].includes(value)
        ? prev[field].filter((v) => v !== value)
        : [...prev[field], value],
    }));
  };

  const finalData = data;

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };

  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url);
      const pathsArray = response.data.paths;
      setData(pathsArray);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setFetchLoading(false);
    }
  }

  // useEffect(() => {
  // 	fetchData();
  // }, []);

  const typeData = datas?.map((item) => item.type);
  const dropdownFilterData = [...new Set(typeData)];

  const handleCheckboxChange = async (id, row) => {
    setFetchLoading(true);
    try {
      const response = await http
        .patch(`${EXPLOITABLE_PATH}/${id}`, {
          enabled: row.enabled ? false : true,
        })
        .then((res) => {
          fetchData();
        });
    } catch (error) {
      console.error('There was a problem with the PATCH request:', error);
    } finally {
      setFetchLoading(false);
    } 
  };
  const DisplayData = data?.exploitable_path?.map((path) => {
    return (
      <>
        <tr key={id}>
          <td data-label="Company Name">{path?.entry?.name} </td>
          <td data-label="Industry Sector">{path?.middle?.name}</td>
          <td className="" data-label="No. of Admin">
            {path?.end?.name}
          </td>
          <td className="flex text-center" data-label="Manage">
            <IconButton
              onClick={() => {
                // console.log(client, "client");
                // setId(client.id);
                // setAction("edit");
                // form.companyName = client.name;
                // form.industrySector = client.industry_sector;
                // form.currency = client.currency.code;
                // form.riskSensitivity = client.sensitivity_level;
                //handleModalShowHide();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                // setId(client.id);
                // toggleDeleteModal();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </td>
        </tr>
      </>
    );
  });

  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span>Exploitable Paths </span>
          <div class="dropdown-content">
            <Form>
              {dropdownFilterData.map((type) => (
                <label key={type}>
                  <input
                    type="checkbox"
                    checked={filterCriteria.type.includes(type)}
                    onChange={() => handleCheckboxChangeItem('type', type)}
                  />
                  {type}
                </label>
              ))}
            </Form>
          </div>
          <div className="flex justify-between items-center space-x-4">
            <div class="dropdown">
              <button
                className="btn-add-new"
                onClick={() => setDropdownShow(true)}
              >
                <FilterListIcon />
              </button>
              {dropdownShow ? '' : null}
            </div>
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && finalData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <table class="tables ">
            <thead className="sticky-header">
              <tr>
                <th scope="col">Entry</th>
                <th scope="col">Middle</th>
                <th scope="col">End</th>
                <th className="w-28 text-center" scope="col">
                  Manage
                </th>
              </tr>
            </thead>
            <tbody>{DisplayData}</tbody>
          </table>
        )}
      </div>
    </main>
  );
};

export default ExploitablePath;
