import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import swal from 'sweetalert';
import http from '../../../resources/http';
import { CURRENCY, CURRENCY_LIST } from '../../../routes/accounts';
import CustomTable from '../../../shared/CustomTable';
import SearchHandler from '../../../shared/SearchHandler';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import ModalHandler from './ModalHandler';
import { GET_CURRENCY } from '../../../graphql/superAdmin/query/query';
import { useQuery } from '@apollo/client';

const Currency = () => {
  const url = CURRENCY;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);

  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = ['name', 'description'];

  //data
  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(CURRENCY_LIST);
      setData(response.data);
      setFetchLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchLoading(false);
    }
  }
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(GET_CURRENCY, {
    variables: { limit: pageSize, offset: page * pageSize },
  });
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };

  const totalItems = data?.currency_aggregate?.count || 0;

  // useEffect(() => {
  // 	fetchData();
  // }, []);

  const filteredData = SearchHandler(
    data?.currency,
    searchValue,
    fieldsToFilterBy
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      await http.delete(`${url}${id}`);
      fetchData();
      setFetchLoading(false);
      swal('Success', 'It has been deleted successfully', 'success');
    } catch (error) {
      swal('Failed', 'Failed to delete item', 'error');
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };
  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span className="">Currency</span>
          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            <button
              className="btn-add-new"
              onClick={() => {
                setSelectedItem(null);
                setMode('add');
                setIsModalOpen(true);
              }}
            >
              <AddIcon />
            </button>
          </div>
        </div>
        {/* table */}
        {loading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && filteredData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <CustomTable
            filteredData={filteredData}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            passedExcludeColumns={['__typename']}
          />
        )}
        {/* add and edit modal */}
        <ModalHandler
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode={mode}
          size="lg"
          selectedItemId={id}
          selectedItem={selectedItem}
          header="Currency"
          url={url}
          fetchData={fetchData}
        />
      </div>
    </main>
  );
};

export default Currency;
