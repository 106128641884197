import React, { useState } from "react";
// import { useHandleTheme } from "./../../hooks/useHandleTheme";
import useNumberFormatter from "../../utils/useNumberFormatter";

import { baseURL } from "../../resources/apiClient";
import { useApi } from "../../hooks/useApis";
import { useSelector } from "react-redux";
import {
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryTheme,
	VictoryGroup,
	VictoryLegend,
	VictoryTooltip,
} from "victory";

const ResponseDistributionTest = ({data}) => {
	const client_currency = useSelector(
		(state) => state.user?.client_obj?.currency
	);

	const client_currency_symbol =
		client_currency && client_currency === "US DOLLAR"
			? "$"
			: client_currency === "EURO"
			? "€"
			: client_currency === "ETB"
			? "฿"
			: client_currency === "YEN"
			? "¥"
			: client_currency === "WON"
			? "₩"
			: client_currency === "POUND"
			? "£"
			: client_currency === "PESO"
			? "₱"
			: "$";

	// Data for the chart

	const incidentData = [
		{
			category: "notification",
			amount: data?.threat_profiling_analysis?.incident_response_distribution?.notification,
		},
		{
			category: "ex_post_response ",
			amount: data?.threat_profiling_analysis?.incident_response_distribution?.ex_post_response,
		},
		{
			category: "detection_and_escalation ",
			amount: data?.threat_profiling_analysis?.incident_response_distribution?.detection_and_escalation,
		},
		{
			category: "lost_business_cost",
			amount: data?.threat_profiling_analysis?.incident_response_distribution?.lost_business_cost,
		},
	];

	const breachData = [
		{
			category: "notification",
			amount: data?.threat_profiling_analysis?.breach_response_distribution?.notification,
		},
		{
			category: "ex_post_response ",
			amount: data?.threat_profiling_analysis?.breach_response_distribution?.ex_post_response,
		},
		{
			category: "detection_and_escalation ",
			amount: data?.threat_profiling_analysis?.breach_response_distribution?.detection_and_escalation,
		},
		{
			category: "lost_business_cost",
			amount: data?.threat_profiling_analysis?.breach_response_distribution?.lost_business_cost,
		},
	];

	return (
		<VictoryChart
			domainPadding={30}
			theme={VictoryTheme.material}
			height={300}
			width={700}
		>
			<VictoryAxis
				// for x axis
				style={{
					axis: { stroke: "#756f6a" },
					// axisLabel: { fontSize: 25, padding: 30 },
					ticks: { stroke: "#756f6a", size: 5 },
					tickLabels: { fontSize: 15, padding: 5 },
				}}
				tickValues={[
					"notification",
					"ex_post_response ",
					"detection_and_escalation ",
					"lost_business_cost",
				]}
				tickFormat={[
					"Notification",
					"Post Response ",
					"Detection escalation ",
					"Lost Business",
				]}
			/>

			<VictoryAxis
				dependentAxis
				style={{
					axis: { stroke: "#756f6a" },
					axisLabel: { fontSize: 15, padding: 5 },
					ticks: { stroke: "#756f6a", size: 5 },
					tickLabels: { fontSize: 15, padding: 5 },
				}}
				tickFormat={(x) => {
					if (x >= 1e12) {
						return `${(x / 1e12).toFixed(1)}T`;
					} else if (x >= 1e9) {
						return `${(x / 1e9).toFixed(1)}B`;
					} else if (x >= 1e6) {
						return `${(x / 1e6).toFixed(1)}M`;
					} else if (x >= 1000) {
						return `${(x / 1000).toFixed(1)}K`;
					} else {
						return `${x}`;
					}
				}}
			/>
			<VictoryGroup
				opacity={0.7}
				offset={20}
				colorScale={["#375e87", "#ffdd87"]}
			>
				<VictoryBar
					data={incidentData}
					labels={({ datum }) =>
						`Incident ${datum.category}: ${client_currency_symbol}${datum.amount}`
					}
					labelComponent={
						<VictoryTooltip
							flyoutStyle={{
								width: 90,
								stroke: "#375e87",
								strokeWidth: 2,
							}}
						/>
					}
					x="category"
					y="amount"
					barWidth={40}
					style={{
						labels: { fontSize: 20, padding: 10 },
						data: { opacity: [0.7] },
					}}
				/>
				<VictoryBar
					data={breachData}
					labels={({ datum }) =>
						`Breach  ${datum.category}: ${client_currency_symbol}${datum.amount}`
					}
					labelComponent={
						<VictoryTooltip
							flyoutStyle={{
								width: 90,
								stroke: "#ffdd87",
								strokeWidth: 2,
							}}
						/>
					}
					x="category"
					y="amount"
					barWidth={35}
					style={{
						labels: { fontSize: 20, padding: 0 },
						data: { opacity: [0.7] },
					}}
				/>
			</VictoryGroup>
			<VictoryLegend
				x={450}
				y={5}
				centerTitle
				orientation="horizontal"
				gutter={15}
				data={[
					{
						name: "Incident ",
						symbol: { fill: "#375e87" },
						labels: {
							fontSize: 20,
						},
					},
					{
						name: "Breach",
						symbol: { fill: "#ffdd87" },
						labels: { fontSize: 20 },
					},
				]}
			/>
		</VictoryChart>
	);
};

export default ResponseDistributionTest;