const urls = {
  signup: "account/signup",
  login: "account/login",
  logout: "account/logout",
  businessProcess: {
    getMyBusinessProcess: "business_process/get-my-business-process",
    dataSource: "configurations/data-source",
    userInterfaceAccessibility: "configurations/user-interface-accessibility",
    hostingModel: "configurations/hosting-model",
    businessCriticality: "configurations/business-criticality-rpo",
    dataClassificationLevel: "configurations/data-classification-level",
    broadestUserPopulation: "configurations/broadest-user-population",
    userPopulation: "configurations/user-population",
    applicationAccessibility: "configurations/application-accessibility",
    businessImpact: "configurations/business-impact",
  },
};

export default urls;
