import React from "react";
import MultiSelect from "./MultiSelect";
import CronusSelect from "./CronusSelect";
import CronusTextarea from "./CronusTextarea";

const Form = ({ children }) => <>{children}</>;

Form.Group = ({ children }) => <div className="form-group">{children}</div>;

Form.Label = ({ children }) => <label className="form-label">{children}</label>;

Form.Control = ({
	type,
	// value,
	options,
	maxLength,
	placeholder,
	onChange: handleChange,
	...props
}) => {
	switch (type) {
		case "select":
			return (
				<>
					<CronusSelect options={options} onChange={handleChange} {...props} />
				</>
			);
		case "multi-select":
			return (
				<>
					<MultiSelect options={options} onChange={handleChange} {...props} />
				</>
			);
		// case "textarea":
		// 	return (
		// 		<>
		// 			<CronusTextarea
		// 				maxLength={maxLength}
		// 				placeholder={placeholder}
		// 				onChange={handleChange}
		// 				{...props}
		// 			/>
		// 		</>
		// 	);
		default:
			return (
				<>
					<input
						className="form-control"
						type={type}
						// value={value}
						placeholder={placeholder}
						onChange={handleChange}
						{...props}
					/>
				</>
			);
	}
};

Form.Control.Feedback = ({ children, type }) => (
	<div
		className={`mt-1 ${
			type === "invalid" ? "invalid-feedback" : "text-green-600"
		}`}
	>
		{children}
	</div>
);

export default Form;
