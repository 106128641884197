import { createSlice } from "@reduxjs/toolkit";

const typesSlice = createSlice({
	name: "types",
	initialState: [],
	reducers: {
		setTypes: (state, action) => action.payload,
		addType: (state, action) => {
			state.push(action.payload);
		},
		updateType: (state, action) => {
			const index = state.findIndex((type) => type.id === action.payload.id);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteType: (state, action) => {
			return state.filter((type) => type.id !== action.payload.id);
		},
	},
});

export const { setTypes, addType, updateType, deleteType } = typesSlice.actions;

export default typesSlice.reducer;
