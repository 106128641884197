import React from 'react';
import ReactApexChart from 'react-apexcharts';

const HarveyBallChart = ({ percentage }) => {
  //   const percentage = 95; // Hardcoded percentage value

  const chartOptions = {
    labels: ['Completion'],
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        offsetY: 50,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false, // Set to false to hide the legend
    },
    tooltip: {
      enabled: false, // Set to false to hide tooltips
    },
    fill: {
      colors: [`#ce9f2c`, 'lightgray'], // Green color for completion, white color for uncompleted
    },
  };

  const chartSeries = [percentage, 100 - percentage];

  return (
    <div className=" w-36 -mt-10 -mx-10">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="pie"
        height="70"
      />
    </div>
  );
};

export default HarveyBallChart;
