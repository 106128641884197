import React, { useState, useMemo } from "react";
import SvgComponent from "./SvgComponent";

function CronusSelect({ options, onChange, value = "" }) {
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	const toggleDropdown = () => setIsOpen(!isOpen);

	const filteredOptions = useMemo(() => {
		// changing options to a Set to remove duplicates, then back to an array
		const uniqueOptions = Array.from(new Set(options));

		if (!searchTerm) return uniqueOptions;
		return uniqueOptions.filter((option) =>
			option.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [options, searchTerm]);

	const selectOption = (option) => {
		const event = {
			target: { value: option },
		};
		onChange(event);
		setIsOpen(false);
	};

	return (
		<div className="CronusSelect">
			<div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
				<div className="dropdown-header-value">
					<span>{value || "Select..."}</span>
				</div>
				<button
					type="button"
					onClick={toggleDropdown}
					className="dropdown-header-down-icon"
				>
					<SvgComponent
						width="24"
						height="24"
						fillColor="none"
						strokeColor="currentColor"
						strokeWidth="2"
						path={isOpen ? "M18 15 12 9 6 15" : "M6 9L12 15 18 9"}
						onClick={toggleDropdown}
					/>
				</button>
			</div>

			{isOpen && (
				<div
					className="dropdown-menu"
					onMouseLeave={() => {
						toggleDropdown();
					}}
				>
					<div className="select-content">
						<div className="dropdown-search">
							<input
								type="text"
								placeholder="Search..."
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
							{searchTerm && (
								<button
									type="button"
									onClick={() => setSearchTerm("")}
									className="dropdown-search-clear-btn"
								>
									<SvgComponent
										width="24"
										height="24"
										fillColor="none"
										strokeColor="currentColor"
										strokeWidth="2"
										path="M18 6L6 18M6 6l12 12"
										onClick={toggleDropdown}
									/>
								</button>
							)}
						</div>
						<ul className="options">
							{filteredOptions.map((option) => (
								<li
									key={option}
									onClick={() => {
										selectOption(option);
									}}
								>
									<span>{option}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
}

export default CronusSelect;
