import React, { useState, useEffect } from 'react';
import http from '../../../../resources/http';
import { cpeValue, id } from '../../../../authentication/store/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { baseURL } from '../../../../resources/apiClient';

const AssetDetails = ({ showHide }) => {
  const [data, setData] = useState([]);
  const processId = useSelector((state) => state.pid);
  let type = JSON.parse(localStorage.getItem('cyber-minds'));
  let clientID = type?.user?.client;

  useEffect(() => {
    http
      .get(
        `${baseURL}/excel-upload/assets?clientId=${clientID}&processId=${processId}`
      )
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log('No Data To Show');
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);
  console.log('Your asset detail here ', data);
  const DisplayData = data?.map((asset) => {
    return (
      <tr>
        <td>{asset.businessAsset}</td>
        <td>{asset.assetName}</td>
      </tr>
    );
  });
  return (
    <>
      <div className="z-100 flex justify-center  items-center ">
        <div className="text-black">
          <div className="rounded overflow-hidden flex  justify-center items-center">
            <table class="tables table-striped ">
              <thead>
                <tr>
                  <th className=" text-2xl">Asset</th>
                  <th className=" text-2xl">Asset Name</th>
                </tr>
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetDetails;
