import { useMemo } from "react";

const SortAndFilterByHandler = (data, filterCriteria, sortField, sortOrder) => {
	return useMemo(() => {
		let processedData = [...data];

		// Apply checkbox/select filters only if needed
		if (filterCriteria) {
			processedData = processedData.filter((item) => {
				return Object.entries(filterCriteria).every(([field, values]) => {
					if (!values || values.length === 0) return true; // No filter applied if no values are selected
					return values.includes(item[field]);
				});
			});
		}

		// Apply sorting
		if (sortField && sortOrder) {
			processedData.sort((a, b) => {
				let valueA = a[sortField];
				let valueB = b[sortField];

				// Check if the values are numbers
				const isNumber = !isNaN(valueA) && !isNaN(valueB);
				// Check if the values are booleans
				const isBoolean =
					typeof valueA === "boolean" && typeof valueB === "boolean";

				if (isNumber) {
					// Numeric comparison
					return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
				} else if (isBoolean) {
					// Boolean comparison: true values are considered 'greater' than false
					return valueA === valueB
						? 0
						: (valueA ? -1 : 1) * (sortOrder === "asc" ? 1 : -1);
				} else {
					// String comparison
					valueA = String(valueA).toLowerCase();
					valueB = String(valueB).toLowerCase();
					if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
					if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
				}
				return 0;
			});
		}

		return processedData;
	}, [data, filterCriteria, sortField, sortOrder]);
};

export default SortAndFilterByHandler;
