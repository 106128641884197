import React, { useState, useEffect } from 'react';
import HealthSafetyPercentage from '../Charts/RiskAnalysis/HealthSafetyPercentage';
import RiskAnalysisThree from '../Charts/RiskAnalysis/RiskAnalysisThree';
import { Slider, Typography } from '@mui/material';
import GuageLegal from '../Charts/RiskAnalysis/GuageLegal';
import GuageOthers from '../Charts/RiskAnalysis/GuageOthers';
import { makeStyles } from '@material-ui/core/styles';
import EnvironmentalGuage from '../Charts/RiskAnalysis/EnvironmentalGuage';
import GuageOperational from '../Charts/RiskAnalysis/GuageOperational';
import GuagePenality from '../Charts/RiskAnalysis/GuagePenality';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { useHandleTheme } from '../hooks/useHandleTheme';
import { useSelector } from 'react-redux';
import http from './../resources/http';
import { baseURL } from './../resources/apiClient';
import { formatNumber } from './../utils/formatNumbers';
const useStyles = makeStyles((theme) => ({
  root: {
    height: 150,
    width: 8,
    color: '#04365d',
    marginRight: 150,
    marginLeft: -60,

    '& .MuiSlider-thumb': {
      height: 10,
      width: 44,
      borderRadius: 10,
    },
  },
  oprational: {
    color: '#04365d',
    '& .MuiSlider-thumb': {
      height: 10,
      width: 44,
      borderRadius: 10,
    },
  },
}));
const RiskAnalysis = () => {
  const { darkMode } = useHandleTheme();
  const classes = useStyles();
  const [value, setValue] = useState(100);
  const [health, setHealth] = useState(20);
  const [others, setOthers] = useState(15);
  const [penality, setPenality] = useState(15);
  const [interuption, setInteruption] = useState(10);
  const [financialValue, setFinancialValue] = useState(5);
  const [legal, setLegal] = useState(15);
  const [env, setEnv] = useState(10);
  const processId = useSelector((state) => state.pid);
  const [data, setData] = useState([]);

  useEffect(() => {
    http
      .get(
        `${baseURL}/business_process/incident-breach-costs?businessProcessId=${processId}`
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [processId]);
  console.log(data, 'risk analysis');
  return (
    <div
      className={`${
        darkMode ? 'bg-gray-900 text-white' : ''
      } flex flex-col font  space-y-1 relative h-screen`}
    >
      <div
        className="flex justify-center
      items-center mt-4 text-4xl font-semibold"
      >
        <span>Financial Impacts</span>
      </div>
      <div className="p-2   text-center  grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5  gap-4">
        <div
          className={`${
            darkMode ? ' text-white' : ' text-blue-800'
          } rounded-lg    border-gray-200   relative flex flex-col justify-between items-center   overflow-hidden shadow`}
        >
          <div
            className={`${
              darkMode ? 'bg-gray-800 ' : 'main '
            } h-14 flex justify-center items-center pl-1 w-full font-bold  text-white`}
          >
            Avg Loss (USD in Millions)
          </div>
          <div className="  flex justify-center items-center flex-1">
            <div className="flex flex-col justify-between items-center space-y-10">
              <div>
                {' '}
                <p className="text-3xl font-bold">
                  {formatNumber(data?.breach_costs)}
                </p>
                <p className="text-2xl font-bold">Breach Costs</p>
              </div>
              <div>
                {' '}
                <p className="text-3xl font-bold">
                  {formatNumber(data?.incident_costs)}
                </p>
                <p className="text-2xl font-bold">Incident Costs</p>
              </div>
              {/* <h2 className="  text-8xl  ">
                {parseFloat(financialValue) +
                  parseFloat(interuption) +
                  parseFloat(legal) +
                  parseFloat(others)}
              </h2> */}
            </div>
          </div>
        </div>

        <div className="rounded-lg  border-gray-200 h-full  relative flex justify-center items-center   overflow-hidden shadow">
          {' '}
          <div className="h-full w-full flex flex-col justify-center items-center vendor-width">
            <div
              className={`${
                darkMode ? 'bg-gray-800 ' : 'main '
              } flex w-full  h-14  justify-center items-center pl-1 text-white  space-x-1 font-semibold`}
            >
              <span>Penalities and Fees</span>
              <Tooltip
                className="text-3xl cursor-pointer"
                title={
                  <Typography fontSize={15}>
                    Number assets and vendors
                  </Typography>
                }
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
            </div>
            <div className=" text-sm flex flex-1 flex-col  pb-2">
              <GuagePenality myValue={penality} />
              <div className=" ">
                <input
                  min="0"
                  max="15"
                  type="number"
                  value={penality}
                  name="firstName"
                  onChange={(e) => setPenality(e.target.value)}
                  className="text-black mx-20  font-bold   p-3 w-24 h-10 text-center border-2 outline-none"
                />
              </div>{' '}
            </div>
          </div>
        </div>

        <div className="rounded-lg  border-gray-200   relative flex justify-center items-center   overflow-hidden shadow">
          <div className="rounded-lg flex w-full  justify-center items-center   flex-col h-full">
            <div
              className={`${
                darkMode ? 'bg-gray-800 ' : 'main '
              } flex w-full  h-14  justify-center items-center pl-1 text-white  space-x-1 font-semibold`}
            >
              <span>Operational</span>
              <Tooltip
                className="text-3xl cursor-pointer"
                title={
                  <Typography fontSize={15}>
                    Number assets and vendors
                  </Typography>
                }
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
            </div>
            <div className=" text-sm flex-1  pb-2 ">
              <GuageOperational
                className="h-96 bg-red"
                myValue={financialValue}
              />
              <div className="">
                <input
                  min="0"
                  max="5"
                  type="number"
                  value={financialValue}
                  name="firstName"
                  onChange={(e) => setFinancialValue(e.target.value)}
                  className="text-black mx-20  font-bold   p-3 w-24 h-10 text-center border-2 outline-none"
                />
              </div>{' '}
            </div>
          </div>
        </div>
        <div className="rounded-lg  border-gray-200    relative flex justify-center items-center   overflow-hidden shadow">
          <div className="h-full w-full flex flex-col justify-center items-center vendor-width">
            <span
              className={`${
                darkMode ? 'bg-gray-800 ' : 'main '
              } flex w-full  h-14  justify-center items-center pl-1 text-white  space-x-1 font-semibold`}
            >
              <span>Legal</span>
              <Tooltip
                className="text-3xl cursor-pointer"
                title={
                  <Typography fontSize={15}>
                    Number assets and vendors
                  </Typography>
                }
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
            </span>
            <div className=" text-sm flex flex-1 flex-col  pb-2">
              <GuageLegal myValue={legal} />
              <div className=" ">
                <input
                  min="0"
                  max="15"
                  type="number"
                  value={legal}
                  name="firstName"
                  onChange={(e) => setLegal(e.target.value)}
                  className="text-black mx-20   font-bold   p-3 w-24 h-10 text-center border-2 outline-none"
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" w-full  rounded-lg  border-gray-200   relative flex justify-center items-center   overflow-hidden shadow">
          <div className="h-full w-full   flex flex-col justify-center items-center">
            <span
              className={`${
                darkMode ? 'bg-gray-800 ' : 'main '
              } flex w-full  h-14  justify-center items-center pl-1 text-white  space-x-1 font-semibold`}
            >
              <span>Others</span>
              <Tooltip
                className="text-3xl cursor-pointer"
                title={
                  <Typography fontSize={15}>
                    Number assets and vendors
                  </Typography>
                }
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
            </span>
            <div className=" text-sm flex-1  pb-2">
              <GuageOthers myValue={others} />
              <div className="">
                <input
                  min="0"
                  max="20"
                  type="number"
                  value={others}
                  name="firstName"
                  onChange={(e) => setOthers(e.target.value)}
                  className="text-black mx-20  font-bold   p-3 w-24 h-10 text-center border-2 outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-2 font -mt-10 rounded h-full  text-center  grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-4">
        <div className="rounded-lg h-card  border-gray-200    relative flex justify-center items-center   overflow-hidden shadow">
          <div className="h-full w-full  flex flex-col ">
            <span
              className={`${
                darkMode ? 'bg-gray-800 ' : 'bg-gray-600'
              } h-10  text-white w-full   flex justify-start items-center space-x-1  px-3 py-1 font-semibold`}
            >
              <span>Health and Safety</span>
              <Tooltip
                className="text-3xl cursor-pointer"
                title={
                  <Typography fontSize={15}>
                    Number assets and vendors
                  </Typography>
                }
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
            </span>
            <div className="flex justify-center items-center space-x-14">
              <div>
                {' '}
                <HealthSafetyPercentage myValue={health} />
              </div>
              <div className="">
                <Slider
                  className={classes.root}
                  orientation="vertical"
                  size="small"
                  max={20}
                  defaultValue={health}
                  onChange={(e) => setHealth(e.target.value)}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
              </div>{' '}
            </div>
            <div className="pb-2 flex space-x-3 justify-center items-center">
              <div className="flex flex-col">
                <span className=" ">Avg Loss</span>
                <span className=" text-sm">(USD in Millions)</span>
              </div>{' '}
              <input
                min="0"
                max="100"
                type="number"
                value={health}
                name="firstName"
                onChange={(e) => setValue(e.target.value)}
                className="text-black mx-20  p-2   text-center border-2 outline-none"
              />
            </div>
          </div>
        </div>
        <div className="rounded-lg h-card  border-gray-200    relative flex justify-center items-center   overflow-hidden shadow">
          <div className="h-full w-full  flex flex-col ">
            <span
              className={`${
                darkMode ? 'bg-gray-800 ' : 'bg-gray-600'
              } h-10  text-white w-full   flex justify-start items-center space-x-1  px-3 py-1 font-semibold`}
            >
              <span>Operational</span>
              <Tooltip
                className="text-3xl cursor-pointer"
                title={
                  <Typography fontSize={15}>
                    Number assets and vendors
                  </Typography>
                }
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
            </span>
            <div className="flex justify-center items-center space-x-14">
              <div>
                {' '}
                <RiskAnalysisThree myValue={value} />
              </div>
              <div className="">
                <Slider
                  className={classes.root}
                  orientation="vertical"
                  size="small"
                  max={100}
                  defaultValue={value}
                  onChange={(e) => setValue(e.target.value)}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
              </div>{' '}
            </div>
            <div className="pb-2 flex space-x-3 justify-center items-center">
              <div className="flex flex-col">
                <span className=" ">Avg Loss</span>
                <span className=" text-sm">(USD in Millions)</span>
              </div>{' '}
              <input
                min="0"
                max="100"
                type="number"
                value={value}
                name="firstName"
                onChange={(e) => setValue(e.target.value)}
                className="text-black mx-20  p-2   text-center border-2 outline-none"
              />
            </div>
          </div>
        </div>
        <div className="rounded-lg h-card  border-gray-200    relative flex justify-center items-center   overflow-hidden shadow">
          <div className="h-full w-full  flex flex-col ">
            <span
              className={`${
                darkMode ? 'bg-gray-800 ' : 'bg-gray-600'
              } h-10  text-white w-full   flex justify-start items-center space-x-1  px-3 py-1 font-semibold`}
            >
              <span>Environmental Ramification</span>
              <Tooltip
                className="text-3xl cursor-pointer"
                title={
                  <Typography fontSize={15}>
                    Number assets and vendors
                  </Typography>
                }
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
            </span>
            <div className="flex justify-center items-center space-x-14">
              <div>
                {' '}
                <EnvironmentalGuage myValue={env} />
              </div>
              <div className="">
                <Slider
                  className={classes.root}
                  orientation="vertical"
                  size="small"
                  max={10}
                  defaultValue={env}
                  onChange={(e) => setEnv(e.target.value)}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
              </div>{' '}
            </div>
            <div className="pb-2 flex space-x-3 justify-center items-center">
              <div className="flex flex-col">
                <span className=" ">Avg Loss</span>
                <span className=" text-sm">(USD in Millions)</span>
              </div>{' '}
              <input
                min="0"
                max="30"
                type="number"
                value={env}
                name="firstName"
                onChange={(e) => setValue(e.target.value)}
                className="text-black mx-20  p-2   text-center border-2 outline-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskAnalysis;
