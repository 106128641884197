import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import cronusLogo from "../../../assets/imgs/logo.svg";
import { SuperAdminNavItems } from "./SuperAdminNavItems";
import { RiArrowDownSFill, RiArrowRightFill } from "react-icons/ri";

const SuperAdminNavBar = () => {
	const [activeDropdown, setActiveDropdown] = useState(null);
	const [activeSecondaryDropdown, setActiveSecondaryDropdown] = useState(null);

	const [activeSubLink, setActiveSubLink] = useState(null);

	const [activeLink, setActiveLink] = useState(null);

	const handleDropdown = (id) => {
		if (activeDropdown === id) {
			setActiveDropdown(null);
		} else {
			setActiveDropdown(id);
		}
	};
	const handleSecondaryDropdown = (id) => {
		if (activeSecondaryDropdown === id) {
			setActiveSecondaryDropdown(null);
		} else {
			setActiveSecondaryDropdown(id);
		}
	};

	const [activeItemId, setActiveItemId] = useState(null);

	const handleItemClick = (id) => {
		setActiveItemId(id);
	};

	return (
		<nav className="nav">
			<img className="cronus-logo" src={cronusLogo} alt="Cronus logo" />
			<ul>
				{SuperAdminNavItems.map((item) => (
					<li
						key={item.id}
						className={
							activeLink === item.id ? " nav-content active" : "nav-content"
						}
						onMouseLeave={() => {
							setActiveDropdown(null);
							setActiveSecondaryDropdown(null);
						}}
					>
						<Link
							to={item.link}
							onClick={() => {
								if (item.dropdown) {
									handleDropdown(item.id);
								}
								setActiveLink(item.id);
							}}
						>
							{item.name}
							{item.dropdown && <RiArrowDownSFill className="dropdown-icon" />}
						</Link>

						{item.dropdown && activeDropdown === item.id && (
							<div className="nav-dropdown-container">
								{item.dropdown.map((subItem) => (
									<div key={subItem.name}>
										<Link
											to={subItem.link}
											className={
												activeSubLink === subItem.name
													? "nav-dropdown-content active"
													: "nav-dropdown-content"
											}
											onClick={() => {
												if (subItem.secondaryDropdown) {
													handleSecondaryDropdown(subItem.name);
												}
												setActiveSubLink(subItem.name);
											}}
										>
											{subItem.name}
											{subItem.secondaryDropdown && (
												<RiArrowRightFill className="dropdown-icon" />
											)}
										</Link>
										{subItem.secondaryDropdown &&
											activeSecondaryDropdown === subItem.name && (
												<div
													className="nav-secondary-dropdown-container"
													onMouseLeave={() => {
														setActiveSecondaryDropdown(null);
													}}
												>
													{subItem.secondaryDropdown.map((secondaryItem) => (
														<div>
															<Link
																key={secondaryItem.name}
																to={secondaryItem.link}
																className={
																	activeSubLink === secondaryItem.name
																		? "nav-dropdown-secondary-content active"
																		: "nav-dropdown-secondary-content"
																}
															>
																{secondaryItem.name}
															</Link>
														</div>
													))}
												</div>
											)}
									</div>
								))}
							</div>
						)}
					</li>
				))}
			</ul>
		</nav>
	);
};

export default SuperAdminNavBar;
