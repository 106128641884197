import fernet from 'fernet';

// Replace 'yourSecretKey' with your actual secret key
const secret = new fernet.Secret(
  'bI6NM94R3_qcFwSxrDnTmIAEWuL-qom8OE623fNqGdA='
);

// Function to encrypt data using Fernet
export const encryptData = (data) => {
  const token = new fernet.Token({
    secret: secret,
  });
  return token.encode(data);
};

// Function to decrypt data using Fernet
export const decryptData = (encryptedData) => {
  try {
    const token = new fernet.Token({
      secret: secret,
      token: encryptedData,
      ttl: 0, // Set TTL to 0 if you don't want automatic expiration checks
    });
    return token.decode();
  } catch (error) {
    console.error('Error decrypting data:', error);
    throw error;
  }
};
