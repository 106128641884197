import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col, Table } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { baseURL } from '../../../resources/apiClient';
import http from '../../../resources/http';
import swal from 'sweetalert';

const RiskProbabilityMapping = () => {
  const [id, setId] = useState();
  const [data, setData] = useState([]);

  const [showHideEdit, setShowHideEdit] = useState(false);
  const handleModalShowHideEdit = () => setShowHideEdit(!showHideEdit);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateForm = () => {
    const {
      risk_definition = '',
      risk_probability_min = '',
      risk_probability_max = '',
      risk_frequency = '',
    } = form;
    const newErrors = {};
    if (!risk_definition || risk_definition === '')
      newErrors.risk_definition = 'Risk definition is required.';
    if (risk_definition == 'very_low') {
      form.risk_probability_min = '0';
    } else if (risk_probability_min === !NaN || risk_probability_min === '') {
      newErrors.risk_probability_min =
        'Risk probability min value is required.';
    }
    if (risk_definition == 'critical') {
      form.risk_probability_max = '100';
    } else if (risk_probability_max === !NaN || risk_probability_max === '') {
      newErrors.risk_probability_max =
        'Risk probability max value is required.';
    }
    if (!risk_frequency || risk_frequency === '')
      newErrors.risk_frequency = 'Risk frequency is required.';
    return newErrors;
  };

  const clearAll = () => {
    form.risk_definition = '';
    form.risk_probability_min = '';
    form.risk_probability_max = '';
    form.risk_frequency = '';
  };
  const updateRiskProbability = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      http
        .patch(`${baseURL}/risk/probability/${id}`, {
          risk_level: form.risk_definition,
          probability_min: form.risk_probability_min,
          probability_max: form.risk_probability_max,
          frequency: form.risk_frequency,
        })
        .then(
          (response) => {
            handleModalShowHideEdit();
            fetchData();
            swal(
              'Success',
              'Risk probability mapping updated successfully',
              'success'
            );
          },
          (err) => {
            swal('fall', 'Something went wrong, please try again.', 'error');
          }
        );
    }
  };
  const fetchData = () => {
    http
      .get(`${baseURL}/risk/probabilities`)
      .then((response) => {
        setData(response.data);
      })
      .then()
      .catch((err) => {
        swal('fall', 'There is no data to display at the moment.', 'error');
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const refresh = () => {
    window.location.reload();
  };
  const DisplayData = data
    ?.sort((a, b) => a.probability_min - b.probability_min)
    ?.map((risk) => {
      return (
        <tr key={risk?.id}>
          <td>{risk.risk_level}</td>
          <td>
            {risk.probability_min} to {risk.probability_max}
          </td>
          <td>{risk.frequency}</td>
          <td>
            <IconButton
              onClick={() => {
                setId(risk.id);
                form.risk_definition = risk.risk_level;
                form.risk_probability_min = risk.probability_min;
                form.risk_probability_max = risk.probability_max;
                form.risk_frequency = risk.frequency;
                handleModalShowHideEdit();
              }}
            >
              <EditIcon />
            </IconButton>
          </td>
        </tr>
      );
    });
  return (
    <div className="p-10">
      <div className="table-title ">
        <div>
          <span className="">Risk Probability</span>
        </div>
      </div>
      <div className="">
        <table class="tables ">
          <thead className="">
            <tr>
              <th className="">Risk Probability </th>
              <th className="">Probability in %</th>
              <th className="">Frequency</th>
              <th className="">Manage</th>
            </tr>
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
      </div>{' '}
      {/* editing model */}
      <Modal show={showHideEdit} className="" size="lg">
        <Modal.Header>
          <Modal.Title>Update Risk Probability</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-small">
          <Form.Group class="mb-6">
            <Form.Label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Risk Probability
            </Form.Label>
            <Form.Control
              type="text"
              id="email"
              disabled
              value={form.risk_definition}
              onChange={(e) => setField('risk_definition', e.target.value)}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="text..."
              isInvalid={!!errors.risk_definition}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.risk_definition}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group class="mb-6">
            <Form.Label
              for="text"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Probability in %
            </Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  disabled={false}
                  id="risk_probability_min"
                  value={form.risk_probability_min}
                  onChange={(e) =>
                    setField('risk_probability_min', e.target.value)
                  }
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Min"
                  isInvalid={!!errors.risk_probability_min}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.risk_probability_min}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  disabled={false}
                  id="risk_probability_max"
                  value={form.risk_probability_max}
                  onChange={(e) =>
                    setField('risk_probability_max', e.target.value)
                  }
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Max"
                  isInvalid={!!errors.risk_probability_max}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.risk_probability_max}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group class="mb-6">
            <Form.Label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Frequency
            </Form.Label>
            <Form.Control
              type="text"
              id="email"
              value={form.risk_frequency}
              onChange={(e) => setField('risk_frequency', e.target.value)}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="text..."
              isInvalid={!!errors.risk_frequency}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.risk_frequency}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => {
              setErrors('');
              clearAll('');
              handleModalShowHideEdit();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-add-new"
            onClick={(e) => updateRiskProbability(e)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RiskProbabilityMapping;
