import { createSlice } from "@reduxjs/toolkit";

const userNameSlice = createSlice({
	name: "userName",
	initialState: [],
	reducers: {
		setUserName: (state, action) => action.payload,
		addUserName: (state, action) => {
			state.push(action.payload);
		},
		updateUserName: (state, action) => {
			const index = state.findIndex(
				(usecase) => usecase.id === action.payload.id
			);
			if (index !== -1) {
				state[index] = action.payload;
			}
		},
		deleteUserName: (state, action) => {
			return state.filter((userName) => userName.id !== action.payload.id);
		},
	},
});

export const { setUserName, addUserName, updateUserName, deleteUserName } =
	userNameSlice.actions;

export default userNameSlice.reducer;
