import { actionTypes } from '../actions';

const userStatusReducer = (
  state = {
    approved: false,
    loggedIn: false,
    access_token: null,
    refresh_token: null,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_USER_STATUS:
      return {
        approved: action.payload.approved,
        loggedIn: action.payload.loggedIn,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
      };
    default:
      return state;
  }
};

export { userStatusReducer };
