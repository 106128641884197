import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useHandleTheme } from '../../hooks/useHandleTheme';
import { makeStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function MultipleSelectCheckmarks({
  data,
  onChange,
  tag,
  initialSelectedValues,
}) {
  // Filter out undefined and null values from initialSelectedValues
  const sanitizedInitialSelectedValues = Array.isArray(initialSelectedValues)
    ? initialSelectedValues.filter(
        (item) => item !== undefined && item !== null
      )
    : [initialSelectedValues].filter(
        (item) => item !== undefined && item !== null
      );

  const [selectedValue, setselectedValue] = useState(
    sanitizedInitialSelectedValues
  );

  const { darkMode } = useHandleTheme();
  const useOutlinedInputStyles = makeStyles((theme) => ({
    root: {
      '& $notchedOutline': {
        borderColor: darkMode ? 'whitesmoke' : undefined,
      },
      '&:hover $notchedOutline': {
        borderColor: darkMode ? 'whitesmoke' : undefined,
      },
      '&$focused $notchedOutline': {
        borderColor: darkMode ? 'whitesmoke' : undefined,
      },
    },
    focused: {},
    notchedOutline: {},
  }));
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Ensure value is always an array and filter out undefined and null values
    const newValue = Array.isArray(value)
      ? value.filter((item) => item !== undefined && item !== null)
      : [value].filter((item) => item !== undefined && item !== null);
    console.log(newValue, 'selected');
    setselectedValue(newValue);
  };

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue, onChange]);

  useEffect(() => {
    // Set the selected values when initialSelectedValues change
    if (initialSelectedValues !== undefined) {
      const sanitizedInitialSelectedValues = Array.isArray(
        initialSelectedValues
      )
        ? initialSelectedValues.filter(
            (item) => item !== undefined && item !== null
          )
        : [initialSelectedValues].filter(
            (item) => item !== undefined && item !== null
          );
      setselectedValue(sanitizedInitialSelectedValues);
    }
  }, [initialSelectedValues]);

  const outlinedInputClasses = useOutlinedInputStyles();

  return (
    <div>
      <FormControl className=" w-full rounded-md">
        <InputLabel
          style={{ color: darkMode ? 'whitesmoke' : undefined }}
          id="multiple-checkbox-label"
        >
          {tag}
        </InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedValue}
          onChange={handleChange}
          input={<OutlinedInput label={tag} classes={outlinedInputClasses} />}
          renderValue={(selected) => (
            <div>
              {Array.isArray(selected) ? (
                selected.map((value, index) => (
                  <React.Fragment key={value}>
                    <span>{value}</span>
                    {index !== selected.length - 1 && <span>, </span>}{' '}
                    {/* Add comma after each value except the last one */}
                  </React.Fragment>
                ))
              ) : (
                <span>{selected}</span>
              )}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {data?.map((items) => (
            <MenuItem key={items} value={items}>
              <Checkbox checked={selectedValue?.indexOf(items) > -1} />
              <ListItemText primary={items} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
