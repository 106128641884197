import React from "react";
import { useSelector } from "react-redux";

const NoDataToShow = () => {
  const processName = useSelector((state) => state.name);
  return (
    <div className="flex flex-col justify-center items-center h-full pt-10 text-2xl">
      {" "}
      <span>
        There is no data for{" "}
        <span className="text-blue-900 font-semibold text-3xl">
          {processName}
        </span>
        , Please select different business process
      </span>
      <img
        src="https://cdn1.vectorstock.com/i/thumb-large/41/05/delete-document-line-icon-web-and-mobile-vector-18444105.jpg"
        alt="no data"
        className="w-96 mt-10"
      />
    </div>
  );
};

export default NoDataToShow;
