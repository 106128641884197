export const Input = ({
	index,
	otp,
	handleChange,
	handleKeyDown,
	inputRef,
}) => (
	<input
		className="w-14 h-14 bg-stone-400 text-gray-900 
    dark:bg-gray-900
    dark:border-gray-600
    dark:placeholder-gray-400 
	dark:text-gray-300
    dark:focus:ring-gray-300
    dark:focus:border-gray-500
    border text-center rounded-xl text-4xl mt-10 mb-10"
		type="text"
		maxLength={1}
		value={otp[index]}
		onChange={(e) => handleChange(e, index)}
		onKeyDown={(e) => handleKeyDown(e, index)}
		ref={inputRef}
	/>
);
