import React from 'react';

const ColorIndicator = ({ onColorClick }) => {
  const colors = [
    { label: 'Very Low', color: 'lightgreen', value: 'very_low' },
    { label: 'Low', color: 'green', value: 'low' },
    { label: 'Moderate', color: 'yellow', value: 'moderate' },
    { label: 'High', color: 'orange', value: 'high' },
    { label: 'Critical', color: 'maroon', value: 'critical' },
  ];

  return (
    <div>
      <div className=" text-white dark:text-gray-400 flex justify-center items-center space-x-6 rounded-md px-4 p-2.5">
        {colors.map((color, index) => (
          <div
            className="flex justify-center items-center space-x-2 border p-0.5 rounded-sm cursor-pointer dark:border-gray-400"
            key={index}
            onClick={() => onColorClick(color.value)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill={color.color}
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="text-xs">{color.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorIndicator;
