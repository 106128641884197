import React, { useEffect, useState } from "react";
import IncidentList from "../layout/components/Threat Scops/IncidentList";
import BreachList from "../layout/components/Threat Scops/BreachList";
import AttacksList from "../layout/components/Threat Scops/AttacksList";
import { columns, pathOptions } from "../data/data";
import Chart from "react-google-charts";

const ExploitablePathWrapperClient = ({ path, fetchData }) => {
	const [openTab, setOpenTab] = React.useState(1);
	const [color, setColor] = useState("green");

	const transformedData = path?.paths?.reduce((acc, curr) => {
		if (curr.entry && curr.middle) {
			const foundIndex = acc.findIndex(
				(item) => item[0] === curr.entry && item[1] === curr.middle
			);
			if (foundIndex !== -1) {
				acc[foundIndex][2]++;
			} else {
				acc.push([curr.entry, curr.middle, 1]);
			}
		}
		return acc;
	}, []);
	const transformedDat = path?.paths
		?.filter((type) => type.type === "breach")
		?.reduce((acc, curr) => {
			if (curr.entry && curr.middle && curr.end) {
				const foundIndex1 = acc.findIndex(
					(item) => item[0] === curr.entry && item[1] === curr.middle
				);
				if (foundIndex1 !== -1) {
					acc[foundIndex1][2]++;
				} else {
					acc.push([curr.entry, curr.middle, 1]);
				}

				const foundIndex2 = acc.findIndex(
					(item) => item[0] === curr.middle && item[1] === curr.end
				);

				if (foundIndex2 !== -1) {
					acc[foundIndex2][2]++;
				} else {
					acc.push([curr.middle, curr.end, 1]);
				}
			}
			return acc;
		}, []);

	return <BreachList path={path} />;
};

export default ExploitablePathWrapperClient;
