import axios from "axios";
import React, { useEffect, useState } from "react";

const CompanyProfileDetail = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios({
      url: "https://api.securityscorecard.io/companies/okta.com/factors",
      method: "get",
      headers: {
        Authorization: "Token Qit1XE1nrE1NHYfIkzHH8MGxUwUO",
      },
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("data", data);
  const DisplayData = data?.entries?.map((items) => {
    return (
      <>
        <div className="p-10 w-full ">
          <div className="border-b-2 flex w-full border-t-2 border-gray-200">
            <div className="p-4 flex space-x-4 items-center">
              {" "}
              <img className="w-10 h-10" src={items.grade_url} />
              <div className="">{items.score}</div>
              <div className="">{items.name}</div>
            </div>
          </div>
        </div>
      </>
    );
  });
  return <div className=" ">{DisplayData}</div>;
};

export default CompanyProfileDetail;
