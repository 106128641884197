import React from "react";

function SvgComponent(props) {
	return (
		<svg
			width={props.width}
			height={props.height}
			strokeWidth={props.strokeWidth}
		>
			<path d={props.path} fill={props.fillColor} stroke={props.strokeColor} />
		</svg>
	);
}

export default SvgComponent;
