const cpeReducer = (
  state = "cpe:2.3:a:1password:1password:1.0.0.114:*:*:*:*:windows:*:*",
  action
) => {
  switch (action.type) {
    case "CPE":
      return action.payload;
    default:
      return state;
  }
};
export { cpeReducer };
