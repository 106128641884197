import React, { useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { TbReplaceFilled } from "react-icons/tb";
import { LuFileInput } from "react-icons/lu";
import { CiCircleMore } from "react-icons/ci";
import FileUploadLoading from "../utils/FileUploadLoading";
import useFileUploadToServer from "../hooks/useFileUploadToServer";
import FilePreviewer from "./FilePreviewer";
import apiErrorHandler from "../services/apiErrorHandler";
import Modal from "./Modal";

const ModalFileUpload = ({
	header,
	UploadURL,
	fetchData,
	isOpen,
	onClose,
	selectedClientId,
	selectedBusinessProcessId,
	importMutation,
}) => {
	const [file, setFile] = useState(null);
	const [fileChosen, setFileChosen] = useState("");
	const [isFileReplace, setIsFileReplace] = useState(false);
	const [isFileAppend, setIsFileAppend] = useState(false);
	const [isDragOver, setIsDragOver] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState("");
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const [isDropFileOptionsOpen, setIsDropFileOptionsOpen] = useState(false);
	const [fileContent, setFileContent] = useState('');

	const { loading, error, handleUploadFile, handleUploadFileErase } =
		useFileUploadToServer(
			fileContent,
			UploadURL,
			onClose,
			fetchData,
			selectedClientId,
			selectedBusinessProcessId,
			importMutation
		);

	// Create a reference to the hidden file input element
	const fileInputRef = React.useRef(null);
	const handleSvgClick = () => {
		fileInputRef.current.click();
	};

	const resetStates = () => {
		setFile(null);
		setFileChosen("");
		setIsFileReplace(false);
		setIsFileAppend(false);
		setIsPreviewOpen(false);
		setFeedbackMessage("");
	};

	const handleFileChange = (event) => {
		const droppedFile = event.target.files[0];
		setFile(droppedFile);
		setFileChosen(droppedFile ? droppedFile.name : "");
		setFeedbackMessage("");

		const reader = new FileReader();
		reader.onload = () => {
			const base64 = reader.result.split(',')[1]; // Get Base64 string after the comma
			setFileContent(base64); // Set the base64 string in state
		  };
		  reader.onerror = (error) => {
			console.error('File reading error:', error);
		  };
	  
		  if (droppedFile) {
			reader.readAsDataURL(droppedFile); // Converts file to base64 encoded string
		  }
	};

	const handleDrop = (e) => {
		e.preventDefault();
		const droppedFile = e.dataTransfer.files[0];
		if (droppedFile) {
			const fileType = droppedFile.name.toLowerCase().split(".").pop();
			if (fileType !== "xlsx") {
				setFeedbackMessage(
					`Please upload a .xlsx file, .${fileType} is not allowed.`
				);
				return;
			}
			setFile(droppedFile);
			setFileChosen(droppedFile ? droppedFile.name : "");
			setFeedbackMessage("");
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDragOver(true);
	};
	const handleDragLeave = (e) => {
		e.preventDefault();
		setIsDragOver(false);
	};

	const removeDroppedFile = () => {
		setFile(null);
		setFileChosen("");
		setFeedbackMessage("");
	};
	const handlePreviewClick = () => {
		setIsPreviewOpen(true);
	};

	const handleClosePreview = () => {
		setIsPreviewOpen(false);
	};

	const handleUploadClick = () => {
		if (!file) {
			setFeedbackMessage(
				"Please choose or drop a file before clicking Append or Replace."
			);
			return;
		}

		if (isFileAppend) {
			handleUploadFile();
		} else if (isFileReplace) {
			handleUploadFileErase();
		}
		else{
			console.log("Nothing selected")
		}
	};

	return (
		<div>
			<Toaster />
			<Modal
				isOpen={isOpen}
				onClose={() => {
					resetStates();
					onClose();
				}}
				size="lg"
			>
				<Modal.Header>
					<Modal.Title> {header} File Upload</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!isFileAppend && !isFileReplace && (
						<div>
							<h2>
								Would you like to replace the existing data with a new one, or
								would you like to append data to the existing one?
							</h2>
							<p className="mb-2 px-5 text-sm text-gray-500 dark:text-gray-400">
								<span className=" text-red-500">
									Note: Once data is replaced, the old data can't be regained.
								</span>
							</p>
							<div className="flex justify-end items-center p-6 space-x-4 border-t border-gray-200 rounded-b dark:border-gray-600">
								<button
									onClick={() => {
										setIsFileAppend(true);
										setIsFileReplace(false);
									}}
									data-modal-hide="defaultModal"
									type="button"
									className="btn-append"
								>
									<p className=" space-x-4">
										<LuFileInput className="inline-block" /> Append
									</p>
								</button>
								<button
									onClick={() => {
										setIsFileReplace(true);
										setIsFileAppend(false);
									}}
									data-modal-hide="defaultModal"
									type="button"
									className="btn-replace"
								>
									<p className=" space-x-4">
										<TbReplaceFilled className="inline-block" /> Replace
									</p>
								</button>
							</div>
						</div>
					)}
					{isPreviewOpen ? (
						<div className="">
							<button
								className="btn-cancel justify-ends"
								onClick={handleClosePreview}
							>
								Close Preview
							</button>{" "}
							<FilePreviewer file={file} />
						</div>
					) : (
						(isFileAppend || isFileReplace) && (
							<div
								onDragOver={handleDragOver}
								onDragLeave={handleDragLeave}
								onDrop={handleDrop}
								className={`${
									(isFileReplace || isFileAppend) && !file
										? "border-dashed border-2 border-gray-600"
										: ""
								} ${
									isDragOver && !file
										? "bg-green-50 dark:bg-gray-600"
										: "bg-gray-50 dark:bg-gray-700"
								} p-5 rounded-lg cursor-pointer`}
							>
								<div className="flex flex-col items-center justify-start pt-5 pb-6">
									{loading ? (
										<FileUploadLoading />
									) : (
										<>
											{file ? (
												<div className="justify-start items-center space-x-2">
													<div className="attachment-container">
														<div
															onClick={() => {
																setIsDropFileOptionsOpen(true);
															}}
															className="drop-file-dd-icon"
														>
															<CiCircleMore className="" />
														</div>
														{isDropFileOptionsOpen ? (
															<div
																className="file-upload-dropdown-container "
																onMouseLeave={() => {
																	setIsDropFileOptionsOpen(false);
																}}
															>
																<ul>
																	<li
																		className="file-upload-dropdown-item"
																		onClick={handlePreviewClick}
																	>
																		Preview
																	</li>
																	<li
																		className="file-upload-dropdown-item"
																		onClick={removeDroppedFile}
																	>
																		Remove
																	</li>
																</ul>
															</div>
														) : null}
														<div className="drop-file-icon">
															<svg viewBox="0 0 20 20">
																<g>
																	<path d="M17.076 4.438L13.028.444c-.19-.187-.39-.415-.75-.444H3.642a.91.91 0 00-.909.91v18.18c0 .5.41.91.91.91h12.726c.5 0 .91-.41.91-.91V4.973a.747.747 0 00-.203-.534zm-.707 14.653H3.642V.909h8.182v3.864c0 .375.307.682.682.682h3.863V19.09z"></path>
																	<path d="M12.18 9.545c0-.228-.157-.383-.366-.432V7.286l-5.467.99v7.497l5.467.989v-1.33c.21-.049.365-.204.365-.432s-.156-.383-.365-.432v-.954c.21-.05.365-.204.365-.432s-.156-.383-.365-.432v-.954c.21-.05.365-.204.365-.432 0-.229-.156-.383-.365-.433v-.953c.21-.05.365-.204.365-.433zm-2.587 4.222c-.191-.463-.425-.91-.562-1.394-.153.45-.372.876-.548 1.317a27.416 27.416 0 01-.74-.023c.29-.558.569-1.12.867-1.675-.253-.572-.532-1.132-.793-1.7.248-.015.496-.03.744-.042.168.433.352.862.49 1.307.149-.472.371-.916.562-1.372.254-.017.51-.033.766-.045a229.97 229.97 0 01-.91 1.818c.31.622.627 1.238.938 1.86a65.72 65.72 0 01-.814-.051zM13.233 13.636h.837c.276 0 .5-.178.5-.454s-.224-.455-.5-.455h-.837c-.277 0-.5.179-.5.455s.223.454.5.454zM13.233 15.455h.837c.276 0 .5-.179.5-.455s-.224-.455-.5-.455h-.837c-.277 0-.5.179-.5.455s.223.455.5.455zM13.233 11.818h.837c.276 0 .5-.178.5-.454s-.224-.455-.5-.455h-.837c-.277 0-.5.179-.5.455s.223.454.5.454zM13.233 10h.837c.276 0 .5-.178.5-.455 0-.276-.224-.454-.5-.454h-.837c-.277 0-.5.178-.5.454s.223.455.5.455z"></path>
																</g>
															</svg>
														</div>
														<div className="drop-file-name-container ">
															<span className="drop-file-name">
																{fileChosen}
															</span>
															<span>.xlsx</span>
														</div>
													</div>
												</div>
											) : (
												<>
													<svg
														onClick={handleSvgClick}
														className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
														aria-hidden="true"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 20 16"
													>
														<path
															stroke="currentColor"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
														/>
													</svg>
													<p>Drag and drop a file here or click the icon</p>
													<input
														type="file"
														ref={fileInputRef}
														onChange={handleFileChange}
														accept=".xlsx"
														className="hidden"
													/>
												</>
											)}

											{feedbackMessage && (
												<p className="mb-2 text-red-500">{feedbackMessage}</p>
											)}
										</>
									)}
								</div>
							</div>
						)
					)}
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => {
							onClose();
							resetStates();
						}}
					>
						Cancel
					</button>
					{isFileAppend || isFileReplace ? (
						<>
							<button
								onClick={handleUploadClick}
								data-modal-hide="defaultModal"
								type="button"
								className={isFileAppend ? "btn-append" : "btn-replace"}
							>
								<p className=" space-x-4">
									{isFileAppend ? (
										<>
											<LuFileInput className="inline-block" /> Append
										</>
									) : (
										<>
											<TbReplaceFilled className="inline-block" /> Replace
										</>
									)}
								</p>
							</button>
						</>
					) : null}
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ModalFileUpload;
