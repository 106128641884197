import React from "react";
import Chart from "react-apexcharts";

const GuageLegal = ({ myValue }) => {
  const value = 100 - (100 * myValue) / 15;
  const valueRounded = Math.round(value * 100) / 100;
  return (
    <div>
      <Chart
        className=""
        type="radialBar"
        width={290}
        offsetY={-20}
        series={[valueRounded]}
        options={{
          sparkline: {
            enabled: true,
          },
          plotOptions: {
            radialBar: {
              startAngle: -120,
              endAngle: 120,
              hollow: {
                size: "70%",
              },
              track: {
                background: "#4B5563",
                strokeWidth: "50%",
                margin: 5, // margin is in pixels
                opacity: 1,
                margin: 5,
                dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  blur: 3,
                  opacity: 0.5,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -5,
                  fontSize: "25px",
                  color: "black",
                },
              },
            },
          },
          colors: ["#f59e0b"],
        }}
      />
    </div>
  );
};

export default GuageLegal;
