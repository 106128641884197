import { gql } from '@apollo/client';

export const ADD_CONTROLTYPE = gql`
  mutation ADD_CONTROLTYPE($inputs: [control_typeInputType]!) {
    create_control_type(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_TYPE = gql`
  mutation DELETE_CONTROL_TYPE($id: Int!) {
    delete_control_type(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONTROL_TYPE = gql`
  mutation UPDATE_CONTROL_TYPE(
    $id: Int!
    $input: control_typeInputType!
  ) {
    update_control_type(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;


//domain
export const ADD_CONTROL_DOMAIN = gql`
  mutation ADD_CONTROL_DOMAIN($inputs: [control_domainInputType]!) {
    create_control_domain(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_DOMAIN = gql`
  mutation DELETE_CONTROL_DOMAIN($id: Int!) {
    delete_control_domain(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONTROL_DOMAIN = gql`
  mutation UPDATE_CONTROL_DOMAIN(
    $id: Int!
    $input: control_domainInputType!
  ) {
    update_control_domain(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
//category
export const ADD_CONTROL_CATEGORY = gql`
  mutation ADD_CONTROL_CATEGORY($inputs: [control_categoryInputType]!) {
    create_control_category(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_CATEGORY = gql`
  mutation DELETE_CONTROL_CATEGORY($id: Int!) {
    delete_control_category(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONTROL_CATEGORY = gql`
  mutation UPDATE_CONTROL_CATEGORY(
    $id: Int!
    $input: control_categoryInputType!
  ) {
    update_control_category(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const UPDATE_CONTROL_EFFECTIVENESS= gql`
  mutation UPDATE_CONTROL_EFFECTIVENESS(
    $id: Int!
    $input: control_effectivenessInputType!
  ) {
    update_control_effectiveness(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_EFFECTIVENESS = gql`
  mutation ADD_CONTROL_EFFECTIVENESS($inputs: [control_effectivenessInputType]!) {
    create_control_effectiveness(inputs: $inputs) {
      affected_rows
    }
  }
`;
