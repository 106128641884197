import React from 'react';
import { useSelector } from 'react-redux';
import useNumberFormatter from '../../utils/useNumberFormatter';
import Chart from 'react-apexcharts';

const FinancialImpact = ({ data }) => {
  const client_currency = useSelector(
    (state) => state.user?.client_obj?.currency
  );

  const client_currency_symbol =
    client_currency && client_currency === 'US DOLLAR'
      ? '$'
      : client_currency === 'EURO'
      ? '€'
      : client_currency === 'ETB'
      ? '฿'
      : client_currency === 'YEN'
      ? '¥'
      : client_currency === 'WON'
      ? '₩'
      : client_currency === 'POUND'
      ? '£'
      : client_currency === 'PESO'
      ? '₱'
      : '$';

  const costOfBreach = parseInt(data?.threat_profiling_analysis?.breach_cost);
  const costOfIncident = parseInt(data?.threat_profiling_analysis?.incident_cost);
  console.log(costOfBreach, costOfIncident, 'pairs of data');
  const options = {
    labels: ['Breach', 'Incident'],
    colors: ['#ffdd87', '#375e87'],
    legend: {
      show: true,
      position: 'bottom',
    },
    tooltip: {
      y: {
        formatter: (val) => `${client_currency_symbol}${val}`,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
  };

  const series = [costOfBreach / 1000, costOfIncident];

  return (
    <Chart
      className="p-4"
      options={options}
      series={series}
      type="pie"
      height="400"
    />
  );
};

export default FinancialImpact;
